import React from "react";
import Select, { components } from 'react-select';
import DownArrowIcon from "components/icons/DownArrowIcon";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <DownArrowIcon color={'#101821'} />
        </components.DropdownIndicator>
    );
};

const CustomEventSessionFormSelect = ({
    styles,
    value = '',
    isDisabled,
    handleChange,
    noOptionsMessage,
    className = 'custom-select',
    field,
    options,
    label,
    placeholder,
    form: { touched, errors }
}) => {
    const classNameWithError = `${className}--error`;

    if (touched[field.name] && errors[field.name]) {
        className = `${className} ${classNameWithError}`;
    }

    return (
        <>
            {
                label && <label htmlFor={field.name} className="event-form__label">{label}</label>
            }
            <Select
                components={{ DropdownIndicator }}
                name={field.name}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={options}
                maxMenuHeight={285}
                isDisabled={isDisabled}
                value={value}
                noOptionsMessage={() => noOptionsMessage}
                className={className}
                placeholder={placeholder}
                classNamePrefix="sign-up-select"
                styles={styles}
                isSearchable={false}
                onChange={(optionValue) => handleChange(optionValue)}
            />
            {
                touched[field.name] && errors[field.name] &&
                <div className='default-auth__error'>{errors[field.name]}</div>
            }
        </>
    )
};

export default CustomEventSessionFormSelect;