import {calendarFilters} from "redux/constants";

const initialState = {
    calendarFilters: null,
    calendarFiltersNull: true,
    calendarFiltersErrorMessage: null
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case calendarFilters.GET_FILTERS_OF_CALENDAR_SUCCESS:
            return {
                ...state,
                calendarFilters: payload,
                calendarFiltersNull: true,
            };

        case calendarFilters.GET_FILTERS_OF_CALENDAR_NULL:
            return {
                ...state,
                calendarFilters: payload,
                calendarFiltersNull: false,
            };

        case calendarFilters.GET_FILTERS_OF_CALENDAR_ERROR:
            return {
                ...state,
                calendarFiltersErrorMessage: payload,
                calendarFiltersNull: false,
            };

        case calendarFilters.CLEAR_FILTERS_OF_CALENDAR_FILTERS:
            return {
                ...state,
                calendarFilters: null,
            };

        default:
            return state;
    }
}