import {fork, put, takeLatest, call, select} from "redux-saga/effects";
import axios from "axios";
import {calendarGoogleList} from "redux/constants";

import {
    getCalendarGoogleListSuccess,
    getCalendarGoogleListEmpty,
    getCalendarGoogleListError,
} from "redux/actions/calendarGoogleList"



import {GET_LIST_OF_GOOGLE_CALENDARS} from "constants/api";
import {generalApiErrorHandler} from "redux/sagas/helpers/generalApiErrorHandler";
import {showNotification} from "redux/actions";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

function* watchGetGoogleCalendarList() {
    yield takeLatest(calendarGoogleList.GET_GOOGLE_LIST_CALENDAR, handleGetGoogleCalendarList);
}

function* handleGetGoogleCalendarList() {
    try {
        const token = yield select(getToken);

        const currentUserData = yield axios({
            method: "get",
            url: GET_LIST_OF_GOOGLE_CALENDARS(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then(response => response.data.data)
            

        if (currentUserData.length > 0) {
            yield put(getCalendarGoogleListSuccess(currentUserData));
        } else {
            yield put(getCalendarGoogleListEmpty(currentUserData));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getCalendarGoogleListError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* calendarGoogleListSaga() {
    yield fork(watchGetGoogleCalendarList);
}