import { all, fork } from "redux-saga/effects";
import authSagas from "./auth";
import dashboardSagas from "./dashboard";
import setPasswordSagas from "./setPassword";
import registerForProgram from "./registerForProgram/registerForProgram";
import personalInfo from "./personalInfo";
import platformSettings from "./platformSettings";
import participantProfile from "./participantProfile";
import settingsSaga from "./settings";
import participantsList from "./participantsList";
import userPrograms from "./userPrograms/userPrograms";
import coachesList from "./coachesList";
import programParticipantsList from "./programParticipantsList";
import chat from "./chat";
import createSession from './createSession/createSession';
import uploadCsvProgrammes from './uploadCsvProgrammes';
import sessionInvitation from "redux/sagas/sessionInvitation/sessionInvitation";
import calendarList from "./calendarList";
import calendarFilters from "./calendarFilters";
import calendarGoogleEvents from "./calendarGoogleEvents";
import calendarGoogleList from "./calendarGoogleList";
import calendarGoogleMembers from "./calendarGoogleMembers";
import completeActivity from "redux/sagas/completeActivity/completeActivity";
import reports from "redux/sagas/reports/reports";
import editDescriptions from "redux/sagas/editProgramDescriptions/editDescriptions";
import notification from "redux/sagas/notification";
import categoryDescriptions from "redux/sagas/programCategoryDescriptions/categoryDescriptions";
import matchedCoaches from "redux/sagas/matchedCoaches";
import requestSession from "redux/sagas/requestSession/requestSession";
import participantDashboardSaga from './participantDashboard';


const sagas = [
    authSagas,
    dashboardSagas,
    setPasswordSagas,
    registerForProgram,
    personalInfo,
    platformSettings,
    participantProfile,
    settingsSaga,
    participantsList,
    userPrograms,
    coachesList,
    programParticipantsList,
    chat,
    createSession,
    uploadCsvProgrammes,
    sessionInvitation,
    calendarList,
    calendarFilters,
    calendarGoogleEvents,
    calendarGoogleList,
    calendarGoogleMembers,
    completeActivity,
    reports,
    editDescriptions,
    notification,
    categoryDescriptions,
    matchedCoaches,
    requestSession,
    participantDashboardSaga
];

export function* rootSaga(services = {}) {
    yield all(sagas.map((saga) => fork(saga, services)));
}
