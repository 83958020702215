import {coachesList} from "redux/constants";

const initialState = {
    coachesListLoading: false,
    coachesList: null,
    coachesExist: false,
    coachesListNull: true,
    coachesListErrorMessage: null
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case coachesList.GET_LIST_COACHES:
            return {
                ...state,
                coachesListLoading: true
            }
        
        case coachesList.GET_NEW_COACHES_ON_SCROLL:
            return {
                ...state,
                coachesListLoading: true
            }

        case coachesList.GET_LIST_COACHES_SUCCESS:
            return {
                ...state,
                coachesList: payload,
                coachesListNull: true,
                coachesExist: true,
                coachesListErrorMessage: null,
                coachesListLoading: false
            };

        case coachesList.GET_LIST_COACHES_NULL:
            return {
                ...state,
                coachesList: payload,
                coachesListNull: false,
                coachesListErrorMessage: null,
                coachesListLoading: false
            };

        case coachesList.GET_LIST_COACHES_ERROR:
            return {
                ...state,
                coachesListErrorMessage: payload,
                coachesListNull: false,
                coachesExist: false,
                coachesListLoading: false
            };

        case coachesList.CLEAR_COACHES_LIST:
            return {
                ...state,
                coachesList: null,
                coachesExist: false,
                coachesListLoading: false
            };

        default:
            return state;
    }
}