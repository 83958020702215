import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from "react-router-dom";
import AuthFormField from "components/auth/authFormField/authFormField";
import Button from "components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { createCoachPasswordRequest, createPasswordRequest } from "redux/actions/setPassword";
import { notifications } from "helpers/notifications";
import RegistrationCheckbox from '../formFields/registrationCheckbox/registrationCheckbox';

const CreatePasswordForm = () => {
    const dispatch = useDispatch();
    const cleanEmailFromSearchParams = email => email ? email.replace(' ', '+') : null;
    const { createPasswordInProgress } = useSelector(state => state.setPassword);
    const urlSearchParams = new URLSearchParams(useLocation().search)
    const token = urlSearchParams.get('token');
    let email = cleanEmailFromSearchParams(urlSearchParams.get('email'));
    const invitation_token = urlSearchParams.get('invitation_token');
    const isCoachRegistration = !!invitation_token;
    const { passwordFormatWrong, confirmationMissmatch } = notifications;

    const validationSchema = (invitationToken) => {

        if (invitationToken) {
            return Yup.object({
                password: Yup.string()
                    .required('Required')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
                        passwordFormatWrong
                    ),
                password_confirmation: Yup.string().required('Required').when("password", {
                    is: val => (val && val.length > 0),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        confirmationMissmatch
                    )
                }),
                privacy: Yup.string().required('Required')
            })
        }

        return Yup.object({
            password: Yup.string()
                .required('Required')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
                    passwordFormatWrong
                ),
            password_confirmation: Yup.string().required('Required').when("password", {
                is: val => (val && val.length > 0),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    confirmationMissmatch
                )
            })
        })
    }

    return (
        <Formik
            initialValues={{
                password: '',
                password_confirmation: '',
                email,
                invitation_token,
                token,
                privacy: ''
            }}
            validationSchema={validationSchema(invitation_token)}
            onSubmit={
                (values) => {
                    isCoachRegistration ?
                        dispatch(createCoachPasswordRequest(values))
                        :
                        dispatch(createPasswordRequest(values))
                }
            }
        >
            <Form className='sign-up' noValidate={true}>
                <Field
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Enter new password..."
                    className="sign-up__input"
                    component={AuthFormField}
                />
                <Field
                    name="password_confirmation"
                    type="password"
                    label="Repeat password"
                    placeholder="Enter new password one more time..."
                    className="sign-up__input"
                    component={AuthFormField}
                />
                {invitation_token &&
                    <Field
                        name='privacy'
                        component={RegistrationCheckbox}
                    />
                }
                <div className="sign-up__bottom sign-up__bottom--sign-in">
                    <Button type="submit"
                        className="sign-up__submit"
                        loading={createPasswordInProgress}
                        disabled={createPasswordInProgress}
                    >
                        Confirm
                    </Button>
                </div>
            </Form>
        </Formik>

    );
};

export default CreatePasswordForm;