import {platformSettings} from "redux/constants";

const initialState = {
    platformSettings: null,
    platformSettingsErrorMessage: null
};

export default function (state = initialState, action) {

    const {type, payload} = action;

    switch (type) {

        case platformSettings.GET_PLATFORM_SETTINGS_SUCCESS:
            return {
                ...state,
                platformSettings: payload,
            };
        case platformSettings.GET_PLATFORM_SETTINGS_ERRORS:
            return {
                ...state,
                platformSettingsErrorMessage: payload,
            };

        default:
            return state;
    }

}