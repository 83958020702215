import React from 'react';

import './StartPage.scss';

import { useDispatch, useSelector } from "react-redux";
import {
    getInfoAboutCurrentUser
} from "redux/actions";

import Title from './Sections/Title';
import Cards from './Sections/Cards';
import Description from './Sections/Description';
import Start from './Sections/Start';
import { Spinner } from 'react-spinners-css';


const StartPage = () => {

    const dispatch = useDispatch();

    const { survey, role } = useSelector(state => state.currentUserInfo.currentUserInfo) || {};

    React.useEffect(() => {

        dispatch(getInfoAboutCurrentUser());

    }, [])

    return (
        survey && role ?
            <div className="section__container">
                <Title role={role} />
                <Cards role={role} />
                {survey?.survey_id !== 2 && <Description role={role} />}
                <Start />
            </div>
            :
            <Spinner />
    )
}

export default StartPage;