import {settings} from "redux/constants";
import React from "react";

const initialState = {
    sendStatusNotificationSettings: null,
    successSettings: null,
    errorsSettings: null,
    googleAuthRequest: false,
    googleAuthRequestLink: false,
    googleAuthRequestError: null,
    cancelGoogleAuthRequest: false,
    cancelGoogleAuthError: null,
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case settings.SEND_STATUS_TOGGLE_NOTIFICATION:
            return {
                ...state,
                sendStatusNotificationSettings: payload,
            };
        case settings.PUT_SETTINGS_SUCCESS:
            return {
                ...state,
                successSettings: payload,
                errorsSettings: null,
            };
        case settings.PUT_SETTINGS_ERROR:
            return {
                ...state,
                successSettings: null,
                errorsSettings: payload,
            };
        case settings.GET_GOOGLE_AUTH_LINK:
            return {
                ...state,
                googleAuthRequest: true,
                googleAuthRequestError: null
            }
        case settings.GET_GOOGLE_AUTH_LINK_SUCCESS:
            return {
                ...state,
                googleAuthRequest: false,
                googleAuthRequestError: null,
                googleAuthRequestLink: payload
            }
        case settings.GET_GOOGLE_AUTH_LINK_ERROR:
            return {
                ...state,
                googleAuthRequest: false,
                googleAuthRequestError: payload,
                googleAuthRequestLink: null
            }
        case settings.CANCEL_GOOGLE_AUTH:
            return {
                ...state,
                cancelGoogleAuthRequest: true,
                cancelGoogleAuthError: null,
            }
        case settings.CANCEL_GOOGLE_AUTH_ERROR:
            return {
                ...state,
                cancelGoogleAuthRequest: false,
                cancelGoogleAuthError: payload,
            }
        case settings.CANCEL_GOOGLE_AUTH_CLEAR_ERROR:
            return {
                ...state,
                cancelGoogleAuthError: null,
            }
        default:
            return state;
    }
}
