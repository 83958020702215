import {
    CHOOSE_QUESTIONNAIRE_LANGUAGE,
    REMOVE_QUESTIONNAIRE_LANGUAGE,
    UPDATE_QUESTIONNAIRE_LANGUAGE_FROM_LOCALSTORAGE
} from 'redux/constants';

const initialState = {
    language: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case CHOOSE_QUESTIONNAIRE_LANGUAGE:
            return {
                language: payload
            };
        case UPDATE_QUESTIONNAIRE_LANGUAGE_FROM_LOCALSTORAGE:
            return {
                ...state,
                language: payload
            };
        case REMOVE_QUESTIONNAIRE_LANGUAGE:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}