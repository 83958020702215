import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import Button from "components/button/Button";
import './TextEditor.scss';
import { useDispatch, useSelector } from "react-redux";
import { clearUserProgramErrors, editActivityDescription, editTopicDescription } from "redux/actions";

const TextEditor = ({ originalText, hideTextEditor, type, activityId, programId }) => {
    const [editedText, setEditedText] = useState('');
    const dispatch = useDispatch();
    const { singleProgram, error } = useSelector(state => state.userPrograms);
    const [textEditRequestInProgress, setTextEditRequestInProgress] = useState(false);

    useEffect(() => {
        if (originalText) {
            setEditedText(originalText);
        }

        return () => {
            dispatch(clearUserProgramErrors())
        }
    }, [originalText]);

    useEffect(() => {
        if (textEditRequestInProgress) {
            setTextEditRequestInProgress(false);
            hideTextEditor();
        }
    }, [singleProgram]);

    const handleSaveText = () => {
        if (originalText === editedText) {
            hideTextEditor();

        } else {
            if (type === 'module') {
                setTextEditRequestInProgress(true);
                dispatch(editTopicDescription(programId, activityId, editedText));

            } else {
                setTextEditRequestInProgress(true);
                dispatch(editActivityDescription(programId, activityId, editedText));
            }
        }
    };

    return (
        <>
            {/* <TextareaAutosize onChange={handleTextInput}
                              disabled={textEditRequestInProgress}
                              value={editedText}
                              className='text-editor__area'
            /> */}
            <ReactQuill theme="snow" value={editedText} onChange={setEditedText} />
            <Button loading={textEditRequestInProgress}
                type="button"
                disabled={textEditRequestInProgress}
                onClick={handleSaveText}
                className="button button--blue text-editor__save">
                Save changes
            </Button>
        </>
    );
};

export default TextEditor;