import {put} from "redux-saga/effects";
import {logout} from "redux/actions";

export const generalApiErrorHandler = (errorMessage, callback) => {
    if (errorMessage === 'request_unauthorized') {
        console.log('Auth token is invalid.')

        return put(logout());

    } else {

        return callback();
    }
};