import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGIN_REQUEST,
    UPDATE_TOKEN_FROM_LOCALSTORAGE,
    UPDATE_SURVEY_DATA_FROM_LOCALSTORAGE,
    CHAT_LOADED_SUCCESS,
    GET_STATIC_DATA_SUCCESS
} from "redux/constants";
import {transformActivityCategories} from "redux/sagas/helpers/staticData";

const initialState = {
    successfullyLogged: false,
    errorLogged: null,
    doingRequestLogin: false,
    authToken: "",
    surveyData: null,
    userRole: "",
    loadChat: false,
    staticData: {},
    mainCoach: null,
    schoolId: null
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                errorLogged: null,
                doingRequestLogin: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                successfullyLogged: true,
                doingRequestLogin: false,
                loadChat: true,
                authToken: payload.token,
                surveyData:{ ...payload?.survey},
                userRole: payload.role,
                mainCoach: payload.main_coach,
                schoolId: payload.school_id,
                errorLogged: null,
                
            };
        case LOGIN_ERROR:
            return {
                ...state,
                successfullyLogged: false,
                authToken: "",
                errorLogged: payload,
                doingRequestLogin: false,
            };
        case LOGOUT:
            return {
                ...state,
                ...initialState
            };
        case GET_STATIC_DATA_SUCCESS:
            return {
                ...state,
                staticData: transformActivityCategories(payload)
            }
        case UPDATE_SURVEY_DATA_FROM_LOCALSTORAGE:
            return {
                ...state,
                surveyData: {
                    ...payload
                }
            }
        case UPDATE_TOKEN_FROM_LOCALSTORAGE:
            return {
                ...state,
                authToken: payload,
            };
        case CHAT_LOADED_SUCCESS:
            return {
                ...state,
                loadChat: false
            };
        default:
            return state;
    }
}