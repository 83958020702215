import React from 'react';
import ProgrammeTopicsListItem from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/ProgrammeTopicsListItem/ProgrammeTopicsListItem";
import "./ProgrammeTopicsList.scss";

const ProgrammeTopicsList = ({ topics, activeListItem, isDeactivated, coachId, ...otherProps }) => {
    const programmeTopicItems = topics.map(
        (topic, index) => <ProgrammeTopicsListItem
            key={topic.uniqId}
            uniqId={topic.uniqId}
            num={topic.num}
            coachId={coachId}
            topicNum={topic.topicNum}
            isTopicHeader={index === 0}
            isActive={topic.uniqId === activeListItem}
            isDeactivated={isDeactivated}
            {...topic}
            {...otherProps}
        />
    );

    return (
        <ul className={`topics-list active-topic-${activeListItem}`}>
            {programmeTopicItems}
        </ul>
    );
};

export default ProgrammeTopicsList;