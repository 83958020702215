export const customMultiSelectStyles = {

    option: (styles, { isSelected }) => ({
        ...styles,
        color: "#212121",
        backgroundColor: isSelected ? "F7F7F7" : "#fff",
        padding: 12,
        zIndex: 1,
        position: "relative",
        borderBottom: "none",
        boxSizing: 'border-box',
        borderRadius: "8px",
        width: '100%',
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "150%",
        cursor: 'pointer',
        ":hover": {
            backgroundColor: "#F7F7F7",
            color: "#212121",
        },
    }),
    container: () => ({
        position: "relative",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "140%",
        letterSpacing: "0.0015em",
        color: "#101821",
        padding: 0,

        ":hover": {
            cursor: "pointer",
        },
    }),
    menu: () => ({
        position: "absolute",
        bottom: "-270",
        margin: "8px 0 0 0",
        boxShadow: "0px 12px 24px rgba(209, 207, 205, 0.4)",
        width: "100%",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        overflow: "hidden",
        zIndex: 10,
        border: "1px solid #F7F7F7",
        padding: "12px"
    }),
    menuList: (base) => ({
        ...base,
        background: 'transparent',
        maxHeight: "235px",
        zIndex: 1,

    }),
    control: (provided, state) => {
        let opened = state.menuIsOpen ? '1px solid #2E79DA' : '1px solid #EDEBF9';

        return {
            position: 'relative',
            border: opened,
            borderRadius: "32px",
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: '42px',
            // padding: '4px',
            backgroundColor: '#fff',
            color: '#101821',
            fontSize: '14px',
            lineHeight: '140%',
            cursor: 'pointer',

            ":hover": {
                border: "1px solid #2E79DA",
            },
        }
    },
    placeholder: () => ({
        paddingLeft: "14px",
        color: '#929292',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%'
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    singleValue: () => ({
        marginLeft: "24px",
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        margin: "0 8px",
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    valueContainer: (styles, { data }) => ({
        ...styles,
        padding: 0,
    }),
    multiValue: (styles, { data }) => ({
        ...styles,
        background: '#F1F1F1',
        borderRadius: '100px',
        padding: '2px 10px',
        margin: "4px"
    }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '150%',
        color: '#212121',
    }),
};

export const customSelectStyles = {
    placeholder: () => ({
        fontSize: '14px',
        lineHeight: '140%',
        color: '#929292',
        fontWeight: 400,
        letterSpacing: '0.0015em',
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    option: (provided, { isSelected }) => {
        const isOptionSelected = isSelected ? "#F7F7F7" : "#fff";

        return {
            ...provided,
            color: "#212121",
            backgroundColor: isOptionSelected,
            padding: 12,
            zIndex: 1,
            position: "relative",
            borderBottom: "none",
            boxSizing: 'border-box',
            borderRadius: "8px",
            width: '100%',
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "150%",
            cursor: 'pointer',

            ":hover": {
                backgroundColor: "#F7F7F7",
                color: "#212121",
            },
        }
    },
    container: () => ({
        position: "relative",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "140%",
        letterSpacing: "0.0015em",
        color: "#101821",
        padding: 0,

        ":hover": {
            cursor: "pointer",
        },
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: '0 12px'
    }),
    menu: () => ({
        position: "absolute",
        top: "56px",
        boxShadow: "0px 12px 24px rgba(209, 207, 205, 0.4)",
        width: "100%",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        overflow: "hidden",
        zIndex: 10,
        border: "1px solid #F7F7F7",
        padding: "12px"
    }),
    menuList: (base) => ({
        ...base,
        background: 'transparent',
        maxHeight: "235px",
        zIndex: 1,
    }),
    control: (provided, state) => {
        let opened = state.menuIsOpen ? '1px solid #2E79DA' : '1px solid #EDEBF9';

        return {
            position: 'relative',
            border: opened,
            borderRadius: "8px",
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: '42px',
            padding: '4px',
            backgroundColor: '#fff',
            color: '#101821',
            fontSize: '14px',
            lineHeight: '140%',
            cursor: state.isDisabled ? 'auto' : 'pointer',

            ":hover": {
                border: state.isDisabled ? null : "1px solid #2E79DA",
            },
        }
    },
};
