import {programParticipantsList} from "redux/constants";

export function getProgramParticipantsList(programParticipantsDefaultData) {
    return {
        type: programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS,
        payload: programParticipantsDefaultData,
    };
}

export function getProgramParticipantsListSuccess(programParticipantsSuccessList) {
    return {
        type: programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS_SUCCESS,
        payload: programParticipantsSuccessList,

    };
}

export function getProgramParticipantsListEmpty(emptylist) {
    return {
        type: programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS_NULL,
        payload: emptylist,
    };
}

export function getProgramParticipantsListError(error) {
    return {
        type: programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS_ERROR,
        payload: error,
    };
}

export function setClearProgramParticipantsFilters(clearSetNewData) {
    return {
        type: programParticipantsList.CLEAR_PROGRAM_PARTICIPANTS_FILTERS,
        payload: clearSetNewData,
    };
}