import React from 'react';
import moment from 'moment';
import './RecentChatItem.scss';
import { useDispatch } from 'react-redux';
import { getMessagesFromUser, setChatOpen, setChatUser } from 'redux/actions';

const RecentChatItem = ({ users, message, time, chatRoomId }) => {
    const dispatch = useDispatch();

    function openChatWithUser() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        dispatch(setChatOpen(true));
        dispatch(setChatUser(users[0]));
        dispatch(getMessagesFromUser(chatRoomId));
    }

    return (
        <div className='recent-chat-item' onClick={(e) => {
            e.stopPropagation();
            openChatWithUser();
        }}>
            <div className="recent-chat-item__logo">
                <img src={users[0]?.avatar} alt="user-logo" />
            </div>
            <div className="recent-chat-item__content">
                <div className="user-name">{users[0]?.full_name}</div>
                <div className="last-message" dangerouslySetInnerHTML={{ __html: message }}></div>
            </div>
            <div className="recent-chat-item__time">
                {moment(time).calendar(time, {
                    sameDay: 'HH:mm',
                    lastDay: '[Yesterday]',
                    lastWeek: 'MMMM, DD',
                    sameElse: 'MMMM, DD'
                })}
            </div>
        </div >
    )
}

export default RecentChatItem;
