import { combineReducers } from "redux";
import modalReducer from './modal';
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import pageTitle from "./currentPageTitle";
import setPasswordReducer from "./setPassword";
import registerForProgramReducer from "./registerForProgram";
import personalInfo from "./personalInfo";
import platformSettings from "./platformSettings";
import participantInfo from "./participantProfile";
import userSettings from "./settings";
import participantsList from "./participantsList";
import userPrograms from "./userPrograms";
import coachesList from "./coachesList";
import programParticipantsList from "./programParticipantsList";
import chat from "./chat";
import createSession from './createSession';
import uploadCsvProgrammes from './uploadCsvProgrammes';
import sessionInvitation from './sessionInvitation';
import calendarList from './calendarList';
import calendarFilters from './calendarFilters';
import calendarGoogleEvents from './calendarGoogleEvents';
import calendarGoogleList from './calendarGoogleList';
import calendarGoogleMembers from './calendarGoogleMembers';
import reports from "redux/reducers/reports";
import notification from "redux/reducers/notification";
import matchedCoaches from "redux/reducers/matchedCoaches";
import questionnaireLanguage from './questionnaireLanguage';
import timezoneNotification from './timezoneNotification';
import suggestedCoachesNotification from './suggestedCoachesNotification';
import missingFeedbackFormNotification from './missingFeedbackFormNotification';
import participantDashboard from './participantDashboard';
import { LOGOUT } from 'redux/constants';

const appReducer = combineReducers({
    auth: authReducer,
    currentPageTitle: pageTitle,
    dashboard: dashboardReducer,
    participantDashboard,
    setPassword: setPasswordReducer,
    programs: registerForProgramReducer,
    currentUserInfo: personalInfo,
    platformSettings: platformSettings,
    participant: participantInfo,
    participantsList: participantsList,
    userSettings: userSettings,
    modal: modalReducer,
    userPrograms: userPrograms,
    coachesList: coachesList,
    programParticipantsList: programParticipantsList,
    chat: chat,
    createSession: createSession,
    uploadCsvProgrammes: uploadCsvProgrammes,
    sessionInvitation: sessionInvitation,
    calendarList: calendarList,
    calendarFilters: calendarFilters,
    calendarGoogleEvents: calendarGoogleEvents,
    calendarGoogleList: calendarGoogleList,
    calendarGoogleMembers: calendarGoogleMembers,
    reports: reports,
    notification: notification,
    matchedCoaches: matchedCoaches,
    questionnaireLanguage,
    timezoneNotification,
    suggestedCoachesNotification,
    missingFeedbackFormNotification
});

export const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined
    }

    return appReducer(state, action);
}
