import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "redux/store";
import { Provider } from "react-redux";
import "./variables.scss";
import "./_breakpoints.scss";
import "./index.scss";
import 'react-quill/dist/quill.snow.css';
import Root from "./containers/root";
import { getUserTokenFromStorage, getSchoolIdFromStorage, getTimeZoneNotificationStatusFronStorage, getSurveyDataFromStorage } from "services/auth";
import { updateToken, updateSchoolId, updateQuestionnaireLanguageFromLocalstorage, updateTimezoneNotificationStatusFromStorage, updateSurveyDataFromLocalstorage } from "redux/actions";
import Modal from "components/modal/Modal";
import Notification from "components/notification/Notification";
import { getQuestionnaireLanguageFromStorage } from 'services/questionnaireLanguage';
import TimezoneNotification from 'components/timezoneNotification/TimezoneNotification';
import SideMenuNotifications from 'components/SideMenuNotifications/SideMenuNotifications';

const userToken = getUserTokenFromStorage();
const schoolId = getSchoolIdFromStorage();
const questionnaireLanguage = getQuestionnaireLanguageFromStorage();
const timeZoneNotificationStatusFronStorage = getTimeZoneNotificationStatusFronStorage();
const surveyData = getSurveyDataFromStorage();

if (userToken) {
    store.dispatch(updateToken(userToken))
}

if (surveyData) {
    store.dispatch(updateSurveyDataFromLocalstorage(JSON.parse(surveyData)))
}

if (schoolId) {
    store.dispatch(updateSchoolId(schoolId))
}

if (questionnaireLanguage) {
    store.dispatch(updateQuestionnaireLanguageFromLocalstorage(questionnaireLanguage))
}
if (timeZoneNotificationStatusFronStorage) {
    store.dispatch(updateTimezoneNotificationStatusFromStorage(timeZoneNotificationStatusFronStorage))
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <Notification />
                <Modal />
                <TimezoneNotification />
                <SideMenuNotifications />
                <Root />
            </Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
