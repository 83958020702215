import {
    GET_EVALUATION_REPORTS,
    GET_EVALUATION_REPORTS_SUCCESS,
    GET_EVALUATION_REPORTS_EMPTY,
    GET_FEEDBACK_REPORTS,
    GET_FEEDBACK_REPORTS_SUCCESS,
    GET_FEEDBACK_REPORTS_EMPTY,
    GET_ENGAGEMENT_REPORTS,
    GET_ENGAGEMENT_REPORTS_SUCCESS,
    GET_ENGAGEMENT_REPORTS_EMPTY,
    CLEAR_REPORTS_DATA,
    GET_REPORTS_ERROR,
    ADD_SELECTED_REPORTS,
    ADD_SELECTED_FILTER_OPTIONS,
    SET_SORTING_OPTION_AND_ORDER,
    ADD_SEARCH_QUERY,
    GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_ERROR, CLEAR_REPORT_DATA, GET_BILLING_OVERVIEW_REPORTS, GET_BILLING_OVERVIEW_REPORTS_SUCCESS, GET_BILLING_OVERVIEW_REPORTS_EMPTY, GET_COACH_INVOICES, GET_COACH_INVOICES_SUCCESS, GET_COACH_INVOICES_EMPTY
} from "redux/constants/reports";

const initialState = {
    getReportsProgress: false,
    getReportsError: false,
    reportsExist: false,
    evaluationReports: null,
    feedbackReports: null,
    engagementReports: null,
    billingReports: null,
    invoices: null,
    selectedReports: [],
    searchQuery: null,
    selectedFilterOptions: null,
    sortingOptionAndOrder: null,
    singleReport: {},
    getSingleReportRequest: false,
    getSingleReportError: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        // single report
        case GET_REPORT:
            return {
                ...state,
                singleReport: {},
                getSingleReportRequest: true,
                getSingleReportError: null
            }
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                singleReport: payload,
                getSingleReportRequest: false,
                getSingleReportError: null
            }
        case GET_REPORT_ERROR:
            return {
                ...state,
                singleReport: {},
                getSingleReportRequest: false,
                getSingleReportError: payload
            }

        // evaluation reports
        case GET_EVALUATION_REPORTS:
            return {
                ...state,
                getReportsProgress: true,
            };
        case GET_EVALUATION_REPORTS_SUCCESS:
            return {
                ...state,
                evaluationReports: payload,
                reportsExist: true,
                getReportsProgress: false
            };
        case GET_EVALUATION_REPORTS_EMPTY:
            return {
                ...state,
                evaluationReports: payload,
                getReportsProgress: false
            };

        // feedback reports
        case GET_FEEDBACK_REPORTS:
            return {
                ...state,
                getReportsProgress: true,
            };
        case GET_FEEDBACK_REPORTS_SUCCESS:
            return {
                ...state,
                feedbackReports: payload,
                reportsExist: true,
                getReportsProgress: false
            };
        case GET_FEEDBACK_REPORTS_EMPTY:
            return {
                ...state,
                feedbackReports: payload,
                getReportsProgress: false
            };

        // billing reports
        case GET_BILLING_OVERVIEW_REPORTS:
            return {
                ...state,
                getReportsProgress: true,
            };
        case GET_BILLING_OVERVIEW_REPORTS_SUCCESS:
            return {
                ...state,
                billingReports: payload,
                reportsExist: true,
                getReportsProgress: false
            };
        case GET_BILLING_OVERVIEW_REPORTS_EMPTY:
            return {
                ...state,
                billingReports: payload,
                getReportsProgress: false
            };

        // coach invoices
        case GET_COACH_INVOICES:
            return {
                ...state,
                getReportsProgress: true,
            };
        case GET_COACH_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: payload,
                reportsExist: true,
                getReportsProgress: false
            };
        case GET_COACH_INVOICES_EMPTY:
            return {
                ...state,
                invoices: payload,
                getReportsProgress: false
            };

        // engagement reports
        case GET_ENGAGEMENT_REPORTS:
            return {
                ...state,
                getReportsProgress: true,
            };
        case GET_ENGAGEMENT_REPORTS_SUCCESS:
            return {
                ...state,
                engagementReports: payload,
                reportsExist: true,
                getReportsProgress: false
            }
        case GET_ENGAGEMENT_REPORTS_EMPTY:
            return {
                ...state,
                engagementReports: payload,
                getReportsProgress: false
            };

        // get reports error for all types
        case GET_REPORTS_ERROR:
            return {
                ...initialState,
                getReportsError: payload,
                getReportsProgress: false,
            };

        // actions with reports list
        case ADD_SELECTED_REPORTS:
            return {
                ...state,
                selectedReports: payload
            }
        case ADD_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: payload
            }
        case ADD_SELECTED_FILTER_OPTIONS:
            return {
                ...state,
                selectedFilterOptions: payload
            }
        case SET_SORTING_OPTION_AND_ORDER:
            return {
                ...state,
                sortingOptionAndOrder: payload
            }
        case CLEAR_REPORTS_DATA:
            return {
                ...state,
                ...initialState
            }

        case CLEAR_REPORT_DATA:
            return {
                ...state,
                singleReport: {},
                getSingleReportRequest: false,
                getSingleReportError: null
            }
        default:
            return state;
    }
}