import React, { useEffect} from "react";
import {useDispatch} from "react-redux"
import "./NotFound.scss";
import pageTitles from "constants/pageTitles";
import {updatePageTitle} from "redux/actions";
import notFound from "assets/vector/404/not-found.svg";

export default function NotFound() {
    const {PAGE_NOT_FOUND_TITLE} = pageTitles;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updatePageTitle(PAGE_NOT_FOUND_TITLE));
    }, []);


    return (
        <div className="section-wrap page-not-found">
            <div className="wrapper">
                <img src={notFound} className={`img-page-not-found`} alt="page not found"/>
                <h2 className="title">{`Sorry, page not found`}</h2>
            </div>
        </div>
    );
};