import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from "formik";
import InvoicesFilterMultiSelect from 'components/CustomFormSelect/InvoicesFilterMultiSelect';
import { removeEmptyValues } from 'helpers/removeEmptyValues';
import { addSelectedFilterOptions } from 'redux/actions';
import './InvoicesFilters.scss';

const currencyList = [
    {
        value: 'USD',
        label: 'USD'
    },
    {
        value: 'GBP',
        label: 'GBP'
    },
    {
        value: 'EUR',
        label: 'EUR'
    },
    {
        value: 'CHF',
        label: 'CHF'
    },
]

const statusesList = [
    // under_review, approved, reverted, paid
    {
        value: 'under_review',
        label: 'Under review'
    },
    {
        value: 'approved',
        label: 'Approved'
    },
    {
        value: 'reverted',
        label: 'Reverted'
    },
    {
        value: 'paid',
        label: 'Paid'
    },
]

const InvoicesFilters = ({ setOffsetReports }) => {
    const dispatch = useDispatch();

    const _handleSubmit = (values) => {
        let filtersValues = {};

        for (const property in removeEmptyValues(values)) {
            filtersValues[property] = [...values[property].map(item => item.value)];
        }

        setOffsetReports(0);
        dispatch(addSelectedFilterOptions(filtersValues));
    }

    return (
        <Formik
            initialValues={{
                currency: '',
                status: ''
            }}
            onSubmit={(values) => _handleSubmit(values)}
        >
            <Form className="invoices-filters">
                <Field
                    name="currency"
                    options={currencyList}
                    placeholder="Choose currency..."
                    component={InvoicesFilterMultiSelect}
                />
                <Field
                    name="status"
                    options={statusesList}
                    placeholder="Choose status..."
                    component={InvoicesFilterMultiSelect}
                />
            </Form>
        </Formik>
    )
}

export default InvoicesFilters;
