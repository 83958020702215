import { fork, put, takeLatest, call, select } from "redux-saga/effects";
import axios from "axios";


import {
    getInfoAboutCurrentUser,
    updateUserSettingsSuccess,
    updateUserSettingsError,
    getGoogleAuthLinkError,
    getGoogleAuthLinkSuccess,
    cancelGoogleAuthError,
    updateGoogleAuthStatus, showNotification
} from "redux/actions";

import { settings } from "redux/constants";
import {
    SEND_NOTIFICATION_STATUS_SETTINGS,
    SEND_DATA_SETTINGS_USER,
    EDIT_SCHOOL_ADMIN,
    GET_GOOGLE_AUTH_LINK, CANCEL_GOOGLE_AUTH, GET_GOOGLE_STATUS
} from "constants/api";
import { HendlerErrors } from "helpers/HendlerErrors";
import { deleteData, getData } from "redux/sagas/helpers/requestHandlers";
import ResponseError from "helpers/ResponseError";
import { generalApiErrorHandler } from "redux/sagas/helpers/generalApiErrorHandler";
import { getNotificationMessage } from "helpers/notifications";
import { getToken } from "redux/sagas/helpers/helpers";

function* watchSendStatusNotification() {
    yield takeLatest(settings.SEND_STATUS_TOGGLE_NOTIFICATION, handleSendStatusNotification);
}

function* handleSendStatusNotification({ payload }) {

    try {
        const token = yield select(getToken);
        yield axios({
            method: "put",
            url: SEND_NOTIFICATION_STATUS_SETTINGS(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
            data: {
                is_notifiable: payload,
            }
        })
            .then(response => response.data);

        yield put(getInfoAboutCurrentUser());

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}


function* watchSendUserSettings() {
    yield takeLatest(settings.PUT_SETTINGS, handleSendUserSettings);
}

function* handleSendUserSettings({ payload, errorHandling }) {

    try {
        const token = yield select(getToken);
        // hot fix of moment library which Kyiv timezone is differed from the backend (Kiev but should be Kyiv)
        const requestData = {...payload};

        if (requestData.timezone === 'Europe/Kiev') {
            requestData.timezone = 'Europe/Kyiv';
        }

        yield axios({
            method: "post",
            url: SEND_DATA_SETTINGS_USER(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
            data: requestData
        })
            .then(response => response.data);

        yield put(updateUserSettingsSuccess('settings have been successfully updated'));
        yield put(getInfoAboutCurrentUser());

    } catch (errors) {
        if (errorHandling) {
            errorHandling(errors.response.data.errors);
        }
        yield put(updateUserSettingsError('form validation error'));
    }
}


function* watchSendSchoolAdminSettings() {
    yield takeLatest(settings.PUT_SCHOOL_ADMIN_SETTINGS, handleSendSchoolAdminSettings);
}

function* handleSendSchoolAdminSettings({ payload, errorHandling }) {
    try {
        const token = yield select(getToken);

        yield axios({
            method: "post",
            url: EDIT_SCHOOL_ADMIN(payload.id),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
            data: {
                name: payload.name,
                surname: payload.surname,
                phone: payload.phone,
                position: payload.position,
                timezone: payload.timezone,
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.password_confirmation,
                old_password: payload.old_password,
                use_local_tz: payload.use_local_tz,
            }
        })
            .then(response => response.data);

        yield put(updateUserSettingsSuccess('settings have been successfully updated'));
        yield put(getInfoAboutCurrentUser());

    } catch (errors) {
        const setNewError = HendlerErrors(errors.response.data.errors);
        if (errorHandling) {
            errorHandling(setNewError);
        }
        yield put(updateUserSettingsError('form validation error'));
    }
}

function* watchGetGoogleAuthLink() {
    yield takeLatest(settings.GET_GOOGLE_AUTH_LINK, handleGetGoogleAuthLink);
}

function* handleGetGoogleAuthLink() {
    try {
        const token = yield select(getToken);
        const { data } = yield call(getData(GET_GOOGLE_AUTH_LINK, token));

        if (data?.status === 'Success') {
            yield put(getGoogleAuthLinkSuccess(data.data?.redirect_url));

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            yield put(getGoogleAuthLinkError(err.getValidationErrorMessages()));

            yield put(showNotification({
                title: err.getMessage(),
                text: getNotificationMessage(err.getValidationErrorMessages())
            }, true));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getGoogleAuthLinkError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* watchGetGoogleAuthStatus() {
    yield takeLatest(settings.GET_GOOGLE_AUTH_STATUS, handleGetGoogleAuthStatus);
}

function* handleGetGoogleAuthStatus() {
    try {
        const token = yield select(getToken);
        const { data } = yield call(getData(GET_GOOGLE_STATUS, token));

        if (data?.status === 'Success') {
            yield put(updateGoogleAuthStatus(data.data));

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            yield put(updateGoogleAuthStatus('error'));

            yield put(showNotification({
                title: 'Error google account sync',
                text: err.getValidationErrorMessages()
            }, true));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error google account sync ',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* watchCancelGoogleAuth() {
    yield takeLatest(settings.CANCEL_GOOGLE_AUTH, handleCancelGoogleAuth);
}

function* handleCancelGoogleAuth() {
    try {
        const token = yield select(getToken);
        const { data } = yield call(deleteData(CANCEL_GOOGLE_AUTH, token));

        if (data?.status === 'Success') {
            yield put(updateGoogleAuthStatus(null));

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            yield put(cancelGoogleAuthError(err.getValidationErrorMessages()));

            yield put(showNotification({
                title: err.getMessage(),
                text: getNotificationMessage(err.getValidationErrorMessages())
            }, true));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(cancelGoogleAuthError(error.message));

            yield put(showNotification({
                title: 'Error google account sync ',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* setSettingsSagas() {
    yield fork(watchSendStatusNotification);
    yield fork(watchSendUserSettings);
    yield fork(watchSendSchoolAdminSettings);
    yield fork(watchGetGoogleAuthLink);
    yield fork(watchCancelGoogleAuth);
    yield fork(watchGetGoogleAuthStatus);
}