import React from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import AuthFormField from "components/auth/authFormField/authFormField";
import Button from "components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {resetPasswordRequest} from "redux/actions/setPassword";

const ResetPasswordForm = () => {
    const dispatch = useDispatch();
    const {resetPasswordInProgress} = useSelector(state => state.setPassword);

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address')
                    .required('Required'),
            })}
            onSubmit={
                ({email}) => dispatch(resetPasswordRequest({email: email.toLowerCase()}))
            }
        >
            <Form className='sign-up' noValidate={true}>
                <Field
                    name="email"
                    type="email"
                    placeholder="email.example@gmail.com..."
                    className="sign-up__input"
                    label="Email"
                    component={AuthFormField}
                />
                <div className="sign-up__bottom">
                    <Button type="submit"
                            className="sign-up__submit"
                            loading={resetPasswordInProgress}
                            disabled={resetPasswordInProgress}
                        >
                        Send
                    </Button>
                </div>
            </Form>
        </Formik>
    );
};

export default ResetPasswordForm;