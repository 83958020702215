import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
    getInfoAboutCurrentUser
} from "redux/actions";

import './ProgrammeStarting.scss';

import Footer from '../../Auth/Footer/Footer';
import Header from '../../Auth/Header/Header';
import routes from 'constants/routes';

const ProgrammeStarting = () => {
    const dispatch = useDispatch();

    const { QUESTIONNAIRE, QUESTIONNAIRE_COACH_MATCHING } = routes;

    React.useEffect(() => {

        dispatch(getInfoAboutCurrentUser());

    }, [dispatch])

    return (
        <>
            <Header />
            <section className="starting-page">
                <div className="container">
                    <h1 className="title">You have <span>successfully</span> registered for the new programme!</h1>
                    <div className="description">
                        <p className="first">We are carefully storing the answers to the questionnaire that you provided the last time.</p>
                        <p className="second">You can update the information and retake the questionnaire, or we will find you the best possible coach for the new programme using the previous answers.</p>
                    </div>
                    <div className="links">
                        <Link className="coach_matching link" to={QUESTIONNAIRE_COACH_MATCHING}>Find a coach</Link>
                        <Link className="start_link link" to={QUESTIONNAIRE}>Take the questionnaire</Link>
                    </div>
                </div>

            </section>
            <Footer />
        </>
    )
}

export default ProgrammeStarting;

