import React, {useEffect} from 'react';
import './DefaultAuth.scss';
import {Switch, Route} from "react-router-dom";
import {ReactComponent as Decor1} from "../../../../assets/vector/auth-page/decor-3.svg";
import {ReactComponent as Decor2} from "../../../../assets/vector/auth-page/decor-4.svg";
import {ReactComponent as Decor3} from "../../../../assets/vector/auth-page/auth-decor-lines.svg";
import pageTitles from "constants/pageTitles";
import {updatePageTitle} from "redux/actions";
import {useDispatch} from "react-redux";
import routes from "constants/routes";
import SignIn from "components/auth/signIn/SignIn";
import CreatePassword from "components/auth/createPassword/CreatePassword";
import ResetPassword from "components/auth/resetPassword/resetPassword";
import Header from "components/pages/Auth/Header/Header";
import Footer from "components/pages/Auth/Footer/Footer";

const DefaultAuth = () => {
    const {RESET_PASSWORD, CREATE_PASSWORD} = routes;
    const {LOGIN_PAGE_TITLE} = pageTitles;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updatePageTitle(LOGIN_PAGE_TITLE));
    }, []);

    return (
        <>
            <Header/>
            <section className="default-auth">
                {/* fot ILTB */}
                {/* <Decor1 className="decor decor--1"/> */}
                {/* <Decor2 className="decor decor--2"/> */}
                {/* <Decor3 className="decor decor--3"/> */}
                <div className="default-auth__content">
                    <Switch>
                        <Route exact path={RESET_PASSWORD} component={ResetPassword}/>
                        <Route exact path={CREATE_PASSWORD} component={CreatePassword}/>
                        <Route>
                            <SignIn/>
                        </Route>
                    </Switch>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default DefaultAuth;