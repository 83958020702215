import {calendarGoogleList} from "redux/constants";

const initialState = {
    calendarGoogleList: null,
    calendarGoogleListNull: true,
    calendarGoogleListErrorMessage: null
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case calendarGoogleList.GET_GOOGLE_LIST_CALENDAR_SUCCESS:
            return {
                ...state,
                calendarGoogleList: payload,
                calendarGoogleListNull: true,
            };

        case calendarGoogleList.GET_GOOGLE_LIST_CALENDAR_NULL:
            return {
                ...state,
                calendarGoogleList: payload,
                calendarGoogleListNull: false,
            };

        case calendarGoogleList.GET_GOOGLE_LIST_CALENDAR_ERROR:
            return {
                ...state,
                calendarGoogleListErrorMessage: payload,
                calendarGoogleListNull: false,
            };

        default:
            return state;
    }
}