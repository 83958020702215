import {fork, put, takeLatest, call, select} from "redux-saga/effects";
import axios from "axios";
import {calendarList} from "redux/constants";

import {
    getCalendarListSuccess,
    getCalendarListEmpty,
    getCalendarListError,
} from "redux/actions/calendarList"

import { GET_LIST_OF_CALENDAR_EVENTS } from "constants/api"
import {generalApiErrorHandler} from "redux/sagas/helpers/generalApiErrorHandler";
import {showNotification} from "redux/actions";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

function* watchGetCalendarList() {
    yield takeLatest(calendarList.GET_LIST_CALENDAR_EVENTS, handleGetCalendarList);
}
function* handleGetCalendarList({payload}) {

    try {
        const token = yield select(getToken);

        let data ={
            "start": `${payload.start}`,
            "end": `${payload.end}`,
        }

        if (payload.type.length > 0) {
            data.type = payload.type;
        }
        if (payload.programs.length > 0) {
            data.program = payload.programs;
        }
        if (payload.members.length > 0) {
            data.members = payload.members;
        }
        if (payload.additional_users.length > 0) {
            data.additional_users = payload.additional_users;
        }
    
        const currentUserData = yield axios({
            method: "post",
            url: GET_LIST_OF_CALENDAR_EVENTS(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
            data: data
        })
            .then(response => response.data.data);


        if (currentUserData.length > 0) {
            yield put(getCalendarListSuccess(currentUserData));
        } else {
            yield put(getCalendarListEmpty(currentUserData));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getCalendarListError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });

    }
}


function* watchClearFilters() {
    yield takeLatest(calendarList.CLEAR_FILTERS, handleClearFilters);
}
function* handleClearFilters({payload}) {

    try {
        const token = yield select(getToken);

        let data;
        if (payload.type.length > 0) {
            data = {
                "start": `${payload.start}`,
                "end": `${payload.end}`,
            }
        } else {
            data = {
                "start": `${payload.start}`,
                "end": `${payload.end}`,
            }
        }

        const getCalendarDataAfterResetFilters = yield axios({
            method: "post",
            url: GET_LIST_OF_CALENDAR_EVENTS(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
            data: data
        })
            .then(response => response.data.data);


        if (getCalendarDataAfterResetFilters.length > 0) {
            yield put(getCalendarListSuccess(getCalendarDataAfterResetFilters));
        }else {
            yield put(getCalendarListEmpty(null));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getCalendarListError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* calendarListSaga() {
    yield fork(watchGetCalendarList);
    yield fork(watchClearFilters);
}