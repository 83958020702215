import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from 'components/button/Button';
import defaultAva from 'assets/vector/default-avatar.png';
import { ReactComponent as BookIcon } from 'assets/vector/book-white.svg';
import { getRelativeLink } from 'helpers/helpers';
import { closeModal } from 'redux/actions/modal';
import { calculateTimeWithZone } from 'components/pages/CalendarPage/helpers/timeTransforms';
import EventLink
  from 'components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/Event/EventLink/EventLink';
import EventDuration
  from 'components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/Event/EventDuration/EventDuration';
import EventDate
  from 'components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/Event/EventDate/EventDate';

const CalendarEventModal = () => {
  const event = useSelector(state => state.modal.modalData.event);
  const { role, id: userId, timezone } = useSelector(state => state.currentUserInfo.currentUserInfo);
  const isAdmin = role === 'school_admin';
  const history = useHistory();
  const dispatch = useDispatch();
  const isGoogleCalendar = event?.calendar_name;
  const eventDuration = event?.all_day ? 'All day' : event?.duration ? `${event?.duration}m` : '45m';
  const eventDate = event?.all_day ? moment(event?.start).local().format('MMMM DD') : moment(event?.start).local().format('MMMM DD, HH:mm A');

  const handleRedirect = () => {
    const sessionLink = getRelativeLink(event?.session_link, '/programmes');
    dispatch(closeModal());

    return history.push(sessionLink);
  };

  return (
    <section className="calendar-event">
      <header className="calendar-event__header">
        <h2 className="calendar-event__type">{event?.title}</h2>
        <div
          className={`calendar-event__status ${event?.status ? event.status : ''}`}>{event?.status === 'pending' ? 'Awaiting confirmation' : <> {event?.status === 'late_canceled' ? event?.status.replace('_', ' ') : event?.status} </>}</div>
        <EventDate text={eventDate}/>
        <EventDuration text={eventDuration}/>
        <EventLink link={event?.link} name={event?.calendar_name}/>
        {event?.details && <p className="calendar-event__details">{event.details}</p>}
        {
          event?.members && (
            <>
              <h3 className="calendar-event__members-title">Participants:</h3>
              <div className="calendar-event__members">
                {event?.members?.coaches ?
                  (event?.members.coaches).map((coach) => {
                    const { userHours, userUTC } = calculateTimeWithZone(event.start, coach, { id: userId, timezone });

                    return (
                      <div className="calendar-event__member-info" key={coach.id}>
                        <img className="avatar" src={coach.avatar ? coach.avatar : defaultAva} alt="coach avatar"/>
                        <p className="name">
                          {coach.name ? coach.name : 'Coach'}
                          {userHours ? <span className="time"> {`(UTC${userUTC}) ${userHours}`}</span> : null}
                        </p>
                      </div>
                    );
                  })
                  : null
                }
                {event?.members?.participants ?
                  (event?.members.participants).map((participant) => {
                    const { userHours, userUTC } = calculateTimeWithZone(event.start, participant, {
                      id: userId,
                      timezone
                    });

                    return (
                      <div className="calendar-event__member-info" key={participant.id}>
                        <img className="avatar" src={participant.avatar ? participant.avatar : defaultAva}
                             alt="participant avatar"/>
                        <p className="name">
                          {participant.name}
                          {userHours ? <span className="time"> {`(UTC${userUTC}) ${userHours}`}</span> : null}
                        </p>
                      </div>
                    );
                  })
                  : null
                }
              </div>
            </>
          )
        }
      </header>
      <footer className="calendar-event__footer">
        <h3 className="calendar-event__footer-title">
          <BookIcon className="calendar-event__footer-icon"/>
          <span className="inner-text">{isGoogleCalendar ? 'Calendar event' : `${event?.type} session`}</span>
        </h3>
        {event?.session_link && !isAdmin &&
          <Button className="button button--white-invert calendar-event__footer-btn"
                  onClick={handleRedirect}>
            Details
          </Button>
        }
      </footer>
    </section>
  );
};

export default CalendarEventModal;
