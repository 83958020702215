import {calendarGoogleEvents} from "redux/constants";

export function getCalendarGoogleList(calendarGoogleDefaultData) {
    return {
        type: calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR,
        payload: calendarGoogleDefaultData,
    };
}

export function getCalendarGoogleListSuccess(calendarGoogleSuccessList) {
    return {
        type: calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR_SUCCESS,
        payload: calendarGoogleSuccessList,

    };
}

export function getCalendarGoogleListEmpty(emptylist) {
    return {
        type: calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR_NULL,
        payload: emptylist,
    };
}

export function getCalendarGoogleListError(error) {
    return {
        type: calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR_ERROR,
        payload: error,
    };
}


export function setClearFilters(clearSetNewData) {
    return {
        type: calendarGoogleEvents.CLEAR_FILTERS,
        payload: clearSetNewData,
    };
}

export function clearCalendarGoogleList() {
    return {
        type: calendarGoogleEvents.CLEAR_CALENDAR_GOOGLE_EVENTS_LIST
    };
}