import { personalInfo } from "redux/constants";
import { updateGoogleAuthStatus, updateUserTimeZone } from "redux/reducers/helpers/updatePersonalInfo";

const initialState = {
    currentUserInfo: null,
    currentUserInfoLoading: false
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case personalInfo.GET_INFO_ABOUT_CURRENT_USER:
            return {
                ...state,
                currentUserInfoLoading: true
            }
        case personalInfo.GET_INFO_ABOUT_CURRENT_USER_ERROR:
            return {
                ...state,
                currentUserInfoLoading: false
            }
        case personalInfo.GET_INFO_ABOUT_CURRENT_USER_SUCCESS:
            return {
                ...state,
                currentUserInfo: {
                    ...state.currentUserInfo,
                    ...updateUserTimeZone(payload)
                },
                currentUserInfoLoading: false
            };
        case personalInfo.CLEAR_DATA_ABOUT_CURRENT_USER:
            return {
                ...state,
                currentUserInfo: null,
                currentUserInfoLoading: false
            };
        case personalInfo.UPDATE_GOOGLE_AUTH_STATUS:
            return {
                ...state,
                currentUserInfo: updateGoogleAuthStatus(state.currentUserInfo, payload)
            }
        default:
            return state;
    }
}
