import {useEffect, useState} from "react";
import {getInfoAboutCurrentUser, getStaticData} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";

export const useAppInit = () => {
    const [isInited, setIsInited] = useState(false);
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth.authToken);
    const getCurrentUserInfo = useSelector(state => state.currentUserInfo.currentUserInfo);

    useEffect(() => {
        if (accessToken && !getCurrentUserInfo) {
            setIsInited(false);
            dispatch(getInfoAboutCurrentUser());
            dispatch(getStaticData());
        }

        if (accessToken && getCurrentUserInfo) {
            setIsInited(true);
        }

        if (!accessToken) {
            setIsInited(true);
        }

    }, [accessToken, getCurrentUserInfo]);

    return isInited;
}

