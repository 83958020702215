import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import "./EditMyProfile.scss";
import profile from "services/profile";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/button/Button";
import {getInfoAboutCurrentUser} from "redux/actions";
import routes from "constants/routes"
import defaultAva from 'assets/vector/default-avatar-light.svg';
import Avatar from "components/pages/EditMyProfile/PersonalInfo/Avatar/Avatar";
import StatusMessage from "components/pages/EditMyProfile/StatusMessage/StatusMessage";

export default function SchoolAdminEditProfile({userInfo}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {MY_PROFILE} = routes;
    const [avatar, setAvatar] = useState('');
    const [spinnerLoadingButton, setSpinnerLoadingButton] = React.useState(false);
    const userToken = useSelector(state => state.auth.authToken);

    const updateSchoolAdminProfileData = (values, token, setStatus) => {
        profile.updateSchoolAdminProfile(values, token, setStatus)
            .then((response) => {
                if (response.data.status === "Success") {
                    setStatus({show: true, sent: true, msg: "Your profile has been successfully updated!"});
                    setSpinnerLoadingButton(true);
                    setTimeout(() => {
                        setSpinnerLoadingButton(false);
                        setStatus({show: false});

                        history.push(MY_PROFILE);
                    }, 2000);
                    dispatch(getInfoAboutCurrentUser());
                }
            })
            .catch(error => {
                console.log(error);
                setStatus({show: true, sent: false, msg: `Error! ${error}. Please try again later.`})
                setTimeout(() => {
                    setSpinnerLoadingButton(false);
                    setStatus({show: false});
                }, 2000);
            })
    };

    useEffect(() => {
        if (userInfo) {
            setAvatar(userInfo.avatar || defaultAva)
        }
    }, [userInfo]);

    return (
        <Formik
            initialValues={{
                avatar: userInfo.avatar,
                school_name: userInfo.school_name,
            }}
            validationSchema={Yup.object({
                school_name: Yup.string()
                    .min(3, 'Must be 3 characters or more')
                    .required('Required'),
                avatar: Yup.mixed()
                    .test("fileSize", "File too large", (value) => {
                        if (typeof value === "string") {
                            return true;
                        } else if (value.avatarSizeIsReadyToUpload !== undefined) {
                            return value.avatarSizeIsReadyToUpload;
                        }
                    })
                    .test('fileType', 'Unsupported file type. Only .jpg, .jpeg, .png or .svg.', (value) => {
                            if (typeof value === "string") {
                                return true;
                            } else if (value.avatarFormatIsOk !== undefined) {
                                return value.avatarFormatIsOk;
                            }
                        }
                    ),
            })}
            onSubmit={
                (values, {setSubmitting, setStatus}) => {
                    setSubmitting(false);
                    setStatus(false);
                    updateSchoolAdminProfileData(values, userToken, setStatus)
                }
            }
        >

            {({values, errors, touched, isSubmitting, dirty, setFieldValue, handleBlur, status, ...props}) => {
                return (
                    <Form>
                        <div className="section-info-block personal-information">
                            <h3 className={`section-title`}>Personal information</h3>
                            <Avatar avatar={avatar}
                                    setAvatar={setAvatar}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    touched={touched}
                            />
                            <div className={`profile-row first-name ${errors.school_name && touched.school_name ? 'input-error' : ''}`}>
                                <label htmlFor={'school_name'} className="profile-row__label">School name</label>
                                <Field name="school_name"
                                       type="text"
                                       placeholder="School name"
                                       className={`profile-row__input`}
                                />
                                <ErrorMessage className={`profile-row__error`} component="div" name="school_name"/>
                            </div>
                        </div>
                        <StatusMessage show={status?.show} success={status?.sent}>
                            {status?.msg}
                        </StatusMessage>
                        <div className="save-block-wrapper">
                            <Button type="submit"
                                    className="save-personal-info"
                                    loading={spinnerLoadingButton}
                                    disabled={isSubmitting || !dirty}
                            >
                                Save changes
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}