import { SHOW_TIMEZONE_NOTIFICATION, HIDE_TIMEZONE_NOTIFICATION, UPDATE_TIMEZONE_NOTIFICATION_STATUS_FROM_STORAGE } from 'redux/constants';

export const showTimezoneNotification = () => ({ type: SHOW_TIMEZONE_NOTIFICATION });

export const hideTimezoneNotification = () => ({ type: HIDE_TIMEZONE_NOTIFICATION });

export const updateTimezoneNotificationStatusFromStorage = (notificationStatus) => ({
    type: UPDATE_TIMEZONE_NOTIFICATION_STATUS_FROM_STORAGE,
    payload: notificationStatus
});
