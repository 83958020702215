import {coachesList} from "redux/constants";

export function getCoachesList(coachesDefaultData) {
    return {
        type: coachesList.GET_LIST_COACHES,
        payload: coachesDefaultData,
    };
}

export function getCoachesListSuccess(coachesSuccessList) {
    return {
        type: coachesList.GET_LIST_COACHES_SUCCESS,
        payload: coachesSuccessList,

    };
}

export function getCoachesListEmpty(emptylist) {
    return {
        type: coachesList.GET_LIST_COACHES_NULL,
        payload: emptylist,
    };
}

export function getCoachesListError(error) {
    return {
        type: coachesList.GET_LIST_COACHES_ERROR,
        payload: error,
    };
}

export function getNewCoachesItemsOnScroll(getNewItems) {
    return {
        type: coachesList.GET_NEW_COACHES_ON_SCROLL,
        payload: getNewItems,
    };
}

export function setClearCoachesFilters(clearSetNewData) {
    return {
        type: coachesList.CLEAR_COACHES_FILTERS,
        payload: clearSetNewData,
    };
}