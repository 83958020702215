import React from 'react';
import './createSession.scss';
import {openModal} from "redux/actions/modal";
import {CREATE_SESSION_MODAL} from "redux/constants/modal";
import {useDispatch, useSelector} from "react-redux";

const CreateSession = ({text, className}) => {
    const dispatch = useDispatch();
    const defaultText = `+ Schedule session`;
    const {role} = useSelector(state => state.currentUserInfo.currentUserInfo) || {};

    return (
        role === 'coach' || role === 'participant' ?
            <button className={`button button--blue create-session ${className ? className : ''}`}
                    onClick={() => dispatch(openModal(CREATE_SESSION_MODAL))}>
                {text ? text : defaultText}
            </button>
            :
            null
    );
};

export default CreateSession;