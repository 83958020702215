import {fork, put, takeLatest, call, select} from "redux-saga/effects";
import axios from "axios";
import {calendarGoogleMembers} from "redux/constants";

import {
    getCalendarGoogleMembersSuccess,
    getCalendarGoogleMembersEmpty,
    getCalendarGoogleMembersError,
} from "redux/actions/calendarGoogleMembers"

import { GET_LIST_OF_GOOGLE_MEMBERS } from "constants/api"
import {generalApiErrorHandler} from "redux/sagas/helpers/generalApiErrorHandler";
import {showNotification} from "redux/actions";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

function* watchGetCalendarMembers() {
    yield takeLatest(calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS, handleGetCalendarMembers);
}
function* handleGetCalendarMembers() {

    try {
        const token = yield select(getToken);

        const currentUserData = yield axios({
            method: "get",
            url: GET_LIST_OF_GOOGLE_MEMBERS(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then(response => response.data.data);

        if (currentUserData.length > 0) {
            yield put(getCalendarGoogleMembersSuccess(currentUserData));
        } else {
            yield put(getCalendarGoogleMembersEmpty(currentUserData));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getCalendarGoogleMembersError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* calendarGoogleMembersSaga() {
    yield fork(watchGetCalendarMembers);
}