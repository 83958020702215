import ActionListItem from 'components/pages/Programmes/SingleProgramme/ProgrammeTopics/ActionListItem/ActionListItem';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteActivityActionItem, updateActivityActionItem, updateProgramsTodos } from 'redux/actions';
import uniqid from "uniqid";
import './TodoItem.scss';

const TodoItem = ({ program, name, todos, activityId }) => {
    const dispatch = useDispatch();
    const [actionList, setActionList] = useState(todos);

    const toggleCompleted = (id, due_date, description, is_done) => {
        const changedItemIndex = actionList.findIndex(item => item.id === id);

        if (changedItemIndex !== -1) {
            const updatedActionList = actionList.map((item, index) => {
                if (index === changedItemIndex) {
                    item.is_done = !item.is_done;
                }

                return item;
            });

            setActionList(updatedActionList);
            dispatch(updateProgramsTodos({ activityId, updatedActionList }))

            dispatch(updateActivityActionItem({
                id: id,
                due_date: due_date,
                description: description,
                is_done: is_done
            }));
        }
    }

    const removeActionItem = (id, activityId) => {
        const updatedActionList = actionList.filter(item => item.id !== id);

        setActionList(updatedActionList);
        dispatch(deleteActivityActionItem(activityId, id))
        dispatch(updateProgramsTodos({ activityId, updatedActionList }))
    };

    useEffect(() => {
        setActionList(todos);
    }, [todos]);

    const actionItems = actionList.map(item =>
        <ActionListItem
            isDashboardPage={true}
            key={uniqid()}
            toggleCompleted={toggleCompleted}
            removeActionItem={removeActionItem}
            activityId={activityId}
            {...item}
        />
    );

    return (
        <div className='todo-item'>
            <div className="todo-item__program-name">{program?.name}</div>
            <Link className="todo-item__name" to={`/programmes/single-program/${program.id}?activityId=${activityId}`}>{name}</Link>
            <ul className="todo-item__list">
                {actionItems}
            </ul>
        </div>
    )
}

export default TodoItem;
