import {participantsList} from "redux/constants";

export function getParticipantsList(partisipantDefaultData) {
    return {
        type: participantsList.GET_LIST_PARTICIPANTS,
        payload: partisipantDefaultData,
    };
}

export function getParticipantsListSuccess(participantsSuccessList) {
    return {
        type: participantsList.GET_LIST_PARTICIPANTS_SUCCESS,
        payload: participantsSuccessList,

    };
}

export function getParticipantsListEmpty(emptylist) {
    return {
        type: participantsList.GET_LIST_PARTICIPANTS_NULL,
        payload: emptylist,
    };
}

export function getParticipantsListError(error) {
    return {
        type: participantsList.GET_LIST_PARTICIPANTS_ERROR,
        payload: error,
    };
}

export function getNewItemsOnScroll(getNewItems) {
    return {
        type: participantsList.GET_NEW_ITEMS_ON_SCROLL,
        payload: getNewItems,
    };
}

export function setClearFilters(clearSetNewData) {
    return {
        type: participantsList.CLEAR_FILTERS,
        payload: clearSetNewData,
    };
}

export function clearParticipantsList() {
    return {
        type: participantsList.CLEAR_PARTICIPANTS_LIST
    };
}