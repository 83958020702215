import {
    GET_SCHOOL_INFO,
    GET_SCHOOL_INFO_SUCCESS,
    GET_SCHOOL_INFO_ERROR,
    REGISTER_FOR_PROGRAM,
    REGISTER_FOR_PROGRAM_SUCCESS,
    REGISTER_FOR_PROGRAM_ERROR
} from "redux/constants";

export function getSchoolInfo(requestData) {
    return {
        type: GET_SCHOOL_INFO,
        payload: requestData,
    }
}

export function getSchoolInfoSuccess(data) {
    return {
        type: GET_SCHOOL_INFO_SUCCESS,
        payload: data
    }
}

export function getSchoolInfoError(errorData) {
    return {
        type: GET_SCHOOL_INFO_ERROR,
        payload: errorData,
    }
}

export function registerForProgram(requestData, callback) {
    return {
        type: REGISTER_FOR_PROGRAM,
        payload: requestData,
        callback
    }
}

export function registerForProgramSuccess(successData) {
    return {
        type: REGISTER_FOR_PROGRAM_SUCCESS,
        payload: successData
    }
}

export function registerForProgramError(errorData) {
    return {
        type: REGISTER_FOR_PROGRAM_ERROR,
        payload: errorData,
    }
}