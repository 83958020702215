import React, {useEffect, useState} from "react";
import "./Notification.scss";
import {CSSTransition} from "react-transition-group";
import {useDispatch, useSelector} from "react-redux";
import {hideNotification} from "redux/actions";

export default function Notification() {
    let defaultShowTimerId = null;
    const nodeRef = React.useRef(null);
    const dispatch = useDispatch();
    const defaultTimeOut = 4000;

    const {show, content, closeTimer} = useSelector(state => state.notification);

    useEffect(() => {
        if (show) {

            if (closeTimer && typeof closeTimer === 'number') {

                defaultShowTimerId = setTimeout(closeNotification, closeTimer);

            } else if (closeTimer) {

                defaultShowTimerId = setTimeout(closeNotification, defaultTimeOut);
            }

            return () => {
                clearTimeout(defaultShowTimerId);
                closeNotification();
            };
        }
    }, [show]);


    const closeNotification = () => {

        setTimeout(() => {
            dispatch(hideNotification());
        }, 500);
    };

    return (
        <>
            <CSSTransition in={show}
                           timeout={500}
                           unmountOnExit
                           nodeRef={nodeRef}
                           classNames={'notification-'}
            >
                <div className="notification" ref={nodeRef}>
                    <div className="notification__content">
                        <button onClick={closeNotification} className="close-btn"></button>
                        {/* <img src={EmptyChat} alt=""/> */}
                        <h3 className={`notification-title`}>{content?.title}</h3>
                        <p className={`notification-text`}>
                            {content?.text}
                        </p>
                    </div>
                </div>
            </CSSTransition>
        </>
    )
}