import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import "./MyProfile.scss";

import CoachesProfile from "./profile-coach";
import ParticipantProfile from "./profile-participant";
import SchoolAdminProfile from "./profile-school-admin";
import NotFound from "components/pages/NotFound/NotFound";

import {updatePageTitle} from "redux/actions";

import pageTitles from "constants/pageTitles";

export default function Profile() {

    const dispatch = useDispatch();

    const getCurrentUserInfo = useSelector(state => state.currentUserInfo.currentUserInfo);

    const {MY_PROFILE_TITLE} = pageTitles;


    useEffect(() => {
        dispatch(updatePageTitle(MY_PROFILE_TITLE));

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

    }, []);


    const switchRole = (userRole, getCurrentUserInfo) => {
        switch (userRole) {
            case 'coach':
                return <CoachesProfile getCurrentUserInfo={getCurrentUserInfo}/>;
            case 'participant':
                return <ParticipantProfile getCurrentUserInfo={getCurrentUserInfo}/>;
            case 'school_admin':
                return <SchoolAdminProfile getCurrentUserInfo={getCurrentUserInfo}/>;
            default:
                return <NotFound />;
        }
    }

    return (
        <section className="section-wrap">

            {
                getCurrentUserInfo &&

                switchRole(getCurrentUserInfo.role, getCurrentUserInfo)

            }

        </section>
    );
};