import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { participantsList } from "redux/constants";

import {
    getParticipantsListSuccess,
    getParticipantsListEmpty,
    getParticipantsListError,
} from "redux/actions/participantsList"

import { GET_LIST_OF_PARTICIPANTS } from "constants/api"
import { generalApiErrorHandler } from "redux/sagas/helpers/generalApiErrorHandler";
import { showNotification } from "redux/actions";
import { getNotificationMessage } from "helpers/notifications";
import { getToken } from "redux/sagas/helpers/helpers";

function* watchGetParticipantsList() {
    yield takeLatest(participantsList.GET_LIST_PARTICIPANTS, handleGetParticipantsList);
}
function* handleGetParticipantsList({ payload }) {
    const { participantsExist } = yield select(state => state.participantsList);

    try {
        const token = yield select(getToken);

        const currentUserData = yield axios({
            method: "get",
            url: GET_LIST_OF_PARTICIPANTS() + `?offset=${payload.offset}&take=${payload.take}${payload.search && `&search=${payload.search}`}${payload.programs.length > 0 ? `&programs=[${payload.programs}]` : ''}${payload.functional_areas.length > 0 ? `&functional_areas=[${payload.functional_areas}]` : ''}${payload.industry_background.length > 0 ? `&industry_background=[${payload.industry_background}]` : ''}${payload.languages.length > 0 ? `&languages=[${payload.languages}]` : ''}${payload.programStatus && `&programStatus=${payload.programStatus}`}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then(response => response.data.data);


        if (currentUserData.participants.length > 0) {
            yield put(getParticipantsListSuccess(currentUserData));
        } else if (participantsExist) {
            yield put(getParticipantsListSuccess(currentUserData));
        } else {
            yield put(getParticipantsListEmpty(currentUserData));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getParticipantsListError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* watchGetNewItemsOnScroll() {
    yield takeLatest(participantsList.GET_NEW_ITEMS_ON_SCROLL, handleGetNewItemsOnScroll);
}
function* handleGetNewItemsOnScroll({ payload }) {

    try {
        const token = yield select(getToken);

        const currentParticipantsList = yield select(state => state.participantsList.participantsList);

        const getParticipantDataByScroll = yield axios({
            method: "get",
            url: GET_LIST_OF_PARTICIPANTS() + `?offset=${payload.offset}&take=${payload.take}${payload.search && `&search=${payload.search}`}${payload.programs.length > 0 ? `&programs=[${payload.programs}]` : ''}${payload.functional_areas.length > 0 ? `&functional_areas=[${payload.functional_areas}]` : ''}${payload.industry_background.length > 0 ? `&industry_background=[${payload.industry_background}]` : ''}${payload.languages.length > 0 ? `&languages=[${payload.languages}]` : ''}${payload.programStatus && `&programStatus=${payload.programStatus}`}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then(response => response.data.data);

        const concatParticipantItemsData = currentParticipantsList.participants.concat(getParticipantDataByScroll.participants);

        const newParticipantItemsData = { ...getParticipantDataByScroll, participants: concatParticipantItemsData };

        if (getParticipantDataByScroll.participants.length > 0) {
            yield put(getParticipantsListSuccess(newParticipantItemsData));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getParticipantsListError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}


function* watchClearFilters() {
    yield takeLatest(participantsList.CLEAR_FILTERS, handleClearFilters);
}
function* handleClearFilters({ payload }) {

    try {
        const token = yield select(getToken);

        const getParticipantDataAfterResetFilters = yield axios({
            method: "get",
            url: GET_LIST_OF_PARTICIPANTS() + `?offset=${payload.offset}&take=${payload.take}${payload.search && `&search=${payload.search}`}${payload.programs.length > 0 ? `&programs=[${payload.programs}]` : ''}${payload.functional_areas.length > 0 ? `&functional_areas=[${payload.functional_areas}]` : ''}${payload.industry_background.length > 0 ? `&industry_background=[${payload.industry_background}]` : ''}${payload.languages.length > 0 ? `&languages=[${payload.languages}]` : ''}&programStatus=${payload.programStatus}${payload.programStatus && `&programStatus=${payload.programStatus}`}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then(response => response.data.data);


        if (getParticipantDataAfterResetFilters.participants.length > 0) {
            yield put(getParticipantsListSuccess(getParticipantDataAfterResetFilters));
        } else {
            yield put(getParticipantsListEmpty(null));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(getParticipantsListError(error.message));

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}


export default function* participantsListSaga() {
    yield fork(watchGetParticipantsList);
    yield fork(watchGetNewItemsOnScroll);
    yield fork(watchClearFilters);
}