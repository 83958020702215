import React from 'react';
import { useDispatch } from 'react-redux';
import emptyImage from 'assets/vector/dashboard/empty_data.svg';
import emptyActivities from 'assets/vector/dashboard/empty_activities.svg';
import emptyTodos from 'assets/vector/dashboard/empty_todos.svg';
import emptyChats from 'assets/vector/dashboard/empty_chats.svg';
import { openModal, setChatOpen } from 'redux/actions';
import { CREATE_SESSION_MODAL } from 'redux/constants/modal';

import './EmptyData.scss';

const EmptyData = ({ type, button = false, text = 'No actions yet' }) => {

    const dispatch = useDispatch();

    const renderImage = (type) => {
        switch (type) {
            case 'activity':
                return emptyActivities;
            case 'todo':
                return emptyTodos;
            case 'chat':
                return emptyChats;
            default:
                return emptyImage;
        }
    }

    const onClickHandler = (type) => {
        if (type === 'chat') {
            dispatch(setChatOpen(true))
        } else {
            dispatch(openModal(CREATE_SESSION_MODAL))
        }
    }

    return (
        <div className='empty-data'>
            <img className="empty-data__image" alt='empty-data' src={renderImage(type)} />
            <div className="empty-data__text">{text}</div>
            {button &&
                <div className="empty-data__button" onClick={() => onClickHandler(type)}>
                    {type === 'chat' ? 'Start conversation' : 'Plan next session'}
                </div>
            }
        </div>
    )
}

export default EmptyData;
