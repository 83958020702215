import { SHOW_MISSING_FEEDBACK_FORM_NOTIFICATION, HIDE_MISSING_FEEDBACK_FORM_NOTIFICATION } from 'redux/constants';

const initialState = {
    show: true,
};

export default (state = initialState, action) => {

    const { type } = action;

    switch (type) {
        case SHOW_MISSING_FEEDBACK_FORM_NOTIFICATION:
            return {
                ...state,
                show: true,
            };
        case HIDE_MISSING_FEEDBACK_FORM_NOTIFICATION:
            return {
                ...state,
                show: false,
            };
        default:
            return state;
    }
}