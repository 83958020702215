import React, {useEffect} from 'react';
import './SingleReport.scss';
import {Link, useParams} from "react-router-dom";
import GoBackArrowIcon from "components/icons/GoBackArrowIcon";
import ReportContent from "components/pages/Reports/SingleReport/ReportContent/ReportContent";
import routes from "constants/routes";
import StarRating from "components/starRating/StarRating";  
import {useDispatch, useSelector} from "react-redux";
import {clearReportData, getReport} from "redux/actions";
import NotFound from "components/pages/NotFound/NotFound";
import Spinner from "components/Spinner/Spinner";

const SingleReport = () => {
    const {id: currentReportId} = useParams();
    const {REPORTS_PAGE} = routes;
    const dispatch = useDispatch();
    const {singleReport, getSingleReportRequest, getSingleReportError} = useSelector(state => state.reports);
    const isReportExist = Object.keys(singleReport).length !== 0 && singleReport.constructor === Object;

    useEffect(() => {
        dispatch(getReport(currentReportId));

        return () => {
            dispatch(clearReportData())
        }
    }, [currentReportId]);

    return (
        <section className="single-report">
            {
                isReportExist ?

                    <>
                        <div className="single-report__head">
                            <div className="single-report__topic-info">
                                <Link to={REPORTS_PAGE} className="single-report__topic-link">
                                    <GoBackArrowIcon className="back-icon"/>
                                    <span
                                        className="single-report__topic-num">Module {singleReport.topic_number} {singleReport.topic_name}</span>
                                </Link>
                                {/* <time>12 August 2021</time> */}
                            </div>
                            <Link to={REPORTS_PAGE} className="single-report__title">
                                <GoBackArrowIcon className="back-icon"/>
                                <h3 className="heading">{singleReport.form_template_name}</h3>
                            </Link>
                            <div className="single-report__user">
                                <div className='userInfo'>
                                    {/* <img src="https://picsum.photos/200" alt="user avatar" className="single-report__user-img"/> */}
                                    <div>
                                        <p className="single-report__user-name" >{singleReport.name}</p>
                                        <p className="single-report__user-role">{singleReport.role}</p>
                                    </div>
                                </div>
                                <StarRating rating={singleReport.stars_rating} className="report-content__rating"/>
                            </div>
                        </div>
                        <ReportContent contentData={singleReport.report_data}/>
                    </>

                    :

                    getSingleReportError ?

                        <NotFound/>

                        :

                        <Spinner />  

            }
        </section>
    );
};

export default SingleReport;