import {completeTopic} from "helpers/userPrograms";
import {transformCategorizedDescriptions} from "redux/sagas/helpers/userPrograms";

export const updateSingleProgramSession = (program, updatedSession) => {
    if (updatedSession) {
        const amendTopicId = updatedSession?.coaching_program?.activity_id || updatedSession?.school_program?.activity_id;
        const shouldSessionCompleteActivity = updatedSession?.is_activity_completed;
        let updatedTopics = [];

        updatedTopics = program.topics?.map((topics) => {
            const activityIndex = topics.findIndex(activity => {
                if (updatedSession.session_type === 'group') {
                    return activity.type !== 'module' && activity.parent_activity_id === amendTopicId;
                }

                return activity.type !== 'module' && activity.id === amendTopicId;
            });

            if (activityIndex !== -1) {
                topics[activityIndex]['session'] = updatedSession;

                if (shouldSessionCompleteActivity) {
                    topics[activityIndex]['completed'] = true;
                }
            }

            return topics;
        });

        return {
            ...program,
            topics: updatedTopics
        }
    }

    return {...program};
}

export const getSession = (topics = [], activityId) => {
    const activity = topics.flat().find(activity => activity.id === activityId);

    return activity.session;
};

export const hasSessionChanged = (oldSession, newSession) => {
    if (oldSession && newSession) {
        return (
            oldSession['date_start'] !== newSession['date_start']
            || oldSession['link'] !== newSession['link']
            || oldSession['name'] !== newSession['name']
            || oldSession['status'] !== newSession['status']
        )
    }

    return !!(oldSession || newSession);
};

export const updateActionItemSingleProgram = (program, updatedActionItems) => {
    const activeTopicId = program?.activeTopic;

    program.topics?.map((topics) => {
        const activityIndex = topics.findIndex(activity => activity.type !== 'module' && activity.uniqId === activeTopicId);

        if (activityIndex !== -1) {
            topics[activityIndex]['todo_tasks'] = updatedActionItems;
        }
        return topics;
    });

    return {...program};
}

export const updateAttachedFilesSingleProgram = (program, updatedData) => {
    const activeTopicId = program?.activeTopic;

    program.topics?.map((topics) => {
        const activityIndex = topics.findIndex(activity => activity.uniqId === activeTopicId);

        if (activityIndex !== -1) {
            topics[activityIndex]['files'] = updatedData;
        }
        return topics;
    });

    return {...program};
}

export const completeSingleProgramActivity = (program, completedActivityId) => {
    if (completedActivityId) {
        let updatedTopics = [];

        updatedTopics = program.topics?.map((topics) => {
            const activityIndex = topics.findIndex(activity => activity.type !== 'module' && activity.id === completedActivityId);

            if (activityIndex !== -1) {
                topics[activityIndex]['completed'] = true;
            }

            return completeTopic(topics);
        });

        return {
            ...program,
            topics: updatedTopics
        }
    }

    return {...program}
}

export const updateSingleProgramTopicDescription = (program, changedTopics) => {
    if (changedTopics) {
        const {topics, topic_id} = changedTopics;
        const amendedTopic = topics.find(topic => topic.id === topic_id);
        const amendedTopicDescription = amendedTopic?.description;

        let updatedTopics = [];

        updatedTopics = program.topics?.map((topics) => {
            const activityIndex = topics.findIndex(topic => topic.type === 'module' && topic.id === topic_id);

            if (activityIndex !== -1) {
                topics[activityIndex]['description'] = amendedTopicDescription;
            }

            return topics;
        });

        return {
            ...program,
            topics: updatedTopics
        }
    }

    return {...program};
}

export const updateSingleProgramCategoryDescription = (program, changedCategories) => {
    if (changedCategories) {
        const {categories, activity_id} = changedCategories;
        let updatedTopics = [];

        updatedTopics = program.topics?.map((topics) => {
            const activityIndex = topics.findIndex(activity => activity.type !== 'module' && activity.id === activity_id);

            if (activityIndex !== -1) {
                topics[activityIndex]['categorized_descriptions'] = transformCategorizedDescriptions(categories);
            }

            return topics;
        });

        return {
            ...program,
            topics: updatedTopics
        }
    }

    return {...program};
}

export const updateSingleProgramActivityDescription = (program, changedTopics) => {
    if (changedTopics) {
        const {topics, activity_id} = changedTopics;
        const amendedActivity = topics.reduce((activity, topic) => {
            const changedActivity = topic.activities.find(activity => activity.id === activity_id);

            if (changedActivity) {
                return changedActivity
            }

            return activity;

        }, {})

        const amendActivityDescription = amendedActivity.details;
        let updatedTopics = [];

        updatedTopics = program.topics.map((topics) => {
            const activityIndex = topics.findIndex(activity => activity.type !== 'module' && activity.id === activity_id);

            if (activityIndex !== -1) {
                topics[activityIndex]['details'] = amendActivityDescription;
            }

            return topics;
        });

        return {
            ...program,
            topics: updatedTopics
        }
    }

    return {...program};
};

export const updateAdditionalSession = (programs = [], updatedProgramId) => {
    if (updatedProgramId) {
        return programs.map(program => {
            if (program.id === updatedProgramId) {
                program.can_request_additional_session = false;
                program.additional_session_request_sent = true;
            }

            return program;
        });
    }

    return {
        ...programs
    }
}

