import {postData} from "redux/sagas/helpers/requestHandlers";
import {ADDITIONAL_SESSION_ROUTE} from "constants/api";
import {put, call, select} from "redux-saga/effects";
import {sessionRequestError, sessionRequestSuccess, showNotification} from "redux/actions";
import ResponseError from "helpers/ResponseError";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

export function* createSessionRequest({payload: {programId, description}}) {
    const token = yield select(getToken);
    const route = ADDITIONAL_SESSION_ROUTE(programId);

    const {data} = yield call(postData(route, {description}, token));

    if (data?.status === 'Success') {
        yield put(sessionRequestSuccess(programId));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        yield put(sessionRequestError(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}