import React from 'react';
import TimePicker from "react-time-picker";
import SwitchArrowIcon from "components/icons/SwitchArrowIcon";
import './EventSessionTimePicker.scss';

const EventSessionTimePicker = ({time, setTime}) => {
        const getTimeFormat = (isDefault = false) => {
            const defaultTimeFormat = 'AM';
            const emptyTimeFormat = '-';

            if (time) {
                return time.split(':')[2];

            } else if (isDefault) {
                return defaultTimeFormat;
            }

            return emptyTimeFormat;
        }

        const addHour = () => {
            const maxHours = 12;
            let hours = time ? Number(time.split(':')[0]) : 0;
            const minutes = time ? time.split(':')[1] : '00';
            let timeFormat = getTimeFormat(true);
            const addedHours = String(hours < maxHours ? ++hours : 1);
            const newHours = addedHours.length > 1 ? addedHours : '0' + addedHours;

            setTime(`${newHours}:${minutes}:${timeFormat}`);
        }

        const deductHour = () => {
            const minHours = 1;
            let hours = time ? Number(time.split(':')[0]) : 0;
            const minutes = time ? time.split(':')[1] : '00';
            let timeFormat = getTimeFormat(true);
            const deductedHours = String(hours > minHours ? --hours : 12);
            const newHours = deductedHours.length > 1 ? deductedHours : '0' + deductedHours;

            setTime(`${newHours}:${minutes}:${timeFormat}`);
        }

        const addMinute = () => {
            const maxMinutes = 59;
            const hours = time ? time.split(':')[0] : '12';
            let minutes = time ? Number(time.split(':')[1]) : 0;
            let timeFormat = getTimeFormat(true);
            const addedMinutes = String(minutes < maxMinutes ? ++minutes : 0);
            const newMinutes = addedMinutes.length > 1 ? addedMinutes : '0' + addedMinutes;

            setTime(`${hours}:${newMinutes}:${timeFormat}`);
        }

        const deductMinute = () => {
            const minMinutes = 0;
            const hours = time ? time.split(':')[0] : '12';
            let minutes = time ? Number(time.split(':')[1]) : 0;
            const timeFormat = getTimeFormat(true);
            const deductedMinutes = String(minutes > minMinutes ? --minutes : 59);
            const newMinutes = deductedMinutes.length > 1 ? deductedMinutes : '0' + deductedMinutes;

            setTime(`${hours}:${newMinutes}:${timeFormat}`);
        }

        const toggleAmPm = () => {
            const hours = time ? time.split(':')[0] : '0';
            const minutes = time ? time.split(':')[1] : '0';
            let timeFormat = getTimeFormat(true);
            const newMinutes = minutes.length > 1 ? minutes : '0' + minutes;
            const newHours = hours.length > 1 ? hours : '0' + hours;

            timeFormat = timeFormat === 'AM' ? 'PM' : 'AM';

            setTime(`${newHours}:${newMinutes}:${timeFormat}`);
        }

        const showTime = () => {
            return time ? time.split(':').slice(0, 2).join(':') : null;
        }

        const setUpTime = (time) => {
            const hours = time ? time.split(':')[0] : '1';
            let minutes = time ? time.split(':')[1] : '0';
            const timeFormat = getTimeFormat(true);
            const newMinutes = minutes.length > 1 ? minutes : '0' + minutes;
            const newHours = hours.length > 1 ? hours : '0' + hours;

            setTime(`${newHours}:${newMinutes}:${timeFormat}`)
        }

        return (
            <>
                <div className="time-picker">
                    <div className="time-picker__time">
                        <div className="time-picker__actions">
                            <SwitchArrowIcon onClick={addHour} className="time-picker__btn"/>
                            <SwitchArrowIcon onClick={addMinute}
                                             className="time-picker__action-icon time-picker__btn"
                            />
                        </div>
                        <TimePicker onChange={setUpTime}
                                    value={showTime()}
                                    disableClock={true}
                                    clearIcon={null}
                                    format={'h:m'}
                        />
                        <div className="time-picker__actions">
                            <SwitchArrowIcon className="time-picker__btn time-picker__btn--down"
                                             onClick={deductHour}/>
                            <SwitchArrowIcon onClick={deductMinute}
                                             className="time-picker__action-icon time-picker__btn time-picker__btn--down"/>
                        </div>
                    </div>
                    <div className="time-picker__ampm">
                        <SwitchArrowIcon className="time-picker__ampm-icon time-picker__btn"
                                         onClick={toggleAmPm}/>
                        <div className="time-picker__ampm-value">
                            {getTimeFormat()}
                        </div>
                        <SwitchArrowIcon className="time-picker__ampm-icon time-picker__btn time-picker__btn--down"
                                         onClick={toggleAmPm}/>
                    </div>
                </div>
            </>
        );
    }
;

export default EventSessionTimePicker;