import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import "./Participants.scss";
import pageTitles from "constants/pageTitles";
import { updatePageTitle, getParticipantsList } from "redux/actions";
import ParticipantsPage from "./ParticipantsPage";
import EmptyPage from "../EmptyPage/EmptyPage";
import { participantsPage } from "constants/emptyPages";
import Spinner from "components/Spinner/Spinner";
import ParticipantProfile from './ParticipantProfile';

export default function Participants() {

    const { path } = useRouteMatch();
    const [programStatus, setProgramStatus] = useState('active');

    const {
        PARTICIPANTS_TITLE,
    } = pageTitles;

    const dispatch = useDispatch();

    const participantsData = useSelector(state => state.participantsList.participantsList);
    const participantsExist = useSelector(state => state.participantsList.participantsExist);
    const participantsListLoading = useSelector(state => state.participantsList.participantsListLoading);
    const currentUserInfo = useSelector(state => state.currentUserInfo.currentUserInfo);

    const defaultShowItems = 10;

    useEffect(() => {
        dispatch(updatePageTitle(PARTICIPANTS_TITLE));

        dispatch(getParticipantsList({
            offset: 0,
            take: defaultShowItems,
            programStatus: currentUserInfo.role !== 'participant' ? programStatus : '',
            search: '',
            functional_areas: [],
            programs: [],
            industry_background: [],
            languages: [],
        }));
    }, []);

    return (
        <Switch>
            <Route exact path={path}>
                <section className={`section-wrap ${participantsExist ? '' : 'fullscreen'}`}>

                    {!participantsExist && participantsListLoading && <Spinner />}

                    {
                        participantsData &&
                        <>
                            {
                                participantsExist
                                    ?
                                    <ParticipantsPage
                                        programStatus={programStatus}
                                        setProgramStatus={setProgramStatus}
                                        participantsData={participantsData}
                                        defaultShowItems={defaultShowItems}
                                    />
                                    :
                                    <EmptyPage emptyPageValue={participantsPage} />
                            }
                        </>
                    }
                </section>
            </Route>

            <Route exact path={`${path}/:profileId`}>
                <ParticipantProfile />
            </Route>
        </Switch>
    );
};
