import React from 'react';

import { Radio, RadioGroup, FormControlLabel, makeStyles } from "@material-ui/core";
import { ErrorMessage } from 'formik';

const useStyles = makeStyles(theme => ({
    root: {
        color: "#4296E5",
        backgroundColor: 'rgb(66, 150, 229, 0.2)',
        "&:hover": {
            color: '#486EA0',
            backgroundColor: 'rgb(72, 110, 160, 0.4)'
        }
    }
}));

const CheckedIcon = () => {
    return (
        <svg width="14" height="12" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6274 1.30797C17.2175 0.75259 18.2241 -0.184623 19.1266 0.301339C21.4523 1.5509 18.5018 4.46673 17.5646 5.64692C16.072 7.55606 14.51 9.39583 12.9827 11.2703C11.8025 12.6934 10.7612 14.2555 9.4768 15.6439C8.74786 16.4423 7.49824 17.9002 6.28333 17.8307C5.2767 17.796 4.40891 17.0671 3.74939 16.3729C2.18737 14.7415 0.069957 12.1728 0.000533835 9.77766C-0.0341778 7.93795 1.63198 8.49333 2.63862 9.25699C4.02708 10.333 5.10314 11.652 6.35276 12.867C6.90814 11.8256 7.81064 10.9231 8.64372 10.0901C10.1016 8.59741 11.4207 7.0701 12.8438 5.54279C14.1282 4.15433 15.2737 2.62702 16.6274 1.30797Z" fill="white" />
        </svg>
    )
}

const RadioItems = ({
    field,
    name,
    options,
    children,
    question,
    item,
    values,
    goToNextStep,
    goToPreviousStep,
    stepDirection,
    ...props }) => {

    const fieldName = name || field.name;

    const classes = useStyles();

    const [currentOptions, setCurrentOptions] = React.useState(null);

    function areEqual(array1, array2) {
        if (array1.length === array2.length) {
            return array1.every((element, index) => {
                if (element === array2[index]) {
                    return true;
                }

                return false;
            });
        }

        return false;
    }

    const renderOptions = (options) => {

        return options.map((option, index) => (
            <FormControlLabel
                key={option.option ? option.option : index}
                value={option.key ? option.key : option.value ? option.value : option.option}
                label={option.label ? option.label : option.option}
                control={<Radio className={classes.root} checkedIcon={<CheckedIcon />} style={{ width: '40px', height: '40px' }} />}
            />
        ));
    };

    React.useEffect(() => {

        if (item?.condition && item.condition.filter(item => Array.isArray(item)).length === 0) {
            let array = item.condition

            let check = array?.filter(item => {
                if (typeof values[item.key] === 'number') {
                    return values[item.key] === item.val && item
                }
                return values[item.key].includes(item.val) && item
            })

            if (!check?.length) {
                if (stepDirection === "next") {
                    goToNextStep()
                }
                if (stepDirection === "back") {
                    goToPreviousStep()
                }
            }
        }

        if (item?.condition && item.condition.filter(item => Array.isArray(item)).length > 0) {
            let array;
            let check;

            let myOptions;

            for (let i = 0; i < item.condition.length; i++) {
                array = item.condition[i];

                check = array?.filter(item => {
                    return values[item.key].includes(item.val) && item
                })

                if (areEqual(array, check)) {
                    myOptions = options[i]
                }
            }

            if (myOptions) {
                setCurrentOptions(myOptions);
            } else {
                if (stepDirection === "next") {
                    goToNextStep()
                }
                if (stepDirection === "back") {
                    goToPreviousStep()
                }
            }

        }

    }, [item?.condition, goToNextStep, values, goToPreviousStep, stepDirection, options])

    return (
        <>
            <div className="form-question">{question}</div>
            <div className="radio__buttons __buttons" role="group" aria-labelledby="my-radio-group">
                <React.Fragment>
                    <RadioGroup {...field} {...props} name={fieldName}>
                        {options ? renderOptions(currentOptions ? currentOptions : options) : children}
                    </RadioGroup>
                </React.Fragment>
            </div>
            <ErrorMessage name={fieldName} render={msg => <p className="error-message">{msg}</p>} />
        </>
    )
}

export default RadioItems;