import React, {useEffect} from 'react';
import './DeclineModal.scss';
import {ReactComponent as Image} from "assets/vector/programmes/cancel-session.svg";
import Button from "components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {declineSessionInvitation, logout} from "redux/actions";
import {clearSessionInfo} from "redux/actions/createSession";
import useRequestStatus from "../../../../hooks/useRequestStatus";
import Notification from "components/auth/notification/Notification";

const DeclineModal = ({closeModal, modalData}) => {
    const dispatch = useDispatch();
    const {requestProgress, requestSuccess, requestError} = useRequestStatus(modalData.actionSelector);

    useEffect(() => {
        if (requestSuccess) {
            closeModal();
            dispatch(clearSessionInfo());
        }
    }, [requestSuccess]);

    return (
        <div className="decline-modal">
            <Image className="decline-modal__img"/>
            <h2 className="decline-modal__title">
                {modalData.title}
            </h2>
            {
                modalData.description && (
                    <p className="decline-modal__text">
                        {modalData.description}
                    </p>
                )
            }
            <div className="decline-modal__actions">
                <Button type="button"
                        loading={requestProgress}
                        className="button button--orange"
                        onClick={modalData.confirmAction}
                >
                    Confirm
                </Button>
                <Button type="button"
                        onClick={closeModal}
                        className="button button--orange-invert"
                >
                    Cancel
                </Button>
            </div>
            <Notification style={{position: 'static'}}
                          show={!!requestError}>
                {requestError}
            </Notification>
        </div>
    );
};

export default DeclineModal;