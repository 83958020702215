import {
    dashboardConstant
} from "redux/constants";

export function getSessions(schoolProgramID) {
    return {
        type: dashboardConstant.GET_SESSIONS,
        payload: schoolProgramID,
    }
}
export function getSessionsSuccess(sessionsData) {
    return {
        type: dashboardConstant.GET_SESSIONS_SUCCESS,
        payload: sessionsData,
    }
}
export function getSessionsError(error) {
    return {
        type: dashboardConstant.GET_SESSIONS_ERROR,
        payload: error,
    }
}
export function getSessionsLoadSuccess(sessionsLoadData) {
    return {
        type: dashboardConstant.GET_SESSIONS_LOAD_SUCCESS,
        payload: sessionsLoadData,
    }
}

export function getSessionsCredits(schoolProgramID) {
    return {
        type: dashboardConstant.GET_SESSIONS_CREDITS,
        payload: schoolProgramID,
    }
}
export function getSessionsCreditsSuccess(sessionsCreditsData) {
    return {
        type: dashboardConstant.GET_SESSIONS_CREDITS_SUCCESS,
        payload: sessionsCreditsData,
    }
}
export function getSessionsCreditsError(error) {
    return {
        type: dashboardConstant.GET_SESSIONS_CREDITS_ERROR,
        payload: error,
    }
}

export function getParticipants(schoolProgramID) {
    return {
        type: dashboardConstant.GET_PARTICIPANTS,
        payload: schoolProgramID,
    }
}
export function getParticipantsSuccess(participantsData) {
    return {
        type: dashboardConstant.GET_PARTICIPANTS_SUCCESS,
        payload: participantsData,
    }
}
export function getParticipantsError(error) {
    return {
        type: dashboardConstant.GET_PARTICIPANTS_ERROR,
        payload: error,
    }
}

export function getAttendanceRate(schoolProgramID) {
    return {
        type: dashboardConstant.GET_ATTENDANCE,
        payload: schoolProgramID,
    }
}
export function getAttendanceRateSuccess(attendanceRateData) {
    return {
        type: dashboardConstant.GET_ATTENDANCE_SUCCESS,
        payload: attendanceRateData,
    }
}
export function getAttendanceRateError(error) {
    return {
        type: dashboardConstant.GET_ATTENDANCE_ERROR,
        payload: error,
    }
}

export function getAverageMark(schoolProgramID) {
    return {
        type: dashboardConstant.GET_AVERAGE_MARK,
        payload: schoolProgramID,
    }
}
export function getAverageMarkSuccess(averageMarkData) {
    return {
        type: dashboardConstant.GET_AVERAGE_MARK_SUCCESS,
        payload: averageMarkData,
    }
}
export function getAverageMarkLoadSuccess(averageMarkLoadData) {
    return {
        type: dashboardConstant.GET_AVERAGE_MARK_LOAD_SUCCESS,
        payload: averageMarkLoadData,
    }
}
export function getAverageMarkError(error) {
    return {
        type: dashboardConstant.GET_AVERAGE_MARK_ERROR,
        payload: error,
    }
}


export function getListUserSchoolPrograms(params) {
    return {
        type: dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS,
        params
    }
}

export function getListUserSchoolProgramsOnScroll(params) {
    return {
        type: dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS_ON_SCROLL,
        params
    }
}

export function getListUserSchoolProgramsSuccess(listSchoolPrograms) {
    return {
        type: dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS_SUCCESS,
        payload: listSchoolPrograms,
    }
}

export function setCurrentSchoolProgram(schoolID) {
    return {
        type: dashboardConstant.SET_CURRENT_SCHOOL_PROGRAM,
        payload: schoolID,
    }
}

export function getListUserSchoolProgramsError(error) {
    return {
        type: dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS_ERROR,
        payload: error,
    }
}

export function showDashboarPage(status) {
    return {
        type: dashboardConstant.SHOW_DASHBOARD_PAGE,
        payload: status,
    }
}
export function loadDashboardPage(loadPage) {
    return {
        type: dashboardConstant.LOAD_DASHBOARD_PAGE,
        payload: loadPage,
    }
}

export function getSchoolOverviewGraphs() {
    return {
        type: dashboardConstant.GET_SCHOOL_OVERVIEW_GRAPHS,
    }
}
export function getSchoolOverviewGraphsSuccess(graphsData) {
    return {
        type: dashboardConstant.GET_SCHOOL_OVERVIEW_GRAPHS_SUCCESS,
        payload: graphsData,
    }
}
export function geSchoolOverviewGraphsError(error) {
    return {
        type: dashboardConstant.GET_SCHOOL_OVERVIEW_GRAPHS_ERROR,
        payload: error,
    }
}

export function clearDashboardData() {
    return {
        type: dashboardConstant.CLEAR_DASHBOARD_DATA
    };
}