import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

import "./Coaches.scss";

import pageTitles from "constants/pageTitles";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import ShowMoreText from 'react-show-more-text';
import { updatePageTitle } from "redux/actions";
import routes from "constants/routes";
import profile from "services/profile";
// for ILTB
// import backToCoaches from "assets/vector/arrow-back-to-coaches.svg";
import backToCoaches from "assets/vector/arrow-back-to-coaches-switch.svg";
import moment from "moment";
import defaultAva from 'assets/vector/default-avatar.svg';
import { ReactComponent as BulletSvg } from "assets/vector/bullet.svg";
import { CHOOSE_COACH_MODAL } from "redux/constants/modal";
import { openModal } from "redux/actions/modal";
import { CHOOSE_COACH_API } from 'constants/api';
import ResponseError from 'helpers/ResponseError';
import { showNotification } from "redux/actions";

import Spinner from "components/Spinner/Spinner";

const {
    COACHES,
    QUESTIONNAIRE_COACH_MATCHING,
    PROGRAMMES
} = routes;


export default function CoachesProfile({ matching = false, participant_program_id }) {
    const { state } = useLocation();
    let programToGoBack = PROGRAMMES;

    if (state?.program) {
        programToGoBack = `${PROGRAMMES}/single-program/${state.program}`
    }

    const {
        COACHES_TITLE,
    } = pageTitles;

    let { profileId } = useParams();
    const dispatch = useDispatch();
    const userToken = useSelector(state => state.auth.authToken);
    const { role: userRole } = useSelector(state => state.currentUserInfo.currentUserInfo) || {};

    const [coachProfile, setCoachProfile] = useState(null);

    const chooseMainCoach = () => {

        const data = {
            "participant_program_id": participant_program_id,
            "coach_id": Number(profileId)
        }
        axios({
            method: "post",
            url: CHOOSE_COACH_API,
            headers: {
                'Authorization': `Bearer ${userToken}`
            },
            accept: "application/json",
            data: data
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(openModal(CHOOSE_COACH_MODAL))
                }
            })
            .catch(error => {
                const responseError = new ResponseError(error.response.data);

                dispatch(showNotification({
                    title: responseError.getStatus(),
                    text: responseError.getValidationErrorMessages()
                }))
            });
    }

    useEffect(() => {

        dispatch(updatePageTitle(COACHES_TITLE));

        profile.getInfoAboutCouch(userToken, profileId)
            .then((response) => {
                setCoachProfile(response)
            })
            .catch(error => console.log(error))

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);


    return (
        <section className="section-wrap">

            {!coachProfile && <Spinner />}

            {coachProfile &&

                <div className="coach-profile">

                    <div className="short-info">
                        <div className="back-to-all-coaches">
                            <Link className="link-to-page"
                                to={
                                    matching
                                        ? QUESTIONNAIRE_COACH_MATCHING
                                        : userRole === 'participant' ? programToGoBack : COACHES
                                }>
                                <img src={backToCoaches} alt="back to coaches" />
                            </Link>
                        </div>

                        <div className="coach-avatar"><img src={coachProfile.avatar ? coachProfile.avatar : defaultAva} alt="avatar" /></div>

                        <div className="coach-short-info">
                            <h3 className={`name`}>{coachProfile.name} {coachProfile.surname}</h3>
                            <p className={`country`}>{coachProfile.location}</p>
                        </div>
                        <div className="buttons__wrapper">
                            {
                                matching && <button className='choose-coach__btn' onClick={() => chooseMainCoach()}>Choose coach</button>
                            }
                            {
                                coachProfile.cv &&

                                <div className="coach-full-info">

                                    <a target="_blank" href={coachProfile.cv} className="view-profile">
                                        Download CV
                                    </a>

                                </div>
                            }
                        </div>

                    </div>

                    {

                        (
                            coachProfile.summary ||
                            coachProfile.languages.length > 0 ||
                            coachProfile.functional_areas.length > 0 ||
                            coachProfile.seniority_level ||
                            coachProfile.coaching_areas.length > 0 ||
                            coachProfile.industry_background.length > 0 ||
                            coachProfile.geography.length > 0 ||
                            coachProfile.work_experience.length > 0 ||
                            coachProfile.publications.length > 0 ||
                            (coachProfile.coaching_experience.years && coachProfile.coaching_experience.hours && coachProfile.certification) ||
                            coachProfile.certification

                        )

                        &&

                        <div className="full-info">

                            {
                                coachProfile.summary &&
                                <div className="section-info-block summary">
                                    <h3 className="block-title">Summary</h3>
                                    <ShowMoreText
                                        /* Default options */
                                        lines={3}
                                        more='Show more'
                                        less='Show less'
                                        className='summary-description'
                                        anchorClass='show-more'
                                        // onClick={this.executeOnClick}
                                        expanded={false}
                                        keepNewLines={true}

                                    >
                                        {coachProfile.summary}
                                    </ShowMoreText>

                                </div>
                            }


                            {
                                coachProfile.languages.length > 0 &&
                                <div className="section-info-block languages">
                                    <h3 className="block-title">Languages</h3>

                                    <div className="languages-block">
                                        {
                                            coachProfile.languages.map(
                                                (element, index) => (
                                                    <div key={index} className="language">

                                                        <img className={`flag`} src={element.flag} alt="language flag" />
                                                        <div className="language">{element.name}</div>
                                                        <div className="experience">{element.level.name}</div>
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>

                                </div>
                            }


                            {
                                coachProfile.functional_areas.length > 0 &&
                                <div className="section-info-block functional-area">
                                    <h3 className="block-title">Functional Area</h3>

                                    <div className="block-items">
                                        {
                                            coachProfile.functional_areas.map(
                                                (element, index) => (
                                                    <span key={index} className="single-item">{element.name}</span>
                                                )
                                            )
                                        }
                                    </div>

                                </div>
                            }

                            {
                                coachProfile.seniority_level && coachProfile.seniority_level.name &&
                                <div className="section-info-block seniority-level">
                                    <h3 className="block-title">Seniority Level</h3>

                                    <div className="block-items">
                                        <span className="single-item">{coachProfile.seniority_level.name}</span>
                                    </div>
                                </div>
                            }


                            {

                                ((coachProfile.coaching_areas && coachProfile.coaching_areas.length > 0) || coachProfile.coaching_experience.hours || coachProfile.coaching_experience.years || coachProfile.certification) &&

                                <div className="section-info-block coaching-info">

                                    {
                                        coachProfile.coaching_areas &&
                                        coachProfile.coaching_areas.length > 0 &&

                                        <>
                                            <h3 className="block-title">Coaching information</h3>
                                            <div className="block-items">
                                                {
                                                    coachProfile.coaching_areas.map(
                                                        (element, index) => (
                                                            <span key={index} className="single-item">{element.name}</span>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </>
                                    }

                                    {

                                        <div className="coaching-experience">

                                            {
                                                coachProfile.coaching_experience.years &&
                                                <div className="coaching-experience-item">
                                                    <BulletSvg className={`icon-bullet`} />
                                                    <div className="coaching-information-label">Coaching experience</div>
                                                    <div className="coaching-information-value">{coachProfile.coaching_experience.years} years</div>
                                                </div>
                                            }

                                            {
                                                coachProfile.coaching_experience.hours &&
                                                <div className="coaching-experience-item">
                                                    <BulletSvg className={`icon-bullet`} />
                                                    <div className="coaching-information-label">Number of coached hours</div>
                                                    <div className="coaching-information-value">{coachProfile.coaching_experience.hours} hours</div>
                                                </div>
                                            }

                                            {
                                                coachProfile.certification &&

                                                <div className="coaching-experience-item">
                                                    <BulletSvg className={`icon-bullet`} />
                                                    <div className="coaching-information-label">Certifications</div>
                                                    <div className="coaching-information-value">{coachProfile.certification}</div>
                                                </div>
                                            }

                                        </div>
                                    }


                                </div>
                            }

                            {
                                coachProfile.industry_background.length > 0 &&
                                <div className="section-info-block industry-background">
                                    <h3 className="block-title">Industry Background</h3>

                                    <div className="block-items">
                                        {
                                            coachProfile.industry_background.map(
                                                (element, index) => (
                                                    <span key={index} className="single-item">{element.name}</span>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            }

                            {
                                coachProfile.geography.length > 0 &&
                                <div className="section-info-block geography">
                                    <h3 className="block-title">Geography</h3>
                                    <div className="block-items">
                                        {
                                            coachProfile.geography.map(
                                                (element, index) => (
                                                    <span key={index} className="single-item">{element.name}</span>
                                                )
                                            )
                                        }

                                    </div>
                                </div>
                            }

                            {
                                coachProfile.work_experience.length > 0 &&
                                <div className="section-info-block work-experience">
                                    <h3 className="block-title">Work experience</h3>

                                    <div className="work-experience-block">
                                        {

                                            coachProfile.work_experience.map(
                                                (element, index) => (
                                                    <div key={index} className="experience-item">

                                                        <div className="experience-item-left">
                                                            <div className="position">{element.position}</div>
                                                            <div className="company">{element.company}</div>
                                                        </div>

                                                        <div className="experience-item-right">
                                                            <div className="date">
                                                                <span className="from">{moment(element.date_from).format("MMMM YYYY")}</span> - <span className="to">{element.date_to !== "" ? moment(element.date_to).format("MMMM YYYY") : "Present"}</span>
                                                            </div>
                                                            <div className="job-responsibilities">{element.responsibilities}</div>
                                                        </div>

                                                    </div>
                                                )
                                            )
                                        }
                                    </div>


                                </div>
                            }

                            {
                                coachProfile.publications.length > 0 &&
                                <div className="section-info-block publications">
                                    <h3 className="block-title">Publications</h3>

                                    <div className="publications-block">
                                        {
                                            coachProfile.publications.map(
                                                (element, index) => (
                                                    <div key={index} className="publications-item">
                                                        <div className="item-title">{element.name}</div>
                                                        <div className="item-year">{element.publishing_house}</div>
                                                        <div className="item-author">{element.author}</div>
                                                        <div className="item-short-description">{element.overview}</div>
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>

                                </div>
                            }


                        </div>
                    }

                </div>
            }

        </section>
    );
};