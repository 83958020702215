import React, { useEffect } from 'react';

import './CoachMatching.scss';

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FIND_ANOTHER_COACH_MODAL } from "redux/constants/modal";
import { openModal } from "redux/actions/modal";
import Footer from 'components/pages/Auth/Footer/Footer';
import Header from 'components/pages/Auth/Header/Header';
import CoachPreview from 'components/CoachPreview/CoachPreview';
import DownloadReport from './DownLoadReport/DownloadReport';
import { REQUEST_NEW_COACH_API } from 'constants/api';
import ResponseError from 'helpers/ResponseError';
import { getMatchedCoaches, showNotification } from "redux/actions";
import WithSpinner from "components/HOC/withSpinner";
import MatchedCoaches from './MatchedCoaches/MatchedCoaches';
import routes from 'constants/routes';
import { Redirect, useLocation, useParams } from 'react-router-dom';

// for ILTB
// import decor_image1 from 'assets/vector/auth-page/decor-3.svg';
// import decor_image2 from 'assets/vector/auth-page/auth-decor-lines.svg';


const MatchedCoachesContent = WithSpinner(MatchedCoaches);

const CoachMatching = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const { DASHBOARD } = routes;
    const { matchedCoaches, suggestedCoaches, manualSuggestedCoaches, getMatchedCoachesProgress } = useSelector(state => state.matchedCoaches)
    const { authToken, mainCoach } = useSelector((state) => state.auth);
    const { last_school_program_id, last_participant_program_id, survey, main_coach } = useSelector(state => state.currentUserInfo?.currentUserInfo) || {};

    const urlSearchParams = new URLSearchParams(location.search);
    const school_program_id = urlSearchParams.get('school_program_id')
    const participant_program_id = urlSearchParams.get('participant_program_id')

    const isMainCoachExist = (mainCoach || main_coach);
    const currentSchoolProgramId = school_program_id ? school_program_id : +last_school_program_id;
    const currentParticipantProgramId = participant_program_id ? participant_program_id : +last_participant_program_id;

    const coachesData = {
        matched_coaches: matchedCoaches,
        suggested_coaches: suggestedCoaches,
        manual_suggested_coaches: manualSuggestedCoaches
    }

    const showMatchedCoaches = (arr) => {
        return arr?.sort((a, b) => b.match - a.match).slice(0, 3).map(coach => {
            return (
                <CoachPreview
                    id={coach.id}
                    key={coach.id}
                    name={coach.name}
                    surname={coach.surname}
                    image={coach.avatar}
                    location={coach.location}
                    experience={coach.industry_background}
                    choose_coach={true}
                    matching={coach.match}
                    participant_program_id={currentParticipantProgramId}
                />
            )
        })
    }

    const requestNewCoach = (id) => {
        const data = {
            "participant_program_id": id
        }
        axios({
            method: "post",
            url: REQUEST_NEW_COACH_API,
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
            accept: "application/json",
            data: data
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(openModal(FIND_ANOTHER_COACH_MODAL))
                }
            })
            .catch(error => {
                const responseError = new ResponseError(error.response.data);

                dispatch(showNotification({
                    title: responseError.getStatus(),
                    text: responseError.getValidationErrorMessages()
                }))
            });
    }

    useEffect(() => {
        if (currentSchoolProgramId && !matchedCoaches.length && !suggestedCoaches.length && !manualSuggestedCoaches.length) {
            dispatch(getMatchedCoaches());
        }

    }, [currentSchoolProgramId, matchedCoaches, suggestedCoaches, manualSuggestedCoaches]);

    useEffect(() => {
        if (isMainCoachExist && !participant_program_id && !school_program_id) {
            dispatch(showNotification({
                title: 'The main coach was already assigned'
            }))
        }
    }, [isMainCoachExist, participant_program_id, school_program_id])

    if (isMainCoachExist && !participant_program_id && !school_program_id) {
        return <Redirect to={DASHBOARD} />
    }

    return (
        <>
            <Header />
            <section className="coach-matching">
                {/* for ILTB */}
                {/* <img className="decor-image-left" src={decor_image1} alt="" />
                <img className="decor-image-right" src={decor_image2} alt="" /> */}
                {survey?.survey_id === 1 && <DownloadReport />}
                <div className="matched-coaches__wrapper">
                    <MatchedCoachesContent
                        spinnerProps={{
                            color: `var(--loader-color)`,
                            loading: getMatchedCoachesProgress,
                            size: 16,
                            margin: 10
                        }}
                        data={coachesData}
                        participant_program_id={currentParticipantProgramId}
                        showMatchedCoaches={showMatchedCoaches}
                        requestNewCoach={requestNewCoach}
                    />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CoachMatching;
