import { GET_PROGRAMS_ACTIVITIES, GET_PROGRAMS_ACTIVITIES_ERROR, GET_PROGRAMS_ACTIVITIES_SUCCESS, GET_PROGRAMS_PROGRESS, GET_PROGRAMS_PROGRESS_ERROR, GET_PROGRAMS_PROGRESS_SUCCESS, GET_PROGRAMS_TODOS, GET_PROGRAMS_TODOS_ERROR, GET_PROGRAMS_TODOS_SUCCESS, GET_UPCOMING_SESSIONS, GET_UPCOMING_SESSIONS_ERROR, GET_UPCOMING_SESSIONS_SUCCESS, UPDATE_PROGRAMS_TODOS } from 'redux/constants'

// programs progress
export function getProgramsProgress() {
    return {
        type: GET_PROGRAMS_PROGRESS,
    }
}
export function getProgramsProgressSuccess(programsData) {
    return {
        type: GET_PROGRAMS_PROGRESS_SUCCESS,
        payload: programsData,
    }
}
export function getProgramsProgressError(error) {
    return {
        type: GET_PROGRAMS_PROGRESS_ERROR,
        payload: error,
    }
}

// programs activities
export function getProgramsActivities() {
    return {
        type: GET_PROGRAMS_ACTIVITIES,
    }
}
export function getProgramsActivitiesSuccess(activitiesData) {
    return {
        type: GET_PROGRAMS_ACTIVITIES_SUCCESS,
        payload: activitiesData,
    }
}
export function getProgramsActivitiesError(error) {
    return {
        type: GET_PROGRAMS_ACTIVITIES_ERROR,
        payload: error,
    }
}

// programs todos
export function getProgramsTodos() {
    return {
        type: GET_PROGRAMS_TODOS,
    }
}
export function getProgramsTodosSuccess(todosData) {
    return {
        type: GET_PROGRAMS_TODOS_SUCCESS,
        payload: todosData,
    }
}
export function getProgramsTodosError(error) {
    return {
        type: GET_PROGRAMS_TODOS_ERROR,
        payload: error,
    }
}
export function updateProgramsTodos(updatedData) {
    return {
        type: UPDATE_PROGRAMS_TODOS,
        payload: updatedData,
    }
}

// upcoming sessions
export function getUpcomingSessions() {
    return {
        type: GET_UPCOMING_SESSIONS,
    }
}
export function getUpcomingSessionsSuccess(sessions) {
    return {
        type: GET_UPCOMING_SESSIONS_SUCCESS,
        payload: sessions,
    }
}
export function getUpcomingSessionsError(error) {
    return {
        type: GET_UPCOMING_SESSIONS_ERROR,
        payload: error,
    }
}