import {HIDE_NOTIFICATION, SHOW_NOTIFICATION} from "redux/constants";
import {dashboardNotification} from "redux/constants";

const initialState = {
    show: false,
    content: {
        title: null,
        text: null
    },
    closeTimer: null,
    userNotification : null,
    userNotificationError : null,
    userNotificationEmpty : false,
    loadNotifications : false,
    countNotifications : null,
    totalNotifications : null,
};

export default (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SHOW_NOTIFICATION:
            return {
                ...state,
                show: true,
                content: payload.content,
                closeTimer: payload.closeTimer
            };
        case HIDE_NOTIFICATION:
            return {
                ...state,
                show: false,
                content: null,
                closeTimer: null
            };

        case dashboardNotification.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                userNotification: payload,
            };

        case dashboardNotification.GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                userNotificationError: payload,
            };

        case dashboardNotification.GET_NOTIFICATIONS_EMPTY:
            return {
                ...state,
                userNotificationEmpty: payload,
            };

        case dashboardNotification.LOAD_NOTIFICATIONS:
            return {
                ...state,
                loadNotifications: payload,
            };

        case dashboardNotification.SET_NOTIFICATIONS_COUNT:
            return {
                ...state,
                countNotifications: payload,
            };

        case dashboardNotification.CLEAR_ON_LOGOUT_ALL_DATA_NOTIFICATIONS:
            return {
                ...state,
                show: false,
                content: {
                    title: null,
                    text: null
                },
                userNotification : null,
                userNotificationError : null,
                userNotificationEmpty : false,
                loadNotifications : false,
                countNotifications : null,
            };

        case dashboardNotification.CLEAR_ALL_NOTIFICATION:
            return {
                ...state,
                userNotification : null,
                userNotificationError : null,
                userNotificationEmpty : false,
                loadNotifications : false,
                countNotifications : null,
            };

        case dashboardNotification.SEND_READ_NOTIFICATIONS:
            return {
                ...state,
                countNotifications : null,
            };

        case dashboardNotification.SET_TOTAL_NOTIFICATIONS:
            return {
                ...state,
                totalNotifications: payload,
            };


        default:
            return state;
    }
}