import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import "./Programmes.scss";
import { getSchoolAdminPrograms } from "redux/actions";
import SchoolAdminProgrammeCard from "components/pages/Programmes/programmeCard/schoolAdminProgrammeCard";
import SingleProgramme from "components/pages/Programmes/SingleProgramme/SingleProgramme";
import EmptyPage from "../EmptyPage/EmptyPage"
import { programmPage } from "constants/emptyPages";
import { useObserver } from 'hooks/useObserver';
import { Ellipsis } from 'react-spinners-css';
import { programStatusListSchoolAdmin } from 'helpers/programStatusList';
import CustomSchoolAdminProgramsSelect from 'components/CustomFormSelect/CustomSchoolAdminProgramsSelect';
import { PrivateRoute } from 'containers/PrivateRoute/PrivateRoute';
import withLoggedLayout from 'components/HOC/withLoggedLayout';
import NotFound from '../NotFound/NotFound';


export default function SchoolAdminProgram({ programStatus, setProgramStatus }) {

    const programsLimit = 10;
    const lastElement = useRef();

    const [programsOffset, setProgramsOffset] = useState(0);

    const dispatch = useDispatch();
    const { path } = useRouteMatch();

    const { programs, programsLoading, programsTotal } = useSelector(state => state.userPrograms);

    useEffect(() => {

        const params = {
            take: programsLimit,
            offset: programsOffset,
            programStatus: programStatus
        }
        dispatch(getSchoolAdminPrograms(params))

    }, [programsOffset, programStatus])

    useObserver(lastElement, (programsOffset + programsLimit) <= programsTotal, programsLoading, () => {
        setProgramsOffset(programsOffset + programsLimit);
    });

    return (
        <Switch>
            <Route exact path={path}>
                <div className="section-wrap">
                    <CustomSchoolAdminProgramsSelect
                        options={programStatusListSchoolAdmin}
                        programStatus={programStatus}
                        setProgramStatus={setProgramStatus}
                        setProgramsOffset={setProgramsOffset}
                    />
                    {
                        programs?.length > 0
                            ?
                            <>
                                {programs.map(item => <SchoolAdminProgrammeCard key={item.id} {...item} />)}
                                <div ref={lastElement}></div>
                                {programsLoading &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Ellipsis color="#2e79da" size={50} />
                                    </div>
                                }
                            </>
                            :
                            <>
                                <EmptyPage emptyPageValue={programmPage(programStatus === 'active')} />
                                <div ref={lastElement}></div>
                            </>
                    }
                </div>
            </Route>
            <>
                <Route path={`${path}/single-program`} component={NotFound} />
                <div ref={lastElement}></div>
            </>
        </Switch>
    );
};