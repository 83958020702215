import React, { useRef, useLayoutEffect } from 'react';
import TopicsContent from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/TopicsContent/TopicsContent";
import { getProgramIcon } from "components/pages/Programmes/helpers/getProgramIcon";
import LockIcon from "components/icons/LockIcon";
import { useSelector } from "react-redux";
import { checkBlockedActivity } from "helpers/userPrograms";
import { getOffsetPosition, scrollToEl } from "helpers/helpers";
import { useMediaQuery } from "react-responsive";

const ProgrammeTopicsListItem = ({
    id,
    uniqId,
    num,
    topicNum,
    type,
    topicId,
    typeName,
    name,
    isActive,
    isTopicHeader,
    coachId,
    handleSetActiveItem,
    isDeactivated,
    ...otherProps
}) => {
    const { topics: programTopics } = useSelector(state => state.userPrograms.singleProgram) || {};
    const activityRef = useRef(null);
    const { previous_steps, completed } = otherProps;
    const isBlocked = checkBlockedActivity(programTopics, topicId, previous_steps, coachId);
    const isMobileOrTablet = useMediaQuery({ maxWidth: 1193 });
    const itemClasses = {
        isActive: isActive ? 'topics-list__item--active' : '',
        isTopicHeader: isTopicHeader ? 'topics-list__item--topic-header' : '',
        isCompleted: completed ? 'topics-list__item--completed' : '',
        isBlocked: isBlocked ? 'topics-list__item--blocked' : '',
        isDeactivated: isDeactivated ? 'topics-list__item--blocked' : ''
    };

    const setActiveItem = () => {
        if (!isActive) {
            handleSetActiveItem(uniqId);
        }
    };

    useLayoutEffect(() => {
        if (isActive) {
            const paddingOffset = 20;
            const scrollPosition = getOffsetPosition(activityRef.current, paddingOffset);

            scrollToEl(scrollPosition);
        }
    }, [isActive]);


    return (
        <li ref={activityRef}
            className={`topics-list__item ${itemClasses.isActive} ${itemClasses.isTopicHeader} ${itemClasses.isCompleted} ${itemClasses.isBlocked} ${itemClasses.isDeactivated}`}
            onClick={setActiveItem}
        >
            <div className="topics-list__item-description">
                <div className="topics-list__item-icon">
                    {getProgramIcon(type, isBlocked, completed)}
                </div>
                <div className="topics-list__item-content">
                    <span className="topics-list__item-num">
                        {topicNum ? `${topicNum}.` : null}
                        {num ? `${num}. ` : null}
                        {typeName}
                    </span>
                    <h4 className="topics-list__item-title">{name}</h4>
                </div>
                {
                    (isBlocked || isDeactivated) && <LockIcon className="topics-list__lock-icon"
                        color={isActive ? 'white' : '#929292'}
                    />
                }
            </div>
            {
                isMobileOrTablet && isActive && <TopicsContent name={name}
                    type={type}
                    uniqId={uniqId}
                    activityId={id}
                    topicNum={topicNum}
                    num={num}
                    coachId={coachId}
                    topicId={topicId}
                    typeName={typeName}
                    isBlocked={isBlocked}
                    isDeactivated={isDeactivated}
                    {...otherProps}
                />
            }
        </li>
    );
};

export default ProgrammeTopicsListItem;