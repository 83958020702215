import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './ChooseCoachModal.scss';

import image from 'assets/vector/questionnaire/modal_image.svg';
import { getInfoAboutCurrentUser } from 'redux/actions';
import routes from 'constants/routes';


const ChooseCoachModal = ({ closeModal, title, description }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { DASHBOARD } = routes;

    React.useEffect(() => {
        const modal__close = document.querySelector(".modal__close");
        modal__close.style.display = "none";
        dispatch(getInfoAboutCurrentUser());
    }, [])

    return (
        <section className="choose-coach-modal">
            <img className="icon" src={image} alt="" />
            <h1 className="title">{title}</h1>
            <div className="description">{description}</div>
            <button className="proceed__link" onClick={() => {
                history.push(DASHBOARD);
                closeModal();
            }}>Proceed</button>
        </section>
    )
}

export default ChooseCoachModal;