import {put, fork, takeLatest} from "redux-saga/effects";
import {COMPLETE_ACTIVITY} from "redux/constants";
import {completeActivityRequest} from "redux/sagas/completeActivity/handleCompleteActivity";
import {completeActivityError, showNotification} from "redux/actions";
import {generalApiErrorHandler} from "redux/sagas/helpers/generalApiErrorHandler";
import {getNotificationMessage} from "helpers/notifications";

function* watchCompleteActivity() {
    yield takeLatest(COMPLETE_ACTIVITY, handleCompleteActivity);
}

function* handleCompleteActivity(requestData) {
    try {
        yield completeActivityRequest(requestData);

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* completeActivity() {
    yield fork(watchCompleteActivity);
}