import {fork, put, takeLatest, call, select} from "redux-saga/effects";
import axios from "axios";
import {platformSettings} from "redux/constants";
import {getPlatformSettingsSuccess, getPlatformSettingsError} from "redux/actions/platformSettings"
import {GET_PLATFORM_SETTINGS} from "constants/api";
import {getToken} from "redux/sagas/helpers/helpers";

function* watchGetPlatformSettings() {
    yield takeLatest(platformSettings.GET_PLATFORM_SETTINGS, handleGetPlatformSettings);
}
function* handleGetPlatformSettings({payload}) {
    try {
        const token = yield select(getToken);

        const currentThemeData = yield axios({
            method: "get",
            url: GET_PLATFORM_SETTINGS(payload),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then(response => response.data.data)
            .catch(error => console.log(error));

        yield put(getPlatformSettingsSuccess(currentThemeData))

    } catch (e) {
        const errorMessage = e?.response?.message;
        console.log(errorMessage);

        yield put(getPlatformSettingsError(errorMessage));
    }
}

export default function* platformSettingsFunc() {
    yield fork(watchGetPlatformSettings);
}