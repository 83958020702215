import {put, fork, takeLatest, call} from "redux-saga/effects";
import axios from "axios";
import api from 'constants/api';
import {CREATE_COACH_PASSWORD_REQUEST, CREATE_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST} from "redux/constants";
import {
    resetPasswordError,
    resetPasswordSuccess,
    createPasswordError,
    registerForProgramSuccess, showNotification
} from "redux/actions";
import {PARTICIPANT_PROGRAMS} from 'constants/api';
import {saveUserCredentials} from "redux/sagas/helpers/saveUserCredentials";
import ResponseError from "helpers/ResponseError";
import {generalApiErrorHandler} from "redux/sagas/helpers/generalApiErrorHandler";
import {getNotificationMessage} from "helpers/notifications";

const {RESET_PASSWORD, CREATE_PASSWORD, REGISTER_COACH} = api;

function* watchResetPassword() {
    yield takeLatest(RESET_PASSWORD_REQUEST, handleResetPassword);
}

function* watchCreatePassword() {
    yield takeLatest(CREATE_PASSWORD_REQUEST, handleCreatePassword);
}

function* watchCreateCoachPassword() {
    yield takeLatest(CREATE_COACH_PASSWORD_REQUEST, handleCreateCoachPassword);
}

function* handleResetPassword({payload}) {
    try {
        const {data} = yield call(() => axios.post(RESET_PASSWORD, payload)
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            yield put(resetPasswordSuccess());

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            yield put(resetPasswordError(err.getValidationErrorMessages()));
        }

    } catch (error) {
        yield put(resetPasswordError(error.message));
    }
}

function* handleCreatePassword({payload}) {
    try {
        const {data} = yield call(() => axios.post(CREATE_PASSWORD, payload)
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            const responseData = data.data;

            if (responseData.role === 'participant') {
                yield handleCreateParticipantWithProgram(responseData.token)
            }

            yield saveUserCredentials(responseData);

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            yield put(createPasswordError(err.getValidationErrorMessages()));
        }

    } catch (error) {
        yield put(createPasswordError(error.message));
    }
}

function* handleCreateCoachPassword({payload}) {
    try {

        const {data} = yield call(() => axios.post(REGISTER_COACH, payload)
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            const responseData = data.data;

            yield saveUserCredentials(responseData);

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            yield put(createPasswordError(err.getValidationErrorMessages()));
        }

    } catch (error) {
        yield put(createPasswordError(error.message));
    }
}

function* handleCreateParticipantWithProgram(token) {
    try {

        const {data, status} = yield call(() => axios.get(PARTICIPANT_PROGRAMS,
            {'headers': {'Authorization': `Bearer ${token}`}})
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {

            const registerData = data.data[0];

            yield put(registerForProgramSuccess(registerData));
            localStorage.setItem('registered_program_data', JSON.stringify(registerData));

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            console.log(err.getValidationErrorMessages())
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* setPasswordSagas() {
    yield fork(watchResetPassword);
    yield fork(watchCreatePassword);
    yield fork(watchCreateCoachPassword);
}