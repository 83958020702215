import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { customSelectStyles } from "helpers/customSelectStyles";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import { getCoachPrograms } from 'redux/actions';

const typeSelectStyles = Object.assign({}, customSelectStyles);

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>
    );
};

const CustomCoachProgramsSelect = ({ options, programStatus, setProgramStatus }) => {

    const dispatch = useDispatch();
    const userToken = useSelector(state => state.auth.authToken);

    const onChangeSelect = (optionValue) => {
        const params = {}
        if (optionValue.value !== '') {
            params.programStatus = optionValue.value
        }
        dispatch(getCoachPrograms(userToken, params));
        setProgramStatus(optionValue.value)
    };

    return (
        <Select
            getOptionLabel={options => options.label}
            getOptionValue={options => options.value}
            components={{ DropdownIndicator }}
            options={options}
            classNamePrefix={`coach-program-select`}
            styles={typeSelectStyles}
            isSearchable={false}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            defaultValue={options.filter(option => option.value === programStatus)}
        />
    )
}

export default CustomCoachProgramsSelect;
