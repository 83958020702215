import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CalendarFilterData from "./CalendarFilters";
import TitleAccessorFunction from './helpers/TitleAccessorFunction';
import EventPropGetter from "./helpers/EventPropGetter";
import CreateSession from "components/createSession/createSession";
import { CALENDAR_EVENT_MODAL, CREATE_SESSION_MODAL } from "redux/constants/modal";
import { openModal } from "redux/actions/modal";
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./CalendarPage.scss";
import EditSchedule from 'components/editSchedule/editSchedule';
import MonthDateHeader from './MonthDateHeader';
import calendarDayPropGetter from './helpers/calendarDayPropGetter';
import calendarSlotPropGetter from './helpers/calendarSlotPropGetter';
import absenceScheduleTransform from './helpers/AbsenceScheduleTransform';
import separateVisiblePeriodToDays from './helpers/separateVisiblePeriodToDays';

const CalendarPage = ({
    calendarEventsData,
    calendarGoogleData,
    calendarGoogleMembers,
    calendarFilters,
    updateDate,
    onFilterChange,
    onFilterGoogleChange,
    absenceSchedule,
    visibleMonthStart,
    visibleMonthEnd,
    additionalUsers }) => {

    const calendarRef = useRef(null)
    const dispatch = useDispatch();
    const { role, timezone } = useSelector(state => state.currentUserInfo.currentUserInfo) || {};
    const isCoach = role === 'coach';
    const isParticipant = role === 'participant';
    const isSchoolAdmin = role === 'school_admin';

    Calendar.tz = moment.tz.guess();
    const m = (...args) => moment.tz(...args, Calendar.tz)
    m.localeData = moment.localeData
    const localizer = momentLocalizer(m);
    moment.updateLocale('en', { week: { dow: 1 } });

    // const unavailableData = document.querySelectorAll('.unavailableData');

    const momentCurrent = moment();

    // Settings
    let formats = {};
    let msg = { showMore: total => `${total} Other sessions`, "next": "", "previous": '' }
    const [calendarView, setCalendarView] = useState('month');

    const isMobile = useMediaQuery({ query: `(max-width: 920px)` });
    const isMobileFilters = useMediaQuery({ query: `(max-width: 768px)` });

    if (isMobile) {
        msg = { showMore: total => `+${total}`, "next": "", "previous": '' };
        formats = {
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dd', culture),
            eventTimeRangeFormat: (range) => `${moment(range.start).local().format('HH:mm')} - ${moment(range.end).local().format('HH:mm')}`,
            timeGutterFormat: (range) => `${moment(range).local().format('HH:mm')}`
        };
        moment.updateLocale('en', { weekdaysMin: 'S_M_T_W_T_F_S'.split('_') });

    } else {
        formats = {
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'ddd', culture),
            dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd \xa0\xa0 DD', culture),
            eventTimeRangeFormat: (range) => `${moment(range.start).local().format('HH:mm')} - ${moment(range.end).local().format('HH:mm')}`,
            timeGutterFormat: (range) => `${moment(range).local().format('HH:mm')}`
        };
    }

    const onRangeChange = useCallback(
        (e) => {
            let newStartDate, newEndDate;
            if (e.start && e.end) {
                newStartDate = moment(e.start).format('YYYY-MM-DDT00:00:00ZZ');
                newEndDate = moment(e.end).format('YYYY-MM-DDT23:59:59ZZ');
            } else {
                newStartDate = m(e[0]).format('YYYY-MM-DDT00:00:00ZZ');
                newEndDate = m((e[e.length - 1])).format('YYYY-MM-DDT23:59:59ZZ');
            }
            updateDate(newStartDate, newEndDate)
        }, [updateDate]
    );

    const handleEventModal = (event) => {
        dispatch(openModal(CALENDAR_EVENT_MODAL, { event }));
    };

    const handleCreateSession = (day) => {
        const currentDay = moment.tz(timezone);
        const selectedDay = moment(day.start);

        if (isCoach) dispatch(openModal(CREATE_SESSION_MODAL, { date: day.start }));

        if (isParticipant && selectedDay.isSameOrAfter(currentDay.startOf('day'))) {
            dispatch(openModal(CREATE_SESSION_MODAL, { date: day.start }));
        }
    };

    const absencePeriods = useMemo(
        () => absenceScheduleTransform(absenceSchedule, separateVisiblePeriodToDays(visibleMonthStart, visibleMonthEnd)
        ), [absenceSchedule, visibleMonthStart, visibleMonthEnd]);

    const components = useMemo(() => ({
        month: {
            dateHeader: (props) =>
                <MonthDateHeader
                    {...props}
                    absencePeriods={absencePeriods}
                    coachName={calendarGoogleMembers.find(item => item.id === additionalUsers[0])?.full_name}
                />
        }
    }), [absencePeriods, additionalUsers, calendarGoogleMembers]);

    return (
        <section className={`page-calendar ${calendarView}__view`}>
            {isMobileFilters
                ?
                null
                :
                <div className='calendar-event__buttons'>
                    <EditSchedule />
                    <CreateSession className='pc-view' />
                </div>
            }
            <CalendarFilterData
                calendarFilters={calendarFilters}
                onFilterChange={onFilterChange}
                onFilterGoogleChange={onFilterGoogleChange}
                calendarGoogleMembers={calendarGoogleMembers}
            />
            <Calendar
                ref={calendarRef}
                selectable
                onSelectSlot={handleCreateSession}
                events={[...calendarEventsData, ...calendarGoogleData]}
                startAccessor="start"
                endAccessor='end'
                defaultDate={new Date()}
                onRangeChange={onRangeChange}
                onSelectEvent={(event) => handleEventModal(event)}
                onView={view => setCalendarView(view)}
                localizer={localizer}
                titleAccessor={calendarEventsData ? TitleAccessorFunction : ''}
                views={['month', 'week', 'day']}
                messages={msg}
                formats={formats}
                dayLayoutAlgorithm={isSchoolAdmin ? 'overlap' : 'no-overlap'}
                dayPropGetter={(date) => calendarDayPropGetter(date, absencePeriods, role)}
                slotPropGetter={(date) => calendarSlotPropGetter(date, absencePeriods)}
                eventPropGetter={(e) => EventPropGetter(e)}
                min={moment('7:00', 'hh:mm').toDate()}
                max={moment('23:00', 'hh:mm').toDate()}
                // scrollToTime={momentCurrent.hours()}
                step={5}
                timeslots={12}
                components={components}
                onResize={e => console.log(e)}
            />
        </section>
    );
};

export default React.memo(CalendarPage);
