import React from "react";
import ModuleIcon from "components/icons/programIcons/ModuleIcon";
import GoalsIcon from "components/icons/programIcons/GoalsIcon";
import SessionIcon from "components/icons/programIcons/SessionIcon";
import TaskIcon from "components/icons/programIcons/TaskIcon";
import FileIcon from "components/icons/programIcons/FileIcon";
import FormIcon from "components/icons/programIcons/FormIcon";


export const getProgramIcon = (programType, isBlocked, isCompleted) => {

    const defaultColor = `#486EA0`;
    const completedColor = `#35A684`;
    const blockedColor = `#929292`;

    let iconColor = defaultColor;

    if (isBlocked) {
        iconColor = blockedColor;
    } else if (isCompleted) {
        iconColor = completedColor;
    }

    switch (programType) {
        case 'module':
            return <ModuleIcon color={iconColor}/>;
        case 'goals':
            return <GoalsIcon color={iconColor}/>;
        case 'session':
        case 'session_and_evaluation':
            return <SessionIcon color={iconColor}/>;
        case 'task':
            return <TaskIcon color={iconColor}/>;
        case 'file':
            return <FileIcon color={iconColor}/>;
        case 'form':
            return <FormIcon color={iconColor}/>;
        default:
            return <ModuleIcon color={iconColor}/>;
    }
};