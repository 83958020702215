import { fork, put, takeLatest, throttle, select, delay } from "redux-saga/effects";
import axios from "axios";
import { personalInfo } from "redux/constants";
import {
    getInfoAboutCurrentUser,
    getInfoAboutCurrentUserError,
    getInfoAboutCurrentUserSuccess
} from "redux/actions/personalInfo"
import { GET_DATA_CURRENT_USER } from "constants/api";
import { generalApiErrorHandler } from "redux/sagas/helpers/generalApiErrorHandler";
import { showNotification } from "redux/actions";
import { getNotificationMessage } from "helpers/notifications";
import { getToken } from "redux/sagas/helpers/helpers";

function* watchGetCurrentUser() {
    yield takeLatest(personalInfo.GET_INFO_ABOUT_CURRENT_USER, handleGetCurrentUser);
}

function* handleGetCurrentUser({ payload: requestId, callback }) {

    try {
        const token = yield select(getToken);

        const currentUserData = yield axios({
            method: "get",
            url: GET_DATA_CURRENT_USER(),
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then(response => response.data.data)
            .catch(error => console.log(error));

        if (requestId) {
            yield put(getInfoAboutCurrentUserSuccess({ ...currentUserData, requestId }));
        } else {
            yield put(getInfoAboutCurrentUserSuccess(currentUserData));
        }

        if (callback) {
            yield callback();
        }

    } catch (error) {
        yield put(getInfoAboutCurrentUserError());

        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* watchThrottledGetCurrentUser() {
    yield throttle(30000, personalInfo.GET_THROTTLED_INFO_ABOUT_CURRENT_USER, function* () {
        yield put(getInfoAboutCurrentUser());
    });
}

export default function* personalInfoSaga() {
    yield fork(watchGetCurrentUser);
    yield fork(watchThrottledGetCurrentUser);
}