import {calendarGoogleList} from "redux/constants";

export function getCalendarGoogleList(calendarGoogleDefaultData) {
    return {
        type: calendarGoogleList.GET_GOOGLE_LIST_CALENDAR,
        payload: calendarGoogleDefaultData,
    };
}

export function getCalendarGoogleListSuccess(calendarGoogleSuccessList) {
    return {
        type: calendarGoogleList.GET_GOOGLE_LIST_CALENDAR_SUCCESS,
        payload: calendarGoogleSuccessList,

    };
}

export function getCalendarGoogleListEmpty(emptylist) {
    return {
        type: calendarGoogleList.GET_GOOGLE_LIST_CALENDAR_NULL,
        payload: emptylist,
    };
}

export function getCalendarGoogleListError(error) {
    return {
        type: calendarGoogleList.GET_GOOGLE_LIST_CALENDAR_ERROR,
        payload: error,
    };
}
