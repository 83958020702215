import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import "./ActionList.scss";
import Button from "components/button/Button";
import ActionListItem from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/ActionListItem/ActionListItem";
import AddActionForm from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/AddActionForm/AddActionForm";
import {updateActivityActionItem, deleteActivityActionItem, addNewActivityActionItem} from "redux/actions";
import uniqid from "uniqid";
import CategorizedActionItems
    from "components/pages/Programmes/SingleProgramme/CategorizedActionItems/CategorizedActionItems";
import {transformCategorizedTodoTasks} from "redux/sagas/helpers/userPrograms";
import {transformDatePickerDate} from "helpers/userPrograms";

const ActonList = ({todo_tasks = [], activityId}) => {
    const dispatch = useDispatch();
    const [isAddActionShow, setAddActionShow] = useState(false);
    const [actionList, setActionList] = useState(todo_tasks);
    const {staticData} = useSelector(state => state.auth);

    const showAddActionForm = () => setAddActionShow(true);
    const hideAddActionForm = () => setAddActionShow(false); 

    const toggleCompleted = (id, due_date, description, is_done) => {
        const changedItemIndex = actionList.findIndex(item => item.id === id);

        if (changedItemIndex !== -1) {
            const updatedActionList = actionList.map((item, index) => {
                if (index === changedItemIndex) {
                    item.is_done = !item.is_done;
                }

                return item;
            });

            setActionList(updatedActionList);

            dispatch(updateActivityActionItem({
                id: id,
                due_date: due_date,
                description: description,
                is_done: is_done
            }));

        }
    }

    const removeActionItem = (id, activityId) => {
        const updatedActionList = actionList.filter(item => item.id !== id);

        setActionList(updatedActionList);
        dispatch(deleteActivityActionItem(activityId, id))
    };

    const addAction = (categoryId, field = {}) => {
        const dueDate = field.dueDate ? {due_date: transformDatePickerDate(field.dueDate)} : {};

        dispatch(addNewActivityActionItem({
            activityId: activityId,
            description: field.text,
            category_id: categoryId,
            ...dueDate
        }));
    };

    useEffect(() => {
        setActionList(todo_tasks);
    }, [todo_tasks]);

    const categorizedTodoTasks = transformCategorizedTodoTasks(actionList);

    const actionItems = actionList.filter(item => !item.category_id)
        .map(item => <ActionListItem
                key={uniqid()}
                toggleCompleted={toggleCompleted}
                removeActionItem={removeActionItem}
                activityId={activityId}
                {...item}
            />
        );

    return (
        <>
            {
                categorizedTodoTasks?.length > 0 && (
                    <CategorizedActionItems categories={staticData.activityCategories}
                                            activityId={activityId}
                                            toggleCompleted={toggleCompleted}
                                            removeActionItem={removeActionItem}
                                            categorized_todo_tasks={categorizedTodoTasks}
                    />
                )
            }
            <ul className="action-list__list">
                {actionItems}
            </ul>
            {
                isAddActionShow ?
                    <AddActionForm activityId={activityId}
                                   addAction={addAction}
                                   hideAddActionForm={hideAddActionForm}/>
                    :
                    <>

                        <Button className="button button--orange-invert action-list__add-btn"
                                onClick={showAddActionForm}>
                            Add action item
                        </Button>
                    </>
            }
        </>
    );
};

export default ActonList;