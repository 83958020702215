import React, { useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import './SingleProgramme.scss';
import { getUserSingleProgram, setActiveTopic } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ProgramContent from "components/pages/Programmes/SingleProgramme/ProgramContent/ProgramContent";
import { getActivityWithSession, getActivityId } from "components/pages/Programmes/helpers/helpers";
import Spinner from 'components/Spinner/Spinner';

const SingleProgramme = () => {
    const { programID } = useParams();
    const dispatch = useDispatch();
    const urlSearchParams = new URLSearchParams(useLocation().search);
    const { topics, name: programName, activeTopic, coach_id, id } = useSelector(state => state.userPrograms.singleProgram);
    let programmeTopicsList = topics || [];
    const searchSession = urlSearchParams.get('session');
    const searchActivityId = urlSearchParams.get('activityId');
    const searchActivityIdFromDashboard = urlSearchParams.get('_activityId');

    useEffect(() => {
        if ((programID && !id) || (+programID !== id)) {
            dispatch(getUserSingleProgram(programID));
        }
    }, [id]);

    useEffect(() => {
        if (topics && !activeTopic) {
            let defaultActiveItem = programmeTopicsList.length ? programmeTopicsList[0][0].uniqId : null;

            if (searchSession) {
                defaultActiveItem = getActivityWithSession(searchSession, programmeTopicsList);
            }

            if (searchActivityId) {
                defaultActiveItem = getActivityId(searchActivityId, programmeTopicsList);
            }

            if (searchActivityIdFromDashboard) {
                defaultActiveItem = getActivityId(searchActivityIdFromDashboard, programmeTopicsList);
            }

            dispatch(setActiveTopic(defaultActiveItem));
        }
    }, [topics, activeTopic, searchActivityId, searchActivityIdFromDashboard]);

    const handleSetActiveItem = uniqId => dispatch(setActiveTopic(uniqId));

    if (!id || (+programID !== id)) {
        return (
            <div className="single-programme">
                <Spinner />
            </div>
        )
    }

    return (
        <section className="single-programme">
            <ProgramContent
                topicsList={programmeTopicsList}
                programName={programName}
                coachId={coach_id}
                handleSetActiveItem={handleSetActiveItem}
                activeListItem={activeTopic}
            />
        </section>
    );
};

export default SingleProgramme;