import {postData} from "redux/sagas/helpers/requestHandlers";
import {EDIT_ACTIVITY_DESCRIPTION_ROUTE} from "constants/api";
import {put, call, select} from "redux-saga/effects";
import {editActivityDescriptionSuccess, showNotification} from "redux/actions";
import ResponseError from "helpers/ResponseError";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

export function* editActivityRequest({payload: {id, activity_id, details}}) {
    const apiRoute = EDIT_ACTIVITY_DESCRIPTION_ROUTE(id);
    const token = yield select(getToken);
    const {data, status} = yield call(postData(apiRoute, {activity_id, details}, token));

    if (data?.status === 'Success') {
        const topics = data?.data?.topics;

        yield put(editActivityDescriptionSuccess({topics, activity_id}));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        put(showNotification({
            title: 'Error',
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));

        console.log(err.getValidationErrorMessages());
    }
}