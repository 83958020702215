import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import "./Coaches.scss";
import pageTitles from "constants/pageTitles";
import { updatePageTitle, getCoachesList } from "redux/actions";
import EmptyPage from "../EmptyPage/EmptyPage"

import CoachesPage from "./CoachesPage"

import { coachesPage } from "constants/emptyPages";
import Spinner from "components/Spinner/Spinner";

export default function Coaches() {

    const {
        COACHES_TITLE,
    } = pageTitles;

    const dispatch = useDispatch();

    const coachesData = useSelector(state => state.coachesList.coachesList);
    const coachesExist = useSelector(state => state.coachesList.coachesExist);
    const coachesListLoading = useSelector(state => state.coachesList.coachesListLoading);

    const defaultShowItems = 10;

    useEffect(() => {
        dispatch(updatePageTitle(COACHES_TITLE));

        dispatch(getCoachesList({
            offset: 0,
            take: defaultShowItems,
            search: '',
            functional_areas: [],
            languages: [],
            industry_background: [],
            geography: [],
            programs: [],
            seniority_level: null,
            experience_years: null,
        }));
    }, []);

    return (
        <section className={`section-wrap ${coachesExist ? '' : 'fullscreen'}`}>

            {!coachesExist && coachesListLoading && <Spinner />}

            {
                coachesData &&
                <>
                    {
                        coachesExist ?

                            <CoachesPage coachesData={coachesData} defaultShowItems={defaultShowItems} />
                            :
                            <EmptyPage emptyPageValue={coachesPage} />
                    }
                </>
            }


        </section>
    );
};