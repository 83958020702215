import { put, fork, takeLatest, select } from "redux-saga/effects";
import {
    COMPLETE_SESSION,
    CREATE_INDIVIDUAL_SESSION,
    EDIT_INDIVIDUAL_SESSION,
    GET_COACH_ABSENCE_SCHEDULE,
    GET_SESSION_FILTER,
    GET_SESSION_TIME
} from "redux/constants";

import {
    completeSessionRequest,
    createSessionRequest,
    editSessionRequest, getCoachAbsenceScheduleRequest, getSessionFilterRequest,
    getSessionsTimeRequest
} from "redux/sagas/createSession/handleIndividualSession";
import { showNotification } from "redux/actions";
import { generalApiErrorHandler } from "redux/sagas/helpers/generalApiErrorHandler";
import { getNotificationMessage } from "helpers/notifications";
import { getToken } from "redux/sagas/helpers/helpers";

function* watchCreateIndividualSession() {
    yield takeLatest(CREATE_INDIVIDUAL_SESSION, handleCreateIndividualSession);
}

function* watchEditIndividualSession() {
    yield takeLatest(EDIT_INDIVIDUAL_SESSION, handleEditIndividualSession);
}

function* watchCompleteIndividualSession() {
    yield takeLatest(COMPLETE_SESSION, handleCompleteIndividualSession);
}

function* watchGetSessionTime() {
    yield takeLatest(GET_SESSION_TIME, handleGetSessionsTime);
}

function* watchGetCoachAbsenceSchedule() {
    yield takeLatest(GET_COACH_ABSENCE_SCHEDULE, handleGetCoachAbsenceSchedule);
}

function* watchGetSessionFilter() {
    yield takeLatest(GET_SESSION_FILTER, handleGetSessionFilter);
}

function* handleGetSessionsTime({ payload: requestData }) {
    try {
        const token = yield select(getToken);

        yield getSessionsTimeRequest(requestData, token);

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* handleGetCoachAbsenceSchedule(requestData) {
    try {

        yield getCoachAbsenceScheduleRequest(requestData);

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* handleCreateIndividualSession({ payload: additionalData }) {
    try {
        yield createSessionRequest(additionalData);

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* handleEditIndividualSession({ payload: additionalData }) {
    try {
        yield editSessionRequest(additionalData);

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* handleCompleteIndividualSession(sessionId) {
    try {
        yield completeSessionRequest(sessionId);

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* handleGetSessionFilter(requestData) {
    try {
        yield getSessionFilterRequest(requestData);

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* createSession() {
    yield fork(watchCreateIndividualSession);
    yield fork(watchEditIndividualSession);
    yield fork(watchCompleteIndividualSession);
    yield fork(watchGetSessionTime);
    yield fork(watchGetSessionFilter);
    yield fork(watchGetCoachAbsenceSchedule);
}