import uniqid from "uniqid";
import { areIncompleteActivities } from "helpers/userPrograms";

export const transformTopicsList = (topics = []) => {
    let topicNum = 1;

    return topics.reduce((topicsList, topic, index) => {

        topicsList.push([
            {
                id: topic.id,
                uniqId: 'module' + topic.id,
                num: topicNum,
                name: topic.name,
                type: 'module',
                typeName: 'Module',
                description: topic.description,
                files: topic.files
            }
        ]);

        if (topic.activities) {

            const transformedActivity = topic.activities.map((activity, index) => {

                if (activity.categorized_descriptions) {
                    activity.categorized_descriptions = transformCategorizedDescriptions(activity.categorized_descriptions);
                }

                return {
                    uniqId: activity.type + activity.id,
                    topicNum: topicNum,
                    topicId: topic.id,
                    num: index + 1,
                    typeName: transformActivityTypeName(activity.type),
                    ...activity
                }
            });

            const hasIncompleteActivities = areIncompleteActivities(transformedActivity);

            if (!hasIncompleteActivities) {
                topicsList[index][0]['completed'] = true;
            }

            topicsList[index].push(...transformedActivity);
        }

        topicNum++;

        return topicsList;

    }, []);
};

const transformActivityTypeName = (type) => {
    if (typeof type === 'string') {
        const typeNameWithSpaces = type.replace(/_/g, ' ');

        return typeNameWithSpaces.charAt(0).toUpperCase() + typeNameWithSpaces.slice(1);
    }
};

export const transformCategorizedDescriptions = (descriptions) => {
    return descriptions.map(description => {
        return {
            ...description,
            itemId: uniqid()
        }
    })
};

export const transformCategorizedTodoTasks = (todoTasks) => {
    if (todoTasks) {
        const uniqCategoryRelatedIndex = {};
        let uniqueCount = 0;

        return todoTasks.filter(task => task.category_id === 0 || task.category_id)
            .reduce((list, task) => {
                const relatedIndex = uniqCategoryRelatedIndex[task.category_id];

                if (relatedIndex === 0 || relatedIndex) {

                    list[relatedIndex]?.push(task);

                } else {

                    list.push([task]);
                    uniqCategoryRelatedIndex[task.category_id] = uniqueCount++;
                }

                return list;

            }, []);
    }
};


