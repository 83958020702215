import React, { useEffect, useState } from 'react';
// for ILTB
import { ReactComponent as SessionIcon } from "assets/vector/programmes/create-session-icon.svg";
// import {ReactComponent as SessionIcon} from "assets/vector/programmes/create-session-icon-switch.svg";
import '../EventSessionModal/EventSessionModal.scss';
import Participants from "components/modal/EventSessionModal/Participants/Participants";
import DayPicker from "react-day-picker";
import 'react-day-picker/lib/style.css';
import Button from "components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { clearSessionInfo, getSessionFilter, getSessionTime } from "redux/actions/createSession";
import CreateSessionForm from "components/forms/CreateSession/CreateSessionForm";

const CreateSessionModal = ({ closeModal, modalData }) => {
    const dispatch = useDispatch();
    const { sessionFilter: { updatedData } } = useSelector(state => state.createSession);
    const { timezone, id: userId, role: userRole } = useSelector(state => state.currentUserInfo.currentUserInfo);
    const isParticipant = userRole === 'participant';
    const setUpPredefinedDate = (date) => moment(date).toString();
    const defaultSessionDetails = {
        school_program: '',
        topic: '',
        members: []
    };
    const [currentSessionDetails, setCurrentSessionDetails] = useState(defaultSessionDetails);
    const [time, setTime] = useState(null);
    const [selectedDate, setSelectedDate] = useState(setUpPredefinedDate(modalData?.date));
    const [sessionLink, setSessionLink] = useState('');
    const [sessionDetails, setSessionDetails] = useState('');
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);
    const [isInvitingParticipant, setIsInvitingParticipant] = useState(false);
    const todayWithTimeZone = new Date(moment.tz(timezone).format('YYYY/MM/DD HH:mm:ss'));
    // YYYY/MM/DD format used to display new Date correctly in Safari
    const [currentActivity, setCurrentActivity] = useState(null);
    const modifiers = { highlighted: todayWithTimeZone };

    if (isParticipant) modifiers['disabled'] = { before: todayWithTimeZone };

    const toggleInviteUser = (invitedUsers) => {
        if (invitedUsers.length) {
            setCurrentSessionDetails({
                ...currentSessionDetails,
                members: invitedUsers
            });
        } else {
            setCurrentSessionDetails({
                ...currentSessionDetails,
                members: invitedUsers,
                topic: '',
            });
        }
    };

    const toggleInvitationList = () => {
        setIsInvitingParticipant(!isInvitingParticipant);
    };

    const showDatePicker = () => setDatePickerVisible(true);
    const saveDate = () => setDatePickerVisible(false);
    const closeDatePicker = () => {
        setSelectedDate(null);
        setDatePickerVisible(false);
    };

    const selectDate = (day, modifiers = {}) => {
        if (modifiers.disabled) {
            return;
        }

        setSelectedDate(modifiers.selected ? undefined : day);
    };

    const getCurrentMembersId = (members = []) => {
        return members.length ? [...members.map(member => member.id), userId] : [];
    };

    const transformRequestParams = (values) => {
        const { programChanged, ...params } = values;

        for (let value in params) {
            if (typeof params[value] === 'object' && params[value] !== null) {
                params[value] = params[value]?.id
            }
        }

        return params;
    };

    const getRequestMembers = () => {
        if (userRole === 'coach' && currentSessionDetails.members.length) {
            return currentSessionDetails.members;

        } else if (userRole === 'coach') {
            return [];
        }

        if (currentSessionDetails.programChanged) {

            return [];
        }

        return updatedData.members || [];
    };

    const getInvitedMembers = () => userRole === 'coach' ? currentSessionDetails.members : (updatedData.members || []);

    useEffect(() => {
        if (selectedDate && userRole === 'coach' && currentSessionDetails.members.length) {
            const chosenDate = moment(selectedDate).format('YYYY-MM-DD');

            dispatch(getSessionTime(chosenDate, getCurrentMembersId(getRequestMembers())));
        }
    }, [selectedDate, currentSessionDetails.members]);

    useEffect(() => {
        if (selectedDate && userRole === 'participant' && updatedData.members?.length) {
            const chosenDate = moment(selectedDate).format('YYYY-MM-DD');

            dispatch(getSessionTime(chosenDate, getCurrentMembersId(getInvitedMembers())));
        }
    }, [selectedDate, updatedData.members]);

    useEffect(() => {
        let requestParams = {};

        if (currentSessionDetails.school_program) {
            requestParams = {
                ...transformRequestParams(currentSessionDetails),
                members: getCurrentMembersId(getRequestMembers())
            };

            dispatch(getSessionFilter(requestParams));
        }

    }, [currentSessionDetails]);

    useEffect(() => {
        dispatch(getSessionFilter());

        return () => {
            dispatch(clearSessionInfo());
        }
    }, []);

    return (
        isInvitingParticipant && updatedData?.members
            ?
            <Participants participants={updatedData?.members}
                toggleInvitationList={toggleInvitationList}
                toggleInviteUser={toggleInviteUser}
            />
            :
            <div className="create-event">
                {
                    isDatePickerVisible ?
                        <>
                            <div className="create-event__head">
                                <SessionIcon className="create-event__icon" />
                                <h3 className="create-event__title">Choose date</h3>
                                <Button className='button participants__close-btn'
                                    onClick={closeDatePicker}
                                    style={{ margin: '0 30px 0 auto' }}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <DayPicker className="create-event__date-picker"
                                numberOfMonths={2}
                                firstDayOfWeek={1}
                                modifiers={modifiers}
                                selectedDays={selectedDate}
                                onDayClick={selectDate}
                            />
                            <div className="create-event__actions">
                                {/* for ILTB */}
                                {/* <Button type="button"
                                        onClick={saveDate}
                                        className="button button--orange create-event__save">
                                    Save
                                </Button> */}
                                <Button type="button"
                                    onClick={saveDate}
                                    className="button button--blue create-event__save">
                                    Save
                                </Button>
                                <Button type="button" className="button button--orange-invert create-event__cancel"
                                    onClick={closeDatePicker}>
                                    Cancel
                                </Button>
                            </div>
                        </>
                        :
                        <>
                            <div className="create-event__head">
                                <SessionIcon className="create-event__icon" />
                                <h3 className="create-event__title">Create Session or Event</h3>
                            </div>
                            <CreateSessionForm
                                isInvitingParticipant={isInvitingParticipant}
                                toggleInvitationList={toggleInvitationList}
                                defaultSessionDetails={defaultSessionDetails}
                                selectedParticipants={getInvitedMembers()}
                                removeFromSelectedParticipants={toggleInviteUser}
                                closeModal={closeModal}
                                showDatePicker={showDatePicker}
                                selectedDate={selectedDate}
                                time={time}
                                setTime={setTime}
                                sessionLink={sessionLink}
                                setSessionLink={setSessionLink}
                                sessionDetails={sessionDetails}
                                setSessionDetails={setSessionDetails}
                                currentSessionDetails={currentSessionDetails}
                                setCurrentSessionDetails={setCurrentSessionDetails}
                                currentActivity={currentActivity}
                                setCurrentActivity={setCurrentActivity}
                                coachId={getInvitedMembers()?.[0]?.id}
                            />
                        </>
                }
            </div>
    );
}

export default CreateSessionModal;