import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './timezoneNotification.scss';

import icon from 'assets/vector/timezone-notification-icon.svg';
import closeIcon from 'assets/vector/ion_close.svg';
import { hideTimezoneNotification } from 'redux/actions';
import routes from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { transformedTimezoneForMoment } from 'helpers/transformedTimezoneForMoment';

const TimezoneNotification = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const {
        MY_SETTINGS,
        CAREER_QUESTIONNAIRE,
        QUESTIONNAIRE,
        QUESTIONNAIRE_FORM,
        QUESTIONNAIRE_COACH_MATCHING,
        MATCHED_COACH_PROFILE,
        PROGRAMME_STARTING
    } = routes;

    // no need to display the notification on pages related to the questionnaire
    const questionnaireRelatedPages = (
        location.pathname.includes(CAREER_QUESTIONNAIRE) ||
        location.pathname.includes(QUESTIONNAIRE) ||
        location.pathname.includes(QUESTIONNAIRE_FORM) ||
        location.pathname.includes(QUESTIONNAIRE_COACH_MATCHING) ||
        location.pathname.includes(MATCHED_COACH_PROFILE) ||
        location.pathname.includes(PROGRAMME_STARTING)
    )

    const { show } = useSelector(state => state.timezoneNotification);
    const userProfileTimezone = useSelector(state => state.currentUserInfo.currentUserInfo?.timezone);
    const isUseLocalTimeZone = useSelector(state => state.currentUserInfo.currentUserInfo?.use_local_tz);
    const currentUserTimezone = moment.tz.guess(true);
    const isTimezonesEqual = (userProfileTimezone === currentUserTimezone);

    // isKeepTimezone is used to check if the user decided to keep the same timezone, if true - we shouldn't display this notification
    const isKeepTimezone = localStorage.getItem('keep_timezone');

    const timezoneTime = moment.tz(transformedTimezoneForMoment(userProfileTimezone)).format('HH:mm');

    const closeNotification = () => {
        dispatch(hideTimezoneNotification());
        localStorage.setItem('time_zone_notification', 'false');
    }

    const editTimezone = () => {
        history.push(MY_SETTINGS);
        closeNotification();
    }

    const keepTimezone = () => {
        localStorage.setItem('keep_timezone', 'true')
        closeNotification();
    }

    return (
        <>
            {
                userProfileTimezone &&
                <div className={`timezone-notification__wrapper ${(show === 'true' && !isTimezonesEqual && !isKeepTimezone && !questionnaireRelatedPages && !isUseLocalTimeZone) ? 'visible' : ''}`}>
                    <div className="notification__image">
                        <img src={icon} alt="" />
                    </div>
                    <div className="notification__content">
                        <div className="notification__title">Is it your timezone (UTC {moment.tz(userProfileTimezone).format('Z')})  {timezoneTime}?</div>
                        <div className="notification__description">Please review your time zone settings to make sure they are correct. It will help you not to miss any event or important notification. </div>
                        <div className="notification__buttons">
                            <div className="edit__btn btn" onClick={() => editTimezone()}>Edit timezone</div>
                            <div className="keep__btn btn" onClick={() => keepTimezone()}>Keep timezone</div>
                        </div>
                    </div>
                    <div className="notification__close" onClick={() => closeNotification()}>
                        <img src={closeIcon} alt="" />
                    </div>
                </div>
            }
        </>

    )
}

export default TimezoneNotification;