import {
    GET_MATCHED_COACHES,
    GET_MATCHED_COACHES_SUCCESS,
    GET_MATCHED_COACHES_ERROR,
    GET_CAREER_MATCHED_COACHES
} from "redux/constants/matchedCoaches";

const initialState = {
    getMatchedCoachesProgress: false,
    matchedCoaches: [],
    suggestedCoaches: [],
    manualSuggestedCoaches: [],
    matchedCoachesError: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_MATCHED_COACHES:
            return {
                ...state,
                ...initialState,
                getMatchedCoachesProgress: true,
            };
        case GET_CAREER_MATCHED_COACHES:
            return {
                ...state,
                ...initialState,
                getMatchedCoachesProgress: true,
            };
        case GET_MATCHED_COACHES_SUCCESS:
            return {
                ...state,
                getMatchedCoachesProgress: false,
                matchedCoaches: payload?.matched_coaches ? payload.matched_coaches : [],
                suggestedCoaches: payload?.suggested_coaches ? payload.suggested_coaches : [],
                manualSuggestedCoaches: payload?.manual_suggested_coaches ? payload.manual_suggested_coaches : []
            };
        case GET_MATCHED_COACHES_ERROR:
            return {
                ...state,
                ...initialState,
                matchedCoachesError: payload
            };
        default:
            return state;
    }
}