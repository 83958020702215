import {HIDE_NOTIFICATION, SHOW_NOTIFICATION} from "redux/constants/notification";
import {dashboardNotification} from "redux/constants/notification";

export function showNotification(content, closeTimer) {
    return {
        type: SHOW_NOTIFICATION,
        payload: {content, closeTimer}
    }
}

export function hideNotification() {
    return {
        type: HIDE_NOTIFICATION,
    }
}

export function getUserNotifications() {
    return {
        type: dashboardNotification.GET_NOTIFICATIONS,
    }
}

export function getUserNotificationsOnScroll(scrollValues) {
    return {
        type: dashboardNotification.GET_NOTIFICATIONS_ON_SCROLL,
        payload: scrollValues
    }
}

export function getUserNotificationsSuccess(notifications) {
    return {
        type: dashboardNotification.GET_NOTIFICATIONS_SUCCESS,
        payload: notifications
    }
}

export function getUserNotificationsError(error) {
    return {
        type: dashboardNotification.GET_NOTIFICATIONS_ERROR,
        payload: error
    }
}

export function getUserNotificationsEmpty(status) {
    return {
        type: dashboardNotification.GET_NOTIFICATIONS_EMPTY,
        payload: status
    }
}

export function removeNotification(removeItem) {
    return {
        type: dashboardNotification.REMOVE_ITEM_NOTIFICATION,
        payload: removeItem
    }
}

export function claerAllNotifications() {
    return {
        type: dashboardNotification.CLEAR_ALL_NOTIFICATION,
    }
}

export function getUnreadNotifications() {
    return {
        type: dashboardNotification.GET_NOTIFICATIONS_UNREAD_COUNT,
    }
}

export function setUnreadNotificationsCount(count) {
    return {
        type: dashboardNotification.SET_NOTIFICATIONS_COUNT,
        payload: count
    }
}

export function loadNotifications(load) {
    return {
        type: dashboardNotification.LOAD_NOTIFICATIONS,
        payload: load
    }
}

export function setTotalNotifications(total) {
    return {
        type: dashboardNotification.SET_TOTAL_NOTIFICATIONS,
        payload: total
    }
}

export function clearOnLogoutAllDataNotifications() {
    return {
        type: dashboardNotification.CLEAR_ON_LOGOUT_ALL_DATA_NOTIFICATIONS,
    }
}

export function sendReadNotifications() {
    return {
        type: dashboardNotification.SEND_READ_NOTIFICATIONS,
    }
}

export function sendAcceptNotification(sessionID) {
    return {
        type: dashboardNotification.SEND_ACCEPT_NOTIFICATIONS,
        payload: sessionID
    }
}

export function sendDeclineNotification(sessionID) {
    return {
        type: dashboardNotification.SEND_DECLINE_NOTIFICATIONS,
        payload: sessionID
    }
}