import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePageTitle, getFiltersOfCalendarList, getCalendarList, getCalendarGoogleList, getCalendarGoogleMembers, showNotification } from "redux/actions";
import pageTitles from "constants/pageTitles";
import CalendarPage from "./CalendarPage"
import Spinner from "components/Spinner/Spinner";
import moment from 'moment-timezone';
import "./CalendarPage.scss";
import 'variables.scss';
import ResponseError from 'helpers/ResponseError';
import axios from 'axios';
import { COACH_ABSENCE_SCHEDULE_ROUTE } from 'constants/api';

export default function Calendar() {

    const {
        CALENDAR_TITLE,
    } = pageTitles;

    const dispatch = useDispatch();

    const calendarEventsData = useSelector(state => state.calendarList.calendarList);
    const calendarGoogleEventsData = useSelector(state => state.calendarGoogleEvents.calendarGoogleEvents);
    const calendarFilters = useSelector(state => state.calendarFilters.calendarFilters);
    const calendarGoogleMembers = useSelector(state => state.calendarGoogleMembers.calendarGoogleMembers);
    const { absence_schedule, role } = useSelector(state => state.currentUserInfo.currentUserInfo);
    const { authToken } = useSelector(state => state.auth);

    // createSessionSuccess is used to update the calendar view after creating a new session
    const { createSessionSuccess } = useSelector(state => state.createSession);

    const [visibleMonthStart, setVisibleMonthStart] = useState(moment().startOf('month').startOf("week").add(1, 'day').format('YYYY-MM-DDT00:00:00ZZ'));
    const [visibleMonthEnd, setVisibleMonthEnd] = useState(moment().endOf('month').endOf("week").add(1, 'day').format('YYYY-MM-DDT23:59:59ZZ'));
    const [additionalUsers, setAdditionalUsers] = useState([]);
    const [filterProgram, setFilterProgram] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [filterMember, setFilterMember] = useState([]);

    // used to display coach's unavailable schedule for participants
    const [coachUnavailableSchedule, setCoachUnavailableSchedule] = useState(null);

    const getCoachUnavailableSchedule = (params) => {
        axios({
            method: 'get',
            url: `${COACH_ABSENCE_SCHEDULE_ROUTE}`,
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
            accept: 'application/json',
            params: { ...params }
        })
            .then(response => {
                const formData = response.data.data[0];

                setCoachUnavailableSchedule(formData)


            })
            .catch(error => {
                const responseError = new ResponseError(error.response.data);

                dispatch(showNotification({
                    title: responseError.getStatus(),
                    text: responseError.getValidationErrorMessages()
                }))
            });
    }

    const updateDate = (newStartDate, newEndDate) => {

        setVisibleMonthStart(newStartDate)
        setVisibleMonthEnd(newEndDate)

        let updatedVisibleMonth = {
            start: newStartDate,
            end: newEndDate,
            additional_users: additionalUsers
        }

        dispatch(getCalendarGoogleList(updatedVisibleMonth))
        dispatch(getCalendarList({
            ...updatedVisibleMonth,
            programs: filterProgram,
            type: filterType,
            members: filterMember,
            additional_users: additionalUsers
        }));
    }

    const onFilterChange = (initProgram, initType, initMember) => {

        setFilterProgram(initProgram != undefined ? initProgram : []);
        setFilterType(initType != undefined ? initType : '');
        setFilterMember(initMember != undefined ? initMember : [])

        let getFormValue = {
            start: visibleMonthStart,
            end: visibleMonthEnd,
            programs: initProgram != undefined ? initProgram : [],
            type: initType != undefined ? initType : '',
            members: initMember != undefined ? initMember : [],
            additional_users: additionalUsers != undefined ? additionalUsers : []
        };

        dispatch(getCalendarList(getFormValue));
    };

    const onFilterGoogleChange = (value) => {
        setAdditionalUsers(value)
        dispatch(getCalendarGoogleList({
            start: visibleMonthStart,
            end: visibleMonthEnd,
            additional_users: value
        }))
        dispatch(getCalendarList({
            start: visibleMonthStart,
            end: visibleMonthEnd,
            programs: filterProgram,
            type: filterType,
            members: filterMember,
            additional_users: value
        }));

        if (role === 'participant') {
            if (value?.length > 0) {
                const params = {
                    list_id: value
                }
                getCoachUnavailableSchedule(params)
            } else {
                setCoachUnavailableSchedule(null)
            }
        }
    }

    const mapper = sessionsData => {
        return sessionsData.map(({ name: title, date_start: start, duration, ...props }) => ({
            title,
            duration,
            start: new Date(moment(start)),
            end: moment(start).add(duration, 'minutes').toDate(),
            ...props
        }));
    };

    useEffect(() => {
        dispatch(updatePageTitle(CALENDAR_TITLE));
        dispatch(getFiltersOfCalendarList());
        dispatch(getCalendarGoogleMembers())
        dispatch(getCalendarList({
            start: visibleMonthStart,
            end: visibleMonthEnd,
            programs: filterProgram,
            type: filterType,
            members: filterMember,
            additional_users: additionalUsers
        }));
        dispatch(getCalendarGoogleList({
            start: visibleMonthStart,
            end: visibleMonthEnd,
            additional_users: additionalUsers
        }))

    }, [CALENDAR_TITLE, createSessionSuccess]);

    return (
        <section className="section-wrap">
            {(!calendarEventsData || !calendarGoogleEventsData) && <Spinner />}

            {calendarEventsData && calendarFilters && calendarGoogleEventsData && calendarGoogleMembers &&
                <CalendarPage
                    visibleMonthStart={visibleMonthStart}
                    visibleMonthEnd={visibleMonthEnd}
                    updateDate={updateDate}
                    calendarEventsData={mapper(calendarEventsData)}
                    calendarGoogleData={mapper(calendarGoogleEventsData)}
                    calendarGoogleMembers={calendarGoogleMembers}
                    calendarFilters={calendarFilters}
                    onFilterChange={onFilterChange}
                    onFilterGoogleChange={onFilterGoogleChange}
                    absenceSchedule={role === 'participant' ? coachUnavailableSchedule : absence_schedule}
                    additionalUsers={additionalUsers}
                />
            }
        </section>
    )
}
