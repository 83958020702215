import {put, fork, takeLatest, select, call} from "redux-saga/effects";
import axios from "axios";
import api from 'constants/api';
import {GET_SCHOOL_INFO, REGISTER_FOR_PROGRAM} from "redux/constants";
import {
    getSchoolInfoSuccess,
    getSchoolInfoError,
    showNotification,
} from "redux/actions";
import {handleNewRegister} from "redux/sagas/registerForProgram/handleNewRegister";
import {handlerExistRegister} from "redux/sagas/registerForProgram/handleExistRegister";
import ResponseError from "helpers/ResponseError";
import {generalApiErrorHandler} from "redux/sagas/helpers/generalApiErrorHandler";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

const {GET_SCHOOL} = api;

function* watchGetProgramInfo() {
    yield takeLatest(GET_SCHOOL_INFO, handleGetProgramInfo);
}

function* watchRegisterForProgram() {
    yield takeLatest(REGISTER_FOR_PROGRAM, handleRegisterForProgram);
}

function* handleGetProgramInfo({payload: {programCode, programId}}) {
    try {
        const {data} = yield call(() => axios.get(`${GET_SCHOOL}/${programCode}/${programId}`)
            .then(data => data)
            .catch(error => error.response)
        );

        if (data?.status === 'Success') {
            yield put(getSchoolInfoSuccess(data.data));

        } else if (data?.status === 'Error') {
            const err = new ResponseError(data);

            yield put(getSchoolInfoError(err.getValidationErrorMessages()));

            yield put(showNotification({
                title: err.getMessage(),
                text: getNotificationMessage(err.getValidationErrorMessages())
            }, true));
        }

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

function* handleRegisterForProgram({ payload, callback }) {
    try {
        const token = yield select(getToken);

        token ?
            yield handlerExistRegister({...payload, token, callback})
            :
            yield handleNewRegister({...payload, token, callback});

    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* registerForProgram() {
    yield fork(watchGetProgramInfo);
    yield fork(watchRegisterForProgram);
}