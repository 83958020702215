import {
    CURRENT_PAGE_TITLE,
} from "redux/constants";

const initialState = {
    pageTitle: "",
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case CURRENT_PAGE_TITLE:
            return {
                ...state,
                pageTitle: payload,
            };

        default:
            return state;
    }
}