import {put, fork, takeLatest} from "redux-saga/effects";
import {SESSION_REQUEST} from "redux/constants";
import {showNotification} from "redux/actions";
import {createSessionRequest} from "redux/sagas/requestSession/handleRequestlSession";
import {getNotificationMessage} from "helpers/notifications";

function* watchSessionRequest() {
    yield takeLatest(SESSION_REQUEST, handleSessionRequest);
}

function* handleSessionRequest(requestData) {
    try {
        yield createSessionRequest(requestData);

    } catch (error) {
        yield put(showNotification({
            title: 'Error',
            text: getNotificationMessage(error.message),
        }, true));

        console.log(error.message);
    }
}

export default function* requestSession() {
    yield fork(watchSessionRequest);
}