import {settings} from "redux/constants";

export function sendStatusToggleSwitchNotification(sendStatusToggle) {
    return {
        type: settings.SEND_STATUS_TOGGLE_NOTIFICATION,
        payload: sendStatusToggle,
    };
}

export function updateUserSettings(updateFields, setErrors) {
    return {
        type: settings.PUT_SETTINGS,
        payload: updateFields,
        errorHandling: setErrors
    };
}

export function updateSchoolAdminSettings(updateFields, setErrors) {
    return {
        type: settings.PUT_SCHOOL_ADMIN_SETTINGS,
        payload: updateFields,
        errorHandling: setErrors
    };
}

export function updateUserSettingsSuccess(success) {
    return {
        type: settings.PUT_SETTINGS_SUCCESS,
        payload: success,
    };
}

export function updateUserSettingsError(error) {
    return {
        type: settings.PUT_SETTINGS_ERROR,
        payload: error,
    };
}

export function getGoogleAuthLink() {
    return {
        type: settings.GET_GOOGLE_AUTH_LINK,
    }
}

export function getGoogleAuthLinkSuccess(link) {
    return {
        type: settings.GET_GOOGLE_AUTH_LINK_SUCCESS,
        payload: link
    }
}

export function getGoogleAuthLinkError(errorData) {
    return {
        type: settings.GET_GOOGLE_AUTH_LINK_ERROR,
        payload: errorData
    }
}

export function getGoogleAuthStatus() {
    return {
        type: settings.GET_GOOGLE_AUTH_STATUS
    }
}

export function cancelGoogleAuth() {
    return {
        type: settings.CANCEL_GOOGLE_AUTH,
    }
}

export function cancelGoogleAuthError(errorData) {
    return {
        type: settings.CANCEL_GOOGLE_AUTH_ERROR,
        payload: errorData
    }
}

export function cancelGoogleAuthClearError() {
    return {
        type: settings.CANCEL_GOOGLE_AUTH_CLEAR_ERROR,
    }
}
