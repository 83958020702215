import React, { useRef, useState } from "react";
import "./Chatlist.scss";

import ChatList from "./ChatList";
import EmptyChatList from "./EmptyChatList";
import { useDispatch } from "react-redux";
import ChatHeader from "./ChatHeader";
import AddParticipantsToChat from "./AddParticipantsToChat";
import ChatWithUser from "./ChatWithUser";
import { clearUserMessages, getListRooms, getTotalMessages, getMessagesSpecifiedUser, setChatUser, setChatOpen } from "redux/actions";
import { useSelector } from 'react-redux';
import useClickOutside from 'hooks/useClickOutside';

export default function Chat({ getListRoomsChat }) {
    const dispatch = useDispatch();

    const scrollOnTopChatListRef = useRef();
    const chatContentRef = useRef();

    const [modalParticipants, setModalParticipants] = useState(false);
    const [userRoom, setUserRoom] = useState(null);
    const { chatUser, isChatOpen } = useSelector(state => state.chat);

    const handlerParticipantsData = (userData, specifiedUser) => {
        dispatch(setChatUser(userData))
        if (specifiedUser) {
            dispatch(getMessagesSpecifiedUser(userData))
        }
        setModalParticipants(false);
        scrollOnTopList();
    };

    const backToChatList = () => {
        dispatch(setChatUser(null))
        dispatch(clearUserMessages());
        dispatch(getListRooms());
        dispatch(getTotalMessages(null));
    };

    const closeModalParticipants = () => {
        setModalParticipants(false);
    };

    function scrollOnTopList() {
        const currentScrollSectionRef = scrollOnTopChatListRef.current;
        if (currentScrollSectionRef) {
            currentScrollSectionRef.scrollTop = 0;
        }
    }

    useClickOutside(chatContentRef, () => {
        if (isChatOpen) dispatch(setChatOpen(false))
    })

    return (
        <div className="chat-overlay">
            <div className="chat-wrapper" ref={chatContentRef}>
                {
                    !chatUser && <ChatHeader setModalParticipants={setModalParticipants} />
                }
                {
                    modalParticipants &&
                    <AddParticipantsToChat closeModalParticipants={closeModalParticipants} handlerParticipantsData={handlerParticipantsData} />
                }
                {
                    chatUser && <ChatWithUser userRoom={userRoom} backToChatList={backToChatList} />
                }
                {
                    getListRoomsChat && getListRoomsChat.length > 0 ?
                        <>
                            {
                                !chatUser &&
                                <ChatList setUserRoom={setUserRoom} getListRoomsChat={getListRoomsChat} handlerParticipantsData={handlerParticipantsData} />
                            }
                        </>
                        :
                        <>
                            {
                                !chatUser && <EmptyChatList />
                            }
                        </>
                }
            </div>
        </div>
    )
};
