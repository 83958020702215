import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MySettings.scss";
import Button from "components/button/Button";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ToggleSwitch from "./ToggleSwitch";
import SettingsSelect from "components/CustomFormSelect/SettingsSelect";
import { sendStatusToggleSwitchNotification, updateUserSettings } from "redux/actions"
import timezones from 'timezones-list';
import moment from "moment"
import SelectGoogleCalendar from "./SelectGoogleCalendar";
import GoogleSync from "components/googleSync/googleSync";
import { useLocation } from "react-router-dom";
import { getOffsetPosition, scrollToEl } from "helpers/helpers";
import { removeEmptyValues } from "helpers/removeEmptyValues";

export default function CoachesSettings({ getCurrentUserInfo }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const googleAuthRef = useRef();

    useLayoutEffect(() => {
        if (location.hash === `#${googleAuthRef?.current.id}`) {
            const scrollPosition = getOffsetPosition(googleAuthRef.current);

            scrollToEl(scrollPosition);
        }
    }, [location.hash, googleAuthRef]);

    const [checkboxValue, setCheckboxValue] = useState(true);

    const getUserSettings = useSelector(state => state.userSettings); 

    useEffect(() => {
        setCheckboxValue(getCurrentUserInfo.is_notifiable);
    }, []);

    const ToggleSwitchNotification = (getValue) => {
        setCheckboxValue(getValue);
        dispatch(sendStatusToggleSwitchNotification(getValue));
    }

    // time zones 
    let selectProfileTZValue = timezones.find(item => item.tzCode === getCurrentUserInfo.timezone);
    const selectDeviceTZValue = timezones.find(item => item.tzCode === moment.tz.guess());

    const [toggled, setToggled] = useState(false);

    const WrappedSelect = function (props) {
        return (<SettingsSelect {...props} toggled={toggled} />);
    };

    return (
        <div className="settings-wrap" >
            <Formik
                // innerRef={formRef} 
                initialValues={{
                    email: getCurrentUserInfo.email,
                    phone: getCurrentUserInfo.phone,
                    old_password: '',
                    password: '',
                    password_confirmation: '',
                    timezone: getCurrentUserInfo.timezone ? getCurrentUserInfo.timezone : '',
                    use_local_tz: getCurrentUserInfo?.use_local_tz
                }}
                validationSchema={Yup.object({
                    email: Yup.string().email('Email must be a valid email').required("This field is required"),
                    // newPassword: Yup.string().required("This field is required"),

                    password: Yup.string()
                        // .required('Please Enter your password')
                        .matches(
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
                            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        ),
                    password_confirmation: Yup.string().when("password", {
                        is: val => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf(
                            [Yup.ref("password")],
                            "Both password need to be the same"
                        )
                    })
                })}
                onSubmit={
                    (values, { setErrors, setSubmitting, setStatus }) => {
                        setSubmitting(false);
                        dispatch(updateUserSettings(removeEmptyValues(values), setErrors));
                    }
                }

            >

                {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, dirty, setFieldValue, handleBlur, status, ...props }) => {

                    const onChangeCheckbox = (event) => {

                        setToggled(!toggled);
                        setFieldValue('use_local_tz', !values.use_local_tz);

                        if (event.target.checked === true) {
                            setFieldValue('timezone', moment.tz.guess());
                        } else {
                            setFieldValue('timezone', getCurrentUserInfo?.timezone);
                        }
                    }

                    return (

                        <Form> 

                            <div className="section-info-block privacy">

                                <h3 className="block-title">Privacy</h3>

                                <div className={`settings-row email ${errors.email && touched.email ? 'input-error' : ''}`}>
                                    <label htmlFor={'email'} className="settings-row__label">Email</label>
                                    <Field
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        className={`settings-row__input`}
                                    />
                                    <ErrorMessage className={`settings-row__error`} component="div" name="email" />

                                </div>
                                <div className={`settings-row phone ${errors.phone && touched.phone ? 'input-error' : ''}`}>
                                    <label htmlFor={'phone'} className="settings-row__label">Phone</label>
                                    <Field
                                        name="phone"
                                        type="text"
                                        placeholder="Phone number"
                                        className={`settings-row__input`}
                                    />
                                    <ErrorMessage className={`settings-row__error`} component="div" name="phone" />

                                </div>

                                <div className={`settings-row ${values.use_local_tz  ? 'no-select' : ''} timezone ${errors.timezone && touched.timezone ? 'input-error' : ''}`}>
                                    <label htmlFor={'timezone'} className="settings-row__label">My Time Zone</label>
                                    <div className="timezone_option">
                                        <Field
                                            onChange={onChangeCheckbox}
                                            type="checkbox"
                                            name="use_local_tz" 
                                            checked={values.use_local_tz}
                                        /> Use your device-provided time zone
                                    </div>

                                    <Field
                                        name="timezone"
                                        placeholder="My Time Zone"
                                        className={`settings-row__input`}
                                        disabled={toggled}
                                        component={WrappedSelect}
                                        options={values.use_local_tz ? [] : timezones}
                                        defaultValue={values.use_local_tz  ? selectDeviceTZValue : selectProfileTZValue}
                                    />

                                    <ErrorMessage
                                        className={`settings-row__error`}
                                        component="div"
                                        name="timezone"
                                    />
                                </div>

                            </div>

                            <div className="section-info-block change-pass">

                                <div className="change-pass-section">
                                    <h3 className="block-title">Change password</h3>

                                    <div className={`settings-row old_password ${errors.old_password && touched.old_password ? 'input-error' : ''}\`}`}>
                                        <label htmlFor={'old_password'} className="settings-row__label">Old password</label>

                                        <Field
                                            name="old_password"
                                            type="password"
                                            placeholder="Enter old password..."
                                            className={`settings-row__input`}
                                        />

                                        <ErrorMessage className={`settings-row__error`} component="div" name="old_password" />
                                    </div>

                                    <div className={`settings-row password ${errors.password && touched.password ? 'input-error' : ''}`}>
                                        <label htmlFor={'password'} className="settings-row__label">New password</label>

                                        <Field
                                            name="password"
                                            type="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            placeholder="Enter new password..."
                                            className={`settings-row__input`}
                                        />

                                        <ErrorMessage className={`settings-row__error`} component="div" name="password" />
                                    </div>

                                    <div className={`settings-row password_confirmation ${errors.password_confirmation && touched.password_confirmation ? 'input-error' : ''}`}>
                                        <label htmlFor={'password_confirmation'} className="settings-row__label">New password</label>

                                        <Field
                                            name="password_confirmation"
                                            type="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password_confirmation}
                                            placeholder="Enter new password one more time..."
                                            className={`settings-row__input`}
                                        />

                                        <ErrorMessage className={`settings-row__error`} component="div" name="password_confirmation" />
                                    </div>

                                </div>
                                <Button type="submit"
                                    className="change-pass-btn" 
                                    disabled={isSubmitting || !dirty}
                                >
                                    Save changes
                                </Button>
                                <div>

                                </div>
                                <div className="result-save-settings-form">

                                    {
                                        getUserSettings.successSettings &&
                                        <div className="message-text success">{getUserSettings.successSettings}</div>
                                    }


                                    {
                                        getUserSettings.errorsSettings &&
                                        <div className="message-text error">{getUserSettings.errorsSettings}</div>
                                    }

                                </div>
                                <div className="settings-row">
                                    <h3 className="block-title" id="g-anchor" ref={googleAuthRef}>Calendar settings</h3>
                                    <GoogleSync isSync={getCurrentUserInfo.google_account} />
                                </div>
                                <SelectGoogleCalendar />
                            </div>
                        </Form>
                    )
                }}

            </Formik>

            {
                false &&
                <div className="section-info-block change-pass">
                    <h3 className="block-title">Notification settings</h3>


                    {/*<div className="send-me-email">*/}
                    {/*    <p className={`text-send-me`}>Send me notifications to</p>*/}
                    {/*    <a className={`mail-to`} href={`mailto:${getCurrentUserInfo.email}`}>{getCurrentUserInfo.email}</a>*/}
                    {/*</div>*/}

                    <div className="send-me-notification">

                        <div className="set-status-send-me-notification">
                            <div className="text">Send me email notifications</div>
                            <div className="toggle-button">
                                <ToggleSwitch
                                    title="toggle switch notification"
                                    value={checkboxValue}
                                    checked={checkboxValue}
                                    onChange={() => {
                                        ToggleSwitchNotification(!checkboxValue)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="info-text">
                            <h4 className={`info-title`}>You will receive email updates about items such as:</h4>
                            <div className="list">
                                <div className="item">New coachees</div>
                                <div className="item">Session invitations</div>
                                <div className="item">Upcoming coaching sessions</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}