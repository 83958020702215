import {
    CHOOSE_QUESTIONNAIRE_LANGUAGE,
    REMOVE_QUESTIONNAIRE_LANGUAGE,
    UPDATE_QUESTIONNAIRE_LANGUAGE_FROM_LOCALSTORAGE
} from 'redux/constants'

export const chooseQuestionnaireLanguage = (language) => {
    return {
        type: CHOOSE_QUESTIONNAIRE_LANGUAGE,
        payload: language
    }
}
export const updateQuestionnaireLanguageFromLocalstorage = (language) => {
    return {
        type: UPDATE_QUESTIONNAIRE_LANGUAGE_FROM_LOCALSTORAGE,
        payload: language
    }
}

export const removeQuestionnaireLanguage = () => ({ type: REMOVE_QUESTIONNAIRE_LANGUAGE })