import {calendarGoogleMembers} from "redux/constants";

const initialState = {
    calendarGoogleMembers: null,
    calendarGoogleMembersNull: true,
    calendarGoogleMembersErrorMessage: null
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS_SUCCESS:
            return {
                ...state,
                calendarGoogleMembers: payload,
                calendarGoogleMembersNull: true,
            };

        case calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS_NULL:
            return {
                ...state,
                calendarGoogleMembers: payload,
                calendarGoogleMembersNull: false,
            };

        case calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS_ERROR:
            return {
                ...state,
                calendarGoogleMembersErrorMessage: payload,
                calendarGoogleMembersNull: false,
            };

        default:
            return state;
    }
}