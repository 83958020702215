import {calendarFilters} from "redux/constants";

export function getFiltersOfCalendarList(filtersOfCalendarDefaultData) {
    return {
        type: calendarFilters.GET_FILTERS_OF_CALENDAR,
        payload: filtersOfCalendarDefaultData,
    };
}

export function getFiltersOfCalendarSuccess(filtersOfCalendarSuccess) {
    return {
        type: calendarFilters.GET_FILTERS_OF_CALENDAR_SUCCESS,
        payload: filtersOfCalendarSuccess,

    };
}

export function getFiltersOfCalendarEmpty(emptylist) {
    return {
        type: calendarFilters.GET_FILTERS_OF_CALENDAR_NULL,
        payload: emptylist,
    };
}

export function getFiltersOfCalendarError(error) {
    return {
        type: calendarFilters.GET_FILTERS_OF_CALENDAR_ERROR,
        payload: error,
    };
}


export function getNewFiltersOfCalendarItemsOnScroll(getNewItems2) {
    return {
        type: calendarFilters.GET_NEW_FILTERS_OF_CALENDAR_ON_SCROLL,
        payload: getNewItems2,
    };
}

export function setClearFiltersOfCalendarFilters(clearSetNewData2) {
    return {
        type: calendarFilters.CLEAR_FILTERS_OF_CALENDAR_FILTERS,
        payload: clearSetNewData2,
    };
}