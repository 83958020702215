import { getData, postData, updateData } from "redux/sagas/helpers/requestHandlers";
import {
    COMPLETE_SESSION_ROUTE,
    EDIT_SESSION_ROUTE,
    SESSION_FILTERS_ROUTE,
    SESSION_ROUTE,
    SESSION_TIME_ROUTE,
    COACH_ABSENCE_SCHEDULE_ROUTE
} from "constants/api";
import { put, call, select } from "redux-saga/effects";
import {
    createSessionError,
    createSessionSuccess, getSessionFilterError, getSessionFilterSuccess, getSessionTimeError, getSessionTimeSuccess,
    getCoachAbsenceScheduleSuccess
} from "redux/actions/createSession";
import { completeSessionSuccess, showNotification, updateUserSingleProgram } from "redux/actions";
import ResponseError from "helpers/ResponseError";
import { getNotificationMessage } from "helpers/notifications";
import { getToken } from "redux/sagas/helpers/helpers";

export function* getSessionsTimeRequest(requestData, token) {
    const { data } = yield call(postData(SESSION_TIME_ROUTE, requestData, token));

    if (data?.status === 'Success') {
        yield put(getSessionTimeSuccess(data.data));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        yield put(getSessionTimeError(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}

export function* getCoachAbsenceScheduleRequest({ payload: token, params }) {
    const { data } = yield call(getData(COACH_ABSENCE_SCHEDULE_ROUTE, token, { params: { ...params } }));

    if (data?.status === 'Success') {
        yield put(getCoachAbsenceScheduleSuccess(data.data));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        // yield put(getSessionTimeError(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}

export function* createSessionRequest(requestData) {
    const token = yield select(getToken);
    const { data } = yield call(postData(SESSION_ROUTE, requestData, token));

    if (data?.status === 'Success') {
        yield put(createSessionSuccess(data.data));
        yield put(updateUserSingleProgram(data.data));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        yield put(createSessionError(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}

export function* editSessionRequest(additionalData) {
    const token = yield select(getToken);
    const { sessionId, ...requestData } = additionalData;
    const route = EDIT_SESSION_ROUTE(sessionId);

    const { data } = yield call(updateData(route, requestData, token));

    if (data?.status === 'Success') {
        yield put(createSessionSuccess(data.data));
        yield put(updateUserSingleProgram(data.data));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        yield put(createSessionError(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}

export function* completeSessionRequest({ payload: sessionId }) {
    const route = COMPLETE_SESSION_ROUTE(sessionId);
    const token = yield select(getToken);

    const { data } = yield call(postData(route, null, token));

    if (data?.status === 'Success') {
        yield put(completeSessionSuccess(data.data));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        yield put(completeSessionSuccess(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}

export function* getSessionFilterRequest({ payload = '' }) {
    const token = yield select(getToken);

    const { data } = yield call(getData(SESSION_FILTERS_ROUTE, token, {
        params: payload
    }));

    if (data?.status === 'Success') {
        yield put(getSessionFilterSuccess(data.data));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        yield put(getSessionFilterError(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}