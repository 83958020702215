import React from 'react';
import './EmptySessionNotify.scss';
// for ILTB
// import {ReactComponent as EmptyIcon} from "assets/vector/programmes/empty-seession.svg";
import {ReactComponent as EmptyIcon} from "assets/vector/programmes/empty-session-switch.svg";
import Button from "components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {SESSION_EVENT_MODAL} from "redux/constants/modal";
import {openModal} from "redux/actions/modal";
import {fetchSessionInfo, showNotification} from "redux/actions";

const EmptySessionNotify = (props) => {
    const {programId, activityId} = props;
    const dispatch = useDispatch();
    const {fetchSessionInfoProgress} = useSelector(state => state.sessionInvitation);

    const scheduleSession = (isSessionExist) => {
        if (isSessionExist) {
            dispatch(showNotification({
                title: 'Session exists',
                text: 'It seems that the session has been already created.',
            }, 6000));

        } else {
            dispatch(openModal(SESSION_EVENT_MODAL, {...props}));
        }
    };

    const handleScheduleSession = () => dispatch(fetchSessionInfo(programId, activityId, scheduleSession));

    return (
        <div className="empty-session">
            <EmptyIcon className="empty-session__icon"/>
            <h3 className="empty-session__title">
                You don’t have any invitations to the session yet...
            </h3>
            <p className="empty-session__subtext">
                Please wait until someone will send you an invitation or schedule one by yourself
            </p>
            <Button className="button button--orange-invert empty-session__add-btn"
                    loading={fetchSessionInfoProgress}
                    spinner={{color: '#4296E5'}}
                    disabled={fetchSessionInfoProgress}
                    onClick={handleScheduleSession}>
                Schedule session
            </Button>
        </div>
    );
};

export default EmptySessionNotify;