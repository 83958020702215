import React from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../SignUp/SignUp.scss';
import { loginUserRequest } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import routes from "constants/routes";
import AuthFormField from "components/auth/authFormField/authFormField";
import Button from "components/button/Button";

const SignInForm = ({ code, id }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { RESET_PASSWORD } = routes;
    const { doingRequestLogin } = useSelector((state) => state.auth);

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                code,
                id
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address')
                    .required('Required'),
                password: Yup.string().required('Required'),
            })}
            onSubmit={
                (values) => {
                    const { email } = values;
                    if (id) {
                        localStorage.setItem('school_program_id', id)
                    }

                    dispatch(loginUserRequest(
                        { ...values, email: email.toLowerCase() },
                        location?.state?.pathname ? () => history.push(location.state.pathname) : null));
                }
            }
        >

            <Form className='sign-up' autoComplete="on" noValidate={true}>
                <Field
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="email.example@gmail.com..."
                    className="sign-up__input"
                    component={AuthFormField}
                    defaultValue={value => value}
                // onChange={(event, value) => console.log(value)}
                />
                <Field
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Please enter your password..."
                    className="sign-up__input"
                    component={AuthFormField}
                />
                <div className="sign-up__bottom sign-up__bottom--sign-in">
                    <Button type="submit"
                        className="sign-up__submit"
                        loading={doingRequestLogin}
                        disabled={doingRequestLogin}>
                        Log In
                    </Button>
                    <Link to={{
                        pathname: RESET_PASSWORD,
                        search: code && id && `?code=${code}&id=${id}`
                    }}
                        className="sign-up__login-link">Forgot password?</Link>
                </div>
            </Form>
        </Formik>
    );
};

export default SignInForm;