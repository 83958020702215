import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import './matchedCoachProfile.scss';
import decor_image1 from 'assets/vector/auth-page/decor-3.svg';
import decor_image2 from 'assets/vector/auth-page/auth-decor-lines.svg';

import Footer from 'components/pages/Auth/Footer/Footer';
import Header from 'components/pages/Auth/Header/Header';
import CoachesProfile from 'components/pages/Coaches/CoachesProfile';
import routes from 'constants/routes';
import { showNotification } from 'redux/actions';
import { Redirect } from 'react-router-dom';

const MatchedCoachProfile = () => {

    const { DASHBOARD } = routes;
    const dispatch = useDispatch();

    const { last_participant_program_id, main_coach } = useSelector(state => state.currentUserInfo?.currentUserInfo) || {};

    useEffect(() => {
        if (main_coach) {
            dispatch(showNotification({
                title: 'The main coach was already assigned'
            }))
        }
    }, [main_coach, dispatch])

    if (main_coach) {
        return <Redirect to={DASHBOARD} />
    }

    return (
        <section className="matched-coach">
            <Header />
            <img className="decor-image-left" src={decor_image1} alt="" />
            <img className="decor-image-right" src={decor_image2} alt="" />
            <div className="profile-info__container">
                {
                    last_participant_program_id &&
                    <CoachesProfile
                        matching={true}
                        participant_program_id={last_participant_program_id}
                    />
                }
            </div>
            <Footer />
        </section>
    )
}

export default MatchedCoachProfile;
