import React, {useState} from 'react';
import ActionListItem from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/ActionListItem/ActionListItem";
import uniqid from "uniqid";
import './CategorizedActionItem.scss';
import CategoryDescriptionEditor
    from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/CategoryDescriptionEditor/CategoryDescriptionEditor";
import {addNewActivityActionItem, deleteActivityActionItem} from "redux/actions";
import {useDispatch} from "react-redux";
import ActionItemTooltip
    from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/ActionItemTooltip/ActionItemTooltip";
import {transformDatePickerDate} from "helpers/userPrograms";

const CategorizedActionItem = ({categories, todo, num, activityId, removeActionItem, toggleCompleted}) => {
    const dispatch = useDispatch();
    const categoryRelatedNames = categories?.reduce((list, category) => {
        list[category.id] = category.name;

        return list;
    }, {});

    const actionItems = todo.map(
        item => <ActionListItem
            key={uniqid()}
            toggleCompleted={toggleCompleted}
            removeActionItem={removeActionItem}
            activityId={activityId}
            {...item}
        />
    );

    const [isAddActionShow, setAddActionShow] = useState(false);

    const showAddActionForm = () => setAddActionShow(true);

    const hideAddActionForm = () => setAddActionShow(false);

    const addAction = (categoryId, field = {}) => {
        const dueDate = field.dueDate ? {due_date: transformDatePickerDate(field.dueDate)} : {};

        dispatch(addNewActivityActionItem({
            activityId: activityId,
            description: field.text,
            category_id: categoryId,
            ...dueDate
        }));
    };

    const removeAllActionItems = () => {
        todo.forEach((item) => {
            dispatch(deleteActivityActionItem(activityId, item.id));
        })
    };

    const [tooltipInfo, setTooltipInfo] = useState(false);

    const showTooltipInfo = () => {
        setTooltipInfo(!tooltipInfo);
    };

    return (
        <div className="categorized-action">
            <h5 className='category-description__topic'>
                Topic {num}
            </h5>
            <h4 className='category-description__title'>
                {categoryRelatedNames[todo[0].category_id]}
                <span className="category-description__actions" onClick={showTooltipInfo}>
                    &middot;&middot;&middot;
                    <ActionItemTooltip show={tooltipInfo}
                                       showAddActionForm={showAddActionForm}
                                       removeAllActionItems={removeAllActionItems}/>
                </span>
            </h4>
            <ul className="categorized-action__list">
                {actionItems}
            </ul>
            {
                isAddActionShow && <CategoryDescriptionEditor isTodo={true}
                                                              selectLabel={'Topic'}
                                                              selectPlaceholder={'-'}
                                                              textLabel={'Action item description'}
                                                              hideCategoryEditor={hideAddActionForm}
                                                              handleSubmit={addAction}
                                                              defaultCategory={{
                                                                  id: todo[0].category_id,
                                                                  label: categoryRelatedNames[todo[0].category_id]
                                                              }}
                />
            }
        </div>
    );
};

export default CategorizedActionItem;