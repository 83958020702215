import React, { useState, useEffect, useRef } from "react";
import ReactQuill from 'react-quill';
import PerfectScrollbar from "react-perfect-scrollbar";
import { ReactComponent as BackSVG } from "assets/vector/chat/back.svg";
import { ReactComponent as SendMessageSVG } from "assets/vector/chat/send-message.svg";
import avatar from "assets/vector/default-avatar.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendMessageToUser, getMessagesFromUserOnScroll } from "redux/actions";
import uniqid from "uniqid";
import moment from "moment-timezone";
import "./Chatlist.scss";

export default function ChatWithUser({ userRoom, backToChatList }) {
    const dispatch = useDispatch();
    const defaultShowMessagesItems = 50;
    const [textMessageValue, setTextMessageValue] = useState('');
    const [offsetMessagesItems, setOffsetMessagesItems] = useState(defaultShowMessagesItems);
    const [calendarAppointmentSort, setCalendarAppointmentSort] = useState([]);
    const [scrollEl, setScrollEl] = useState();
    const wrapRef = useRef(null);
    const typeMessageRef = useRef(null)

    const currentUserRole = useSelector(state => state.currentUserInfo.currentUserInfo.role);
    const userTimezone = useSelector(state => state.currentUserInfo.currentUserInfo.timezone);
    const { chatUser, messagesFromUser, countMessages } = useSelector(state => state.chat)

    const textEditorOptions = [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        ['clean']
    ];

    function createMarkup(content) {
        return { __html: content };
    }

    const sendMessage = () => {
        if (textMessageValue !== '') {
            dispatch(sendMessageToUser({
                message: textMessageValue,
                userId: chatUser?.id,
                userRole: chatUser?.role,
            }));

            setTextMessageValue('');
        }
    };

    // load new messages on scroll
    function onScrollMessagesList(event) {
        const chatScrollTop = event.target.scrollTop;

        if (chatScrollTop <= 50 && offsetMessagesItems <= countMessages) {
            let getNewItems = {
                roomID: userRoom,
                offset: offsetMessagesItems,
                take: defaultShowMessagesItems,
            };
            setOffsetMessagesItems(offsetMessagesItems + offsetMessagesItems);
            dispatch(getMessagesFromUserOnScroll(getNewItems));
        }
    }

    useEffect(() => {
        // need to display the last message in the bottom of the chat container
        if (scrollEl && wrapRef && messagesFromUser?.length <= defaultShowMessagesItems) {

            scrollEl.scrollTop = wrapRef.current.scrollHeight;
        }
    }, [scrollEl, wrapRef, calendarAppointmentSort, messagesFromUser]);

    useEffect(() => {
        if (messagesFromUser && messagesFromUser.length) {
            let sortingByDate = [];
            let itemOnIteration = "";

            for (let i in messagesFromUser) {
                let item = messagesFromUser[i];
                let periodStart = moment(item.created_at).format('l');
                let testObj = {};

                if (periodStart === itemOnIteration) {
                    let lastItemArray = sortingByDate[sortingByDate.length - 1];
                    lastItemArray[itemOnIteration].push(item)
                } else {
                    testObj[periodStart] = [item];
                    sortingByDate.push(testObj);
                    itemOnIteration = periodStart;
                }
            }

            setCalendarAppointmentSort(sortingByDate)
        }
    }, [messagesFromUser]);

    return (
        <div className="chat-with-user">
            <div className="user-chat-header">
                <BackSVG
                    onClick={(e) => {
                        e.stopPropagation();
                        backToChatList();
                    }}
                    className={`back-to-chat-list`}
                />
                <div className="info-block">
                    <img src={chatUser?.avatar ? chatUser.avatar : avatar} className={`avatar`} alt="" />
                    <div className="user-name">{chatUser?.full_name}</div>
                </div>
            </div>
            <PerfectScrollbar
                className="chat-dashboard-list"
                onScroll={event => onScrollMessagesList(event)}
                containerRef={el => (setScrollEl(el))}
            >
                <div className="chat-messages-wrapper" ref={wrapRef}>
                    {
                        scrollEl && wrapRef && calendarAppointmentSort &&
                        calendarAppointmentSort.map(
                            (element) => {
                                // used for setting referenceDay's date for moment#calendar
                                const referenceDay = moment().tz(userTimezone).format('l')
                                return (
                                    <div key={uniqid()}>
                                        <div className="chat-date">
                                            <span className="value">
                                                {moment(Object.keys(element).toString()).calendar(referenceDay, {
                                                    sameDay: '[Today]',
                                                    lastDay: '[Yesterday]',
                                                    lastWeek: 'MMMM, DD',
                                                    sameElse: 'MMMM, DD'
                                                })}
                                            </span>
                                        </div>
                                        {Object.values(element)[0].map((element, k) => {
                                            return (
                                                <div key={uniqid()}
                                                    className={`chat-message-item ${element.role === currentUserRole && `my-self-message`}`}>
                                                    <div className={`${element.role === currentUserRole ? `my-self` : `opponent`}`}>
                                                        <div className="message">
                                                            <div className="message-info">
                                                                <div className="name"> {element.role === currentUserRole ? `You` : element.username}</div>
                                                                <div className="time">{moment(element.created_at).format("HH:mm")}</div>
                                                            </div>
                                                            {/* <div className="text-message">{element.message}</div> */}

                                                            <div className="text-message" dangerouslySetInnerHTML={createMarkup(element.message)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </PerfectScrollbar>
            <div className="type-message" ref={typeMessageRef}>
                <ReactQuill
                    modules={{
                        toolbar: textEditorOptions
                    }}
                    placeholder={`Type your message...`}
                    theme="snow"
                    value={textMessageValue}
                    onChange={setTextMessageValue} />
                <SendMessageSVG
                    onClick={(e) => {
                        e.stopPropagation();
                        sendMessage();
                    }}
                    className={`icon send-message-button`}
                />
            </div>
        </div>
    )
};
