import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from '../Auth/Footer/Footer';
import Header from '../Auth/Header/Header';
import CustomPlatformSettings from "services/platformSettings/platformSettings";

import './Questionnaire.scss';

// for ILTB
// import decor_image1 from 'assets/vector/auth-page/decor-3.svg';
// import decor_image2 from 'assets/vector/auth-page/auth-decor-lines.svg';
import StartPage from './StartPage/StartPage';
import {
    getPlatformSettings
} from "redux/actions";
import { Redirect } from 'react-router-dom';

const Questionnaire = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (window.localStorage.getItem('school_id')) {
            dispatch(getPlatformSettings(window.localStorage.getItem('school_id')))
        }
    }, []);

    const platformSettings = useSelector((state) => state.platformSettings.platformSettings);

    const { survey, role } = useSelector(state => state.currentUserInfo.currentUserInfo) || {};

    if (platformSettings) {
        localStorage.setItem('platformSettings', JSON.stringify(platformSettings.settings));
        CustomPlatformSettings();
    }

    if (role === 'participant' && (!survey?.required || survey?.skip)) {
        return <Redirect to='/' />
    }

    return (
        <section className="questionnaire-section">
            <Header />
            {/* for ILTB */}
            {/* <img className="decor-image-left" src={decor_image1} alt="" /> */}
            {/* <img className="decor-image-right" src={decor_image2} alt="" /> */}
            <StartPage />
            <Footer />
        </section>
    )
}

export default Questionnaire;