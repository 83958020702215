import React from 'react';
import { Link } from 'react-router-dom';
import defaultAva from 'assets/vector/default-avatar.svg';

const ParticipantProfilePreview = ({ program }) => {
    return (
        <Link className="view-profile__card" to={`/programmes/single-program/${program.id}`}>
            <div className={`item ${program.is_deactivated ? 'deactivated' : ''}`} >
                <div className="participant-avatar">
                    <img src={program.participant.avatar ? program.participant.avatar : defaultAva} alt="" />
                </div>
                <div className="participant-short-info">
                    <h3 className={`name`}>
                        <span>{program.participant.name}</span>
                        {program.is_deactivated && <div className='deactivated-label'>Deactivated</div>}
                    </h3>
                    <div className="participant-block-wrapper">
                        <div className="participant-left-block">
                            <p className={`country`}> {program.participant.program_name}</p>
                            <p className="program-in-progress">{program.participant.school_name}</p>
                        </div>
                        <div className="participant-right-block">
                            <div className="sessions">
                                <div className="counter">
                                    <span className={`from`}>{program.participant.completed_session_activities}</span>
                                    <span className={`to`}> of {program.participant.total_session_activities} sessions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="participant-full-info">
                    <div className="view-profile">View programme</div>
                </div>
            </div>
        </Link>
    )
}

export default ParticipantProfilePreview;
