import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import profile from "services/profile";
import Spinner from "components/Spinner/Spinner";
import Button from "components/button/Button";
import CustomMultiSelectParticipant from "components/CustomFormSelect/CustomMultiSelectParticipant";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ReactComponent as Search } from 'assets/vector/search.svg';
import { ReactComponent as CloseFilter } from 'assets/vector/close-filter.svg';
import { ReactComponent as FilterToggleButton } from 'assets/vector/filter-toggle-button.svg';
import search_is_nothing_found from 'assets/vector/participant/search-is-nothing-found.svg';
import { useDispatch, useSelector } from "react-redux";
import { getProgramParticipantsList, setClearProgramParticipantsFilters } from "redux/actions";
import ParticipantProfilePreview from './ParticipantProfilePreview';
import './ProgrammesParticipantsList.scss';

export default function ProgrammesParticipantsList() {

    const location = useLocation();
    const dispatch = useDispatch();

    const scrollSectionRef = useRef();

    const { programParticipantsList, programParticipantsExist, programParticipantsLoading } = useSelector(state => state.programParticipantsList);
    const userToken = useSelector(state => state.auth.authToken);

    const [newUserProgramsList, setNewUserProgramsList] = useState(null);
    const [statusFilterToggle, setStatusFilterToggle] = useState(false);
    const [focusSearch, setFocusSearch] = useState(false);
    const [participantsSearchValue, setParticipantsSearchValue] = useState('');
    const [selectParticipantsFiltersValue, setSelectParticipantsFiltersValue] = useState({
        languages: [],
        industry_background: [],
        functional_areas: []
    });

    const currentProgrammName = useMemo(
        () => programParticipantsList?.filter(program => program.id === +(location.pathname.split('/')[location.pathname.split('/').length - 1]))[0]?.name,
        [programParticipantsList, location])

    const newResultFunctionalAreas = selectParticipantsFiltersValue.functional_areas.map(function (ellement) {
        return ellement.id;
    });

    const newResultIndustryBackground = selectParticipantsFiltersValue.industry_background.map(function (ellement) {
        return ellement.id;
    });

    const newResultLanguagesList = selectParticipantsFiltersValue.languages.map(function (ellement) {
        return ellement.id;
    });

    const [languageOptions, setLanguageOptions] = useState(null);
    const [industryBackgroundOptions, setIndustryBackgroundOptions] = useState(null);
    const [functionalAreaOptions, setFunctionalAreaOptions] = useState(null);

    function handleParticipantsSearchChange(event) {
        setParticipantsSearchValue(event.target.value);

        let getFormValue = {
            search: event.target.value,
            functional_areas: newResultFunctionalAreas,
            industry_background: newResultIndustryBackground,
            languages: newResultLanguagesList,
        };

        dispatch(getProgramParticipantsList(getFormValue));
    }

    const handleChangeSelect = (values) => {
        const newValuesFunctionalAreas = values.functional_areas.map(function (ellement) {
            return ellement.id;
        });

        const newValuesIndustryBackground = values.industry_background.map(function (ellement) {
            return ellement.id;
        });

        const newValuesLanguagesList = values.languages.map(function (ellement) {
            return ellement.id;
        });

        let getFormValue = {
            search: participantsSearchValue,
            functional_areas: newValuesFunctionalAreas,
            industry_background: newValuesIndustryBackground,
            languages: newValuesLanguagesList,
        };

        dispatch(getProgramParticipantsList(getFormValue));
        setSelectParticipantsFiltersValue(values);
    };

    const clearSearchField = () => {
        setParticipantsSearchValue('');

        let getFormValue = {
            search: '',
            functional_areas: newResultFunctionalAreas,
            industry_background: newResultIndustryBackground,
            languages: [],

        };
        dispatch(getProgramParticipantsList(getFormValue));
    }

    const getDefinedData = () => {

        profile.getDefinedData(userToken)
            .then((response) => {
                setLanguageOptions(response.languages);
                setIndustryBackgroundOptions(response.industryBackgrounds);
                setFunctionalAreaOptions(response.functionalAreas);
            })
            .catch(error => console.log(error))
    };

    function scrollTopParticipants() {
        const currentScrollSectionRef = scrollSectionRef.current;
        if (currentScrollSectionRef) {
            currentScrollSectionRef.scrollTop = 0;
        }
    }

    const handleReset = () => {
        clearSearchField();
        scrollTopParticipants();

        let getFormValue = {
            search: participantsSearchValue,
            languages: [],
            industry_background: [],
            functional_areas: [],
        };
        dispatch(getProgramParticipantsList(getFormValue));
        dispatch(setClearProgramParticipantsFilters(getFormValue));
        setSelectParticipantsFiltersValue({
            languages: [],
            industry_background: [],
            functional_areas: []
        });

    };

    useEffect(() => {
        getDefinedData();
    }, []);

    useEffect(() => {
        let getFormValue = {
            search: '',
            functional_areas: [],
            industry_background: [],
            languages: [],
        };

        dispatch(getProgramParticipantsList(getFormValue));
    }, []);

    useEffect(() => {
        if (programParticipantsList?.length > 0) {
            setNewUserProgramsList(programParticipantsList
                .filter(program => program.name === currentProgrammName)
                .filter(participant => participant.is_deactivated === location?.state.is_deactivated)
            );
        } else {
            setNewUserProgramsList([])
        }
    }, [programParticipantsList]);

    if (!programParticipantsExist && programParticipantsLoading) {
        return (
            <div className="program-participants">
                <Spinner />
            </div>
        )
    }

    return (
        <Formik
            onReset={handleReset}
            initialValues={{
                languages: [],
                industry_background: [],
                functional_areas: [],
            }}
            onSubmit={(values) => handleChangeSelect(values)}
        >
            {({ handleReset, values, isSubmitting }) => {
                return (
                    <Form>
                        <section className="program-participants">
                            <div className={`participants-filters ${statusFilterToggle ? 'open-filter' : ''}`}>
                                <div className="mobile-filter-header">
                                    <h3>Filters</h3>
                                    <div className="close-filter"><CloseFilter onClick={() => setStatusFilterToggle(false)} /></div>
                                </div>
                                <div className="filter-item language">
                                    <p className={`filter-group-title`}>Language</p>
                                    <Field
                                        name='languages'
                                        options={languageOptions}
                                        defaultValue={values.languages}
                                        component={CustomMultiSelectParticipant}
                                        placeholder="All"
                                    />
                                </div>
                                <div className="filter-item industry">
                                    <p className={`filter-group-title`}>Industry Background</p>
                                    <Field
                                        name='industry_background'
                                        options={industryBackgroundOptions}
                                        defaultValue={values.industry_background}
                                        component={CustomMultiSelectParticipant}
                                        placeholder="All"
                                    />
                                </div>
                                <div className="filter-item functional-area">
                                    <p className={`filter-group-title`}>Functional area</p>
                                    <Field
                                        name='functional_areas'
                                        options={functionalAreaOptions}
                                        defaultValue={values.functional_areas}
                                        component={CustomMultiSelectParticipant}
                                        placeholder="All"
                                    />
                                </div>
                                <div className="clear-all-filters">
                                    <Button onClick={handleReset} className="clear-btn" type="reset"
                                        disabled={!isSubmitting}>
                                        Clear all filters
                                    </Button>
                                </div>
                            </div>
                            <div className="participants-list">
                                <div className="participants-filter-search">
                                    <h2>Participants list</h2>
                                    <div className="search">
                                        <input
                                            name="participants-search"
                                            onFocus={() => setFocusSearch(!focusSearch)}
                                            onBlur={() => setFocusSearch(!focusSearch)}
                                            onChange={handleParticipantsSearchChange}
                                            type="text"
                                            value={participantsSearchValue}
                                            placeholder={`Key word for search...`} />
                                        <div className={focusSearch ? 'icon active' : 'icon'}>
                                            <Search />
                                        </div>
                                        <div className="filter-toggle-block">
                                            <FilterToggleButton className={`toggle-filter`} onClick={() => setStatusFilterToggle(true)} />
                                        </div>
                                    </div>
                                </div>
                                {
                                    newUserProgramsList && newUserProgramsList.length > 0 ?
                                        <PerfectScrollbar containerRef={el => (scrollSectionRef.current = el)} className="list">
                                            {
                                                newUserProgramsList.map((program, index) => (
                                                    <ParticipantProfilePreview program={program} key={index} />
                                                ))
                                            }
                                        </PerfectScrollbar>
                                        :
                                        <div className="empty-list">
                                            <img className={`search-is-nothing-found`} src={search_is_nothing_found} alt="" />
                                            {participantsSearchValue !== '' &&
                                                <>
                                                    <h2 className={`searched-value`}>Search request <span
                                                        className={`text`}>“{participantsSearchValue}”</span> is nothing
                                                        found</h2>

                                                    <div className="clear-search-field" onClick={handleReset}>
                                                        Back to all participants list
                                                    </div>
                                                </>
                                            }
                                        </div>
                                }
                            </div>
                        </section>
                    </Form>
                )
            }}
        </Formik>
    );
};
