import React, {useEffect} from 'react';
import {ReactComponent as GoogleIcon} from "assets/vector/g-calendar-icon.svg";
import {ReactComponent as BasketIcon} from "assets/vector/basket.svg";
import './googleSync.scss';
import {useDispatch, useSelector} from "react-redux";
import {openModal} from "redux/actions/modal";
import {getGoogleAuthLink, showNotification} from "redux/actions";
import Button from "components/button/Button";
import {GOOGLE_CANCEL_AUTH_MODAL} from "redux/constants/modal";
import googleBtn from "assets/images/g-btn.jpg";

const GoogleSync = ({isSync}) => {
    const dispatch = useDispatch();
    const {
        googleAuthRequest,
        googleAuthRequestError,
        googleAuthRequestLink,
    } = useSelector(state => state.userSettings);

    const handleGetAuthLink = () => {
        dispatch(getGoogleAuthLink())
    }

    const handleRemove = () => {
        dispatch(openModal(GOOGLE_CANCEL_AUTH_MODAL));
    };

    useEffect(() => {
        if (googleAuthRequestLink) {
            window.location = googleAuthRequestLink;
        }
    });

    useEffect(() => {
        if (googleAuthRequestError) {
            dispatch(showNotification({
                title: 'Error',
                text: googleAuthRequestError
            }));
        }
    });

    return (
        isSync?.google_account ?
            <>
                <p className="calendar-sync__subtext">Connected accounts:</p>
                <p className="calendar-sync__email">
                    {isSync?.google_account?.email}
                    <BasketIcon class="calendar-sync__remove" onClick={handleRemove}/>
                </p>
            </>
            :
            <div className="calendar-sync">
                <h4 className="calendar-sync__title">
                    <GoogleIcon className="calendar-sync__icon"/>
                    Do you want to sync your google calendar?
                </h4>
                <ul className="calendar-sync__list">
                    <li className="calendar-sync__list-item">Switch calendar will use
                        your google calendar data
                    </li>
                </ul>
                <Button loading={googleAuthRequest}
                        type='button'
                        onClick={handleGetAuthLink}
                        className={`button calendar-sync__g-button ${googleAuthRequest ? 'active' : ''}`}>
                    <img src={googleBtn} className='icon' alt='google-icon' width='44' height='44'/>
                    Add Google Calendar
                </Button>
            </div>
    );
};

export default GoogleSync;