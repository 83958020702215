import {uploadCsv} from "redux/constants";

export function sendCsvFile(info) {
    return {
        type: uploadCsv.SEND_CSV_FILE,
        payload: info,
    };
}


export function sendCsvFileSuccess(importParticipantsSuccess) {
    return {
        type: uploadCsv.SEND_CSV_FILE_SUCCESS,
        payload: importParticipantsSuccess,

    };
}

export function sendCsvFileError(error) {
    return {
        type: uploadCsv.SEND_CSV_FILE_ERRORS,
        payload: error,
    };
}