import React, { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import "./EngagementReportsDownload.scss";

import Button from "components/button/Button";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import { GET_ENGAGEMENT_REPORTS_ROUTE } from "constants/api";
import ResponseError from "helpers/ResponseError";
import { showNotification } from "redux/actions";
import { useMediaQuery } from "react-responsive";

const EngagementReportsDownload = ({ selectedReports, isSearchOpen }) => {
  const isMaxMobScreen = useMediaQuery({ maxWidth: 1193 });

  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  const { authToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const downloadOptions = [
    {
      name: "overview",
      label: "Download Report Overview",
    },
    {
      name: "detailed",
      label: "Download Report Detailed",
    },
  ];

  const downloadReports = (type, idsArray) => {
    axios({
      method: "get",
      url: `${GET_ENGAGEMENT_REPORTS_ROUTE}/export-csv`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        type: type,
        ids: idsArray,
      },
      accept: "text/xls",
      responseType: "blob",
    })
      .then((response) => {
        const file = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = file;
        link.target = "_blank";
        link.download = `${type}_reports.xls`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        const responseError = new ResponseError(error.response.data);

        dispatch(
          showNotification({
            title: responseError.getStatus(),
            text: responseError.getValidationErrorMessages(),
          })
        );
      });
  };

  const toggleShowDownloadOptions = () => {
    setShowDownloadOptions(!showDownloadOptions);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className='engagement-reports__download'>
      <Button
        className={`button button--blue button__download ${
          !selectedReports?.length || !selectedOption ? "disabled" : ""
        }`}
        onClick={() => downloadReports(selectedOption?.name, selectedReports)}
        disabled={!selectedReports?.length || !selectedOption}
      >
        {selectedOption ? selectedOption.label : " Download report"}
      </Button>
      <Button
        className={`button button--blue button__options ${
          isMaxMobScreen && isSearchOpen ? "hide" : ""
        } ${showDownloadOptions ? "active" : ""}`}
        onClick={toggleShowDownloadOptions}
      >
        <ArrowSvg />
      </Button>
      <div
        className={`download__options ${showDownloadOptions ? "active" : ""}`}
      >
        {downloadOptions.map((option) => (
          <span
            key={option.name}
            onClick={() => {
              selectOption(option);
              toggleShowDownloadOptions();
            }}
          >
            {option.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default EngagementReportsDownload;
