import {fork, put, takeLatest, call, select} from "redux-saga/effects";
import axios from "axios";
import {uploadCsv} from "redux/constants";
import {sendCsvFileSuccess, sendCsvFileError} from "redux/actions/uploadCsvProgrammes"
import {IMPORT_PARTICIPANTS} from "constants/api";
import {generalApiErrorHandler} from "redux/sagas/helpers/generalApiErrorHandler";
import {showNotification} from "redux/actions";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

function* watchSendScvFile() {
    yield takeLatest(uploadCsv.SEND_CSV_FILE, handleSendScvFile);
}

function* handleSendScvFile({payload}) {
    try {
        const token = yield select(getToken);
        const data = new FormData();
        data.append('import_file', payload.import_file);

        const csvFileRes = yield axios({
            method: "post",
            url: IMPORT_PARTICIPANTS(payload.programID),
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            },
            accept: "text/csv",
            data: data
        })
            .then(response => response.data)
            .catch(error => error.response);

        if (csvFileRes?.status === 'Success' && csvFileRes.data?.errors.length > 0) {
            const errors = csvFileRes.data.errors.map(err => {
                const validationErrors = err.errors.map(message => message.message).join(`; `);

                return `Row(${err.row}): ${validationErrors}`;
            }).join(' \n');

            return yield put(sendCsvFileError(errors));
        }

        if (csvFileRes?.status === 'Success') {
            yield put(sendCsvFileSuccess(csvFileRes.data));
        }


    } catch (error) {
        yield generalApiErrorHandler(error.message, function* () {
            console.log(error.message);

            yield put(sendCsvFileError(error.message))

            yield put(showNotification({
                title: 'Error',
                text: getNotificationMessage(error.message)
            }, true));
        });
    }
}

export default function* sendCsvFile() {
    yield fork(watchSendScvFile);
}