import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SelectCloseSvg } from "assets/vector/select-close.svg";

import { ReactComponent as IconType1 } from "./notification-icons/icon-type-1.svg";
import { ReactComponent as IconType2 } from "./notification-icons/icon-type-2.svg";
import { ReactComponent as IconType3 } from "./notification-icons/icon-type-3.svg";
import { ReactComponent as IconType4 } from "./notification-icons/icon-type-4.svg";

import { ReactComponent as Circle } from "./notification-icons/circle.svg";
import uniqid from "uniqid";

import {
    removeNotification,
    getUserNotificationsOnScroll,
    sendAcceptNotification,
    sendDeclineNotification,
} from "redux/actions";

export default function NotificationItems({ userNotification }) {
    const dispatch = useDispatch();

    const defaultShowItems = 15;

    const totalNotifications = useSelector(state => state.notification.totalNotifications);

    const [offsetItems, setOffsetItems] = useState(defaultShowItems);

    const [maxItemsNotifications, setMaxItemsNotifications] = useState(0);

    useEffect(() => {
        if (totalNotifications) {
            setMaxItemsNotifications(totalNotifications);
        }
    }, [totalNotifications]);

    const removeItem = (itemID) => {
        dispatch(removeNotification(itemID))
    }

    const acceptNotification = (itemId) => {
        dispatch(sendAcceptNotification(itemId))
    }

    const declineNotification = (itemId) => {
        dispatch(sendDeclineNotification(itemId))
    }

    const switchNoificationType = (type) => {
        switch (type) {
            case 'one_hour_after_session':
            case 'one_hour_before_session':
            case 'session_invitation_accepted ':
            case 'session_invitation_not_accepted':
                return <IconType1 />;

            case 'session_changed':
            case 'session_invitation_declined':
            case 'session_time_changed':
                return <IconType2 />;

            case 'session_invitation_completed':
                return <IconType3 />;

            case 'new_session_invitation':
                return <IconType4 />;

            default:
                return <IconType1 />;
        }
    }

    const showItemActions = (type, session_id) => {
        switch (type) {
            case 'new_session_invitation':
            case 'session_changed':
            case 'session_time_changed':

                return (
                    <div className="item-actions">
                        <div className="cancel-btn" onClick={() => declineNotification(session_id)}>Decline</div>
                        <div className="accept-btn" onClick={() => acceptNotification(session_id)}>Accept</div>
                    </div>
                );
            default:
                return '';
        }
    }

    function onScrollList(event) {
        const scrollBottom = event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight;

        if (scrollBottom && offsetItems <= maxItemsNotifications) {

            let getNewItems = {
                offset: offsetItems,
                take: defaultShowItems,

            };
            setOffsetItems(offsetItems + defaultShowItems);
            // setOffsetItems(offsetItems + offsetItems);
            dispatch(getUserNotificationsOnScroll(getNewItems));
        }
    }

    return (
        <>
            {
                userNotification &&
                <PerfectScrollbar
                    className="notification-items"
                    onScroll={event => onScrollList(event)}
                >
                    {
                        userNotification.map(
                            (element) => {
                                return (
                                    <div className="item" key={uniqid()}>
                                        <div className={`item-icon ${element.read ? 'readed' : 'not-readed'}`}>
                                            {
                                                switchNoificationType(element.type)
                                            }
                                            {
                                                !element.read && <Circle className={`not-readed-icon`} />
                                            }
                                        </div>
                                        <div className="item-info">
                                            <h3 className={`item-notification-title`}>{element.title}</h3>
                                            <p className={`item-notification-content`}>{ReactHtmlParser(element.text)}</p>
                                            <p className={`item-notification-date`}>
                                                {
                                                    moment(element.created_at).calendar(null, {
                                                        sameDay: 'HH:mm',
                                                        lastDay: '[Yesterday]',
                                                        lastWeek: 'MMMM, DD',
                                                        sameElse: 'MMMM, DD'
                                                    })
                                                }
                                            </p>
                                            {
                                                element.data.session_status === "pending" &&
                                                showItemActions(element.type, element.data.session_id)
                                            }
                                        </div>
                                        <div className="remove-item">
                                            <SelectCloseSvg onClick={(e) => {
                                                e.stopPropagation();
                                                removeItem(element.id)
                                            }} />
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </PerfectScrollbar>
            }
        </>
    )
}