import {call, put} from "redux-saga/effects";
import axios from "axios";
import { registerForProgramError, registerForProgramSuccess, showNotification } from "redux/actions";
import api from "constants/api";
import ResponseError from "helpers/ResponseError";
import {getNotificationMessage} from "helpers/notifications";

export function* handlerExistRegister(requestData) {
    const {REGISTER_FOR_PROGRAM_URL} = api;
    const {code: programCode, id: programId, token} = requestData;

    const {data} = yield call(() => axios.post(
        `${REGISTER_FOR_PROGRAM_URL}/${programCode}/${programId}`, requestData, {
            'headers': {'Authorization': `Bearer ${token}`}
        })
        .then(data => data)
        .catch(error => error.response)
    );

    if (data?.status === 'Success') {
        const registerData = data.data;
        
        yield put(registerForProgramSuccess(registerData.participant_program));

        localStorage.setItem('survey', JSON.stringify(registerData.survey));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        yield put(registerForProgramError(err.getValidationErrorMessages()));

        yield put(showNotification({
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}