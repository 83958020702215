import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Programmes.scss";
import CoachProgram from "components/pages/Programmes/CoachProgram";
import ParticipantProgram from "components/pages/Programmes/ParticipantProgram";
import SchoolAdminProgram from "components/pages/Programmes/SchoolAdminProgram";
import NotFound from "components/pages/NotFound/NotFound";
import { useDispatch } from 'react-redux';
import { getCoachPrograms, getParticipantPrograms, getSchoolAdminPrograms, updatePageTitle } from 'redux/actions';
import pageTitles from 'constants/pageTitles';
import Spinner from "components/Spinner/Spinner";
import EmptyPage from '../EmptyPage/EmptyPage';
import { programmPage } from 'constants/emptyPages';

export default function Programmes() {

    const dispatch = useDispatch();
    const { PROGRAMMES_TITLE } = pageTitles;

    const getCurrentUserInfo = useSelector(state => state.currentUserInfo.currentUserInfo);
    const userToken = useSelector(state => state.auth.authToken);
    const { programsExist, programsLoading } = useSelector(state => state.userPrograms);

    const [programStatus, setProgramStatus] = useState('active');

    const switchRole = (userRole) => {
        switch (userRole) {
            case 'coach':
                return <CoachProgram programStatus={programStatus} setProgramStatus={setProgramStatus} />;
            case 'participant':
                return <ParticipantProgram />;
            case 'school_admin':
                return <SchoolAdminProgram programStatus={programStatus} setProgramStatus={setProgramStatus} />;
            default:
                return <NotFound />;
        }
    }

    useEffect(() => {
        dispatch(updatePageTitle(PROGRAMMES_TITLE));

        const currentUserRole = (user) => {
            switch (user?.role) {
                case 'coach':
                    return dispatch(getCoachPrograms(userToken, { programStatus: 'active' }));
                case 'participant':
                    return dispatch(getParticipantPrograms(userToken));
                case 'school_admin':
                    return dispatch(getSchoolAdminPrograms({ take: 10, offset: 0, programStatus: 'active' }));
                default:
                    break;
            }
        }

        currentUserRole(getCurrentUserInfo);

    }, [PROGRAMMES_TITLE, getCurrentUserInfo, userToken]);

    return (
        <div className="page-programmes">
            {!programsExist && programsLoading && <Spinner />}

            {
                getCurrentUserInfo && programsExist
                    ?
                    switchRole(getCurrentUserInfo.role)
                    :
                    <EmptyPage emptyPageValue={programmPage} />
            }
        </div>
    );
};