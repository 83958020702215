import React from 'react';
import image from 'assets/vector/dashboard/recent_chats.svg';
import { useSelector } from 'react-redux';
import RecentChatItem from './RecentChatItem/RecentChatItem';
import EmptyData from '../EmptyData/EmptyData';
import Spinner from 'components/Spinner/Spinner';
import { useDispatch } from 'react-redux';
import { getMessagesFromUser, setChatOpen, setChatUser } from 'redux/actions';
import './RecentChats.scss';

const RecentChats = () => {
    const dispatch = useDispatch();
    const { listRooms, listRoomsLoading, listUsers } = useSelector(state => state.chat);

    function startChatWithAdmin() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        const schoolAdmin = listUsers?.find(user => user.role === 'super_admin');
        const schoolAdminChatRoomId = listRooms?.find(room => room.users[0].id === schoolAdmin.id);

        dispatch(setChatOpen(true));
        dispatch(setChatUser(schoolAdmin));

        if (schoolAdminChatRoomId) {
            dispatch(getMessagesFromUser(schoolAdminChatRoomId.id));
        }
    }

    return (
        <div className='recent-chats'>
            <div className="recent-chats__top-section">
                <div className="top-section__title">Recent chats</div>
                <div
                    className="top-section__link"
                    onClick={(e) => {
                        e.stopPropagation();
                        startChatWithAdmin();
                    }}>
                    <img src={image} alt="open-chat" /> Talk to us
                </div>
            </div>
            <div className="recent-chats__list">
                {
                    listRoomsLoading
                        ? <Spinner size={50} />
                        : listRooms.length > 0
                            ? listRooms.slice(0, 3)
                                .map(chatRoom =>
                                    <RecentChatItem
                                        users={chatRoom.users}
                                        message={chatRoom.last_message_body}
                                        time={chatRoom.updated_at}
                                        chatRoomId={chatRoom.id}
                                    />)
                            : <EmptyData type='chat' text={`You don't have any chats`} button={true} />
                }
            </div>
        </div>
    )
}

export default RecentChats;
