import {
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    CREATE_PASSWORD_REQUEST,
    CREATE_PASSWORD_ERROR,
    CREATE_COACH_PASSWORD_REQUEST,
} from "redux/constants";

export function resetPasswordRequest(requestData) {
    return {
        type: RESET_PASSWORD_REQUEST,
        payload: requestData,
    }
}

export function resetPasswordSuccess() {
    return {
        type: RESET_PASSWORD_SUCCESS,
    }
}

export function resetPasswordError(errorData) {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: errorData,
    }
}

export function createPasswordRequest(requestData) {
    return {
        type: CREATE_PASSWORD_REQUEST,
        payload: requestData,
    }
}

export function createPasswordError(errorData) {
    return {
        type: CREATE_PASSWORD_ERROR,
        payload: errorData,
    }
}

export function createCoachPasswordRequest(requestData) {
    return {
        type: CREATE_COACH_PASSWORD_REQUEST,
        payload: requestData,
    }
}