import {postData} from "redux/sagas/helpers/requestHandlers";
import {EDIT_TOPIC_DESCRIPTION_ROUTE} from "constants/api";
import {put, call, select} from "redux-saga/effects";
import {editTopicDescriptionSuccess, showNotification} from "redux/actions";
import ResponseError from "helpers/ResponseError";
import {getNotificationMessage} from "helpers/notifications";
import {getToken} from "redux/sagas/helpers/helpers";

export function* editTopicRequest({payload: {id, topic_id, description}}) {
    const apiRoute = EDIT_TOPIC_DESCRIPTION_ROUTE(id);
    const token = yield select(getToken);
    const {data} = yield call(postData(apiRoute, {topic_id, description}, token));

    if (data?.status === 'Success') {
        const topics = data?.data?.topics;

        yield put(editTopicDescriptionSuccess({topics, topic_id}));

    } else if (data?.status === 'Error') {
        const err = new ResponseError(data);

        console.log(err.getValidationErrorMessages());

        put(showNotification({
            title: 'Error',
            text: getNotificationMessage(err.getValidationErrorMessages())
        }, true));
    }
}