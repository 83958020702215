import React, { useEffect, useRef, useState } from 'react';
import './ReportsDesk.scss';
import ReportsList from "components/pages/Reports/ReportsList/ReportsList";
import ReportsFilter from "components/pages/Reports/ReportsFilter/ReportsFilter";
import ReportsActions from "components/pages/Reports/ReportsActions/ReportsActions";
import ReportsTabs from "components/pages/Reports/ReportsTabs/ReportsTabs";
import ReportsMobileMenu from "components/pages/Reports/ReportsMobileMenu/ReportsMobileMenu";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import EngagementReportsFilter from '../EngagementReportsFilter/EngagementReportsFilter';
import { useObserver } from 'hooks/useObserver';
import { addSearchQuery, addSelectedFilterOptions, addSelectedReports, getBillingOverviewReports, getCoachInvoices, getEngagementReports, getEvaluationReports, getFeedbackReports, setReportsSortingOptionAndOrder } from 'redux/actions';
import { removeEmptyValues } from 'helpers/removeEmptyValues';
import { Ellipsis } from 'react-spinners-css';
import BillingOverview from '../BillingOverview/BillingOverview';
import SelectCurrency from '../BillingOverview/SelectCurrency/SelectCurrency';
import GenerateInvoice from '../BillingOverview/GenerateInvoice/GenerateInvoice';
import Invoices from '../Invoices/Invoices';


const ReportsDesk = ({ feedbackReports, evaluationReports, engagementReports, billingReports, invoices }) => {
    const dispatch = useDispatch();
    const { authToken } = useSelector(state => state.auth);
    const userRole = useSelector(state => state.currentUserInfo.currentUserInfo.role);
    const { getReportsProgress, selectedFilterOptions, sortingOptionAndOrder, searchQuery } = useSelector(state => state.reports)

    const isMaxMobScreen = useMediaQuery({ maxWidth: 1193 });
    const isMinTabletScreen = useMediaQuery({ minWidth: 1194 });

    const [isAllFiltersVisible, setAllFiltersVisible] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [tabs, setTabs] = useState([])
    const [activeTab, setActiveTab] = useState(null);
    const [isMobileTabsOpen, setMobileTabsOpen] = useState(false);
    const [clearFilters, setClearFilters] = useState(false);
    const [currency, setCurrency] = useState('')
    const [offsetReports, setOffsetReports] = useState(0);

    const reportsLimit = 20;

    // used for Intersection Observer API in pagination on scroll
    const lastElement = useRef();

    const changeActiveTab = (tab) => {
        // need to reset all filter, sorting, search values and selected reports
        setOffsetReports(0);
        setClearFilters(true);
        setActiveTab(tab);
        dispatch(addSelectedReports([]));
        dispatch(addSelectedFilterOptions(null))
        dispatch(setReportsSortingOptionAndOrder(null))
        dispatch(addSearchQuery(''));
        setMobileTabsOpen(false);
        setIsSearchOpen(false);
        setCurrency('')
    }

    const getTabs = (userRole) => {
        switch (userRole) {
            case 'coach':
                return [
                    {
                        label: 'Engagement overview',
                        name: 'engagement'
                    },
                    {
                        label: 'Evaluation forms',
                        name: 'evaluation'
                    },
                    {
                        label: 'Billing overview',
                        name: 'billing'
                    },
                    {
                        label: 'Billing reports',
                        name: 'invoices'
                    }
                ];
            case 'participant':
                return [
                    {
                        label: 'Feedback forms',
                        name: 'feedback'
                    }
                ];
            default:
                return [
                    {
                        label: 'Engagement overview',
                        name: 'engagement'
                    },
                    {
                        label: 'Evaluation forms',
                        name: 'evaluation'
                    },
                    {
                        label: 'Feedback forms',
                        name: 'feedback'
                    }
                ]
        }
    }

    const getTotalCountOfReportsByType = (activeTab) => {
        switch (activeTab?.name) {
            case 'engagement':
                return engagementReports?.total;
            case 'evaluation':
                return evaluationReports?.total;
            case 'feedback':
                return feedbackReports?.total;
            case 'billing':
                return billingReports?.total;
            case 'invoices':
                return invoices?.total;
            default:
                break;
        }
    }

    const toggleFilters = () => {
        setAllFiltersVisible(!isAllFiltersVisible);
    }

    useEffect(() => {
        const body = document.querySelector('body')

        if (isMaxMobScreen && isAllFiltersVisible) {
            body.classList.add('lock');
        }

        return () => {
            body.classList.remove('lock')
        }
    }, [isMaxMobScreen, isAllFiltersVisible])

    // used the Intersection Observer API for loading on page scroll
    useObserver(lastElement, (offsetReports + reportsLimit) <= getTotalCountOfReportsByType(activeTab), getReportsProgress, () => {
        setOffsetReports(offsetReports + reportsLimit);
    });

    useEffect(() => {
        if (userRole) {
            const userTabs = getTabs(userRole);
            const defaultTab = userTabs[0];

            setTabs(userTabs);
            setActiveTab(defaultTab);
        }
    }, [userRole])

    useEffect(() => {

        const params = {
            type: activeTab?.name === 'engagement' ? '' : activeTab?.name,
            take: reportsLimit,
            offset: offsetReports,
            ...selectedFilterOptions,
            ...sortingOptionAndOrder,
            search: searchQuery ? searchQuery : ''
        }

        if (activeTab?.name === 'engagement') {
            dispatch(getEngagementReports(authToken, removeEmptyValues(params)));
        }

        if (activeTab?.name === 'evaluation') {
            dispatch(getEvaluationReports(authToken, removeEmptyValues(params)));
        }

        if (activeTab?.name === 'feedback') {
            dispatch(getFeedbackReports(authToken, removeEmptyValues(params)));
        }

    }, [offsetReports, activeTab, selectedFilterOptions, sortingOptionAndOrder, searchQuery]);

    // billing overview reports updates after filtering, sorting or pagination
    useEffect(() => {
        const params = {
            take: reportsLimit,
            offset: offsetReports,
            currency: currency,
            ...sortingOptionAndOrder,
        }
        if (activeTab?.name === 'billing') {
            dispatch(getBillingOverviewReports(authToken, removeEmptyValues(params)));
        }
    }, [activeTab, offsetReports, currency, sortingOptionAndOrder])

    // coach invoices updates after filtering, sorting or pagination
    useEffect(() => {
        const params = {
            take: reportsLimit,
            offset: offsetReports,
            ...sortingOptionAndOrder,
            ...selectedFilterOptions,
        }
        if (activeTab?.name === 'invoices') {
            dispatch(getCoachInvoices(authToken, removeEmptyValues(params)));
        }
    }, [activeTab, offsetReports, sortingOptionAndOrder, selectedFilterOptions])

    return (
        <section className="reports-desk">
            <ReportsTabs
                tabs={tabs}
                activeTab={activeTab}
                setOffsetReports={setOffsetReports}
                setIsSearchOpen={setIsSearchOpen}
                isSearchOpen={isSearchOpen}
                setClearFilters={setClearFilters}
                changeActiveTab={changeActiveTab}
                setMobileTabsOpen={setMobileTabsOpen}
                isMobileTabsOpen={isMobileTabsOpen}
            />
            <div className="reports-desk__filters">
                {
                    activeTab?.name === 'billing' &&
                    <div className="billing-actions">
                        <SelectCurrency currency={currency} setCurrency={setCurrency} />
                        <GenerateInvoice isAllowedGeneration={billingReports?.is_allowed_invoice_generation} changeActiveTab={changeActiveTab} />
                    </div>
                }
                {
                    isMinTabletScreen && activeTab?.name !== 'billing' && activeTab?.name !== 'invoices' &&
                    (activeTab?.name !== 'engagement'
                        ? <ReportsFilter
                            clearFilters={clearFilters}
                            setClearFilters={setClearFilters}
                            isAllFiltersVisible={isAllFiltersVisible}
                            filterOptions={activeTab?.name === 'feedback' ? feedbackReports?.filter_options : evaluationReports?.filter_options}
                            activeTab={activeTab}
                            setOffsetReports={setOffsetReports}
                        />
                        : <EngagementReportsFilter
                            clearFilters={clearFilters}
                            setClearFilters={setClearFilters}
                            isAllFiltersVisible={isAllFiltersVisible}
                            filterOptions={engagementReports?.filter_options}
                            activeTab={activeTab}
                            setOffsetReports={setOffsetReports}
                        />
                    )
                }
                {
                    (activeTab?.name !== 'billing' && activeTab?.name !== 'invoices') &&
                    <ReportsActions
                        activeTab={activeTab}
                        toggleFilters={toggleFilters}
                        setIsSearchOpen={setIsSearchOpen}
                        setOffsetReports={setOffsetReports}
                        isSearchOpen={isSearchOpen}
                        isAllFiltersVisible={isAllFiltersVisible}
                    />
                }
            </div>
            {activeTab?.name === 'billing'
                ? <BillingOverview setOffsetReports={setOffsetReports} data={billingReports} lastElement={lastElement} />
                : activeTab?.name === 'invoices'
                    ? <Invoices lastElement={lastElement} setOffsetReports={setOffsetReports} invoices={invoices} />
                    :
                    <ReportsList
                        lastElement={lastElement}
                        activeTab={activeTab}
                        reports={activeTab?.name === 'feedback' ? feedbackReports?.reports : evaluationReports?.reports}
                        engagementReports={engagementReports?.engagement_reports}
                        userRole={userRole}
                        isMobile={isMaxMobScreen ? true : false}
                        searchQuery={searchQuery}
                        setOffsetReports={setOffsetReports}
                    />
            }
            {getReportsProgress &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Ellipsis color="#2e79da" size={50} />
                </div>
            }
            {
                isMaxMobScreen && isAllFiltersVisible &&
                <ReportsMobileMenu
                    userRole={userRole}
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setAllFiltersVisible={setAllFiltersVisible}
                    clearFilters={clearFilters}
                    setClearFilters={setClearFilters}
                    isAllFiltersVisible={isAllFiltersVisible}
                    filterOptions={activeTab?.name === 'feedback' ? feedbackReports?.filter_options : evaluationReports?.filter_options}
                    engagementFilterOptions={engagementReports?.filter_options}
                    setOffsetReports={setOffsetReports}
                />
            }
        </section>

    );
}

export default ReportsDesk;
