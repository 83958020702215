export const getUserInvitationStatus = (session = [], userRole, userId) => {
    const currentCoach = session.coaches.find(coach => coach.id === userId);
    const currentParticipant = session.participants.find(participant => participant.id === userId);

    if (session.status === 'confirmed' && session.session_type === 'individual') {
        return 'confirmed';
    }

    if (session.status === 'confirmed' && userRole === 'coach') {
        return currentCoach.invite_status;
    }

    if (session.status === 'confirmed') {
        return currentParticipant.invite_status;
    }

    if (session.status === 'late_canceled') {
        return 'late_canceled';
    }

    if (userRole === 'coach') {
        return currentCoach ? currentCoach.invite_status : 'noCoach';
    }

    return currentParticipant?.invite_status;
};

export const getActivityWithSession = (sessionId, topics = []) => {
    const activity = topics.flat().find(topic => topic.session?.id === +sessionId);

    return activity?.uniqId || topics[0][0].uniqId;
};

export const getActivityId = (activityId, topics = []) => {
    const activity = topics.flat().find(topic => topic.id === +activityId);

    return activity?.uniqId || topics[0][0].uniqId;
};