import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import pageTitles from "constants/pageTitles";
import {
    updatePageTitle,
} from "redux/actions";
import DashboardCoach from "./Coach/dashboard-coach";
import DashboardParticipant from "./Participant/dashboard-participant";
import DashboardSchoolAdmin from "./SchoolAdmin/dashboard-school-admin";
import NotFound from "components/pages/NotFound/NotFound";
import { Redirect } from "react-router-dom";
import routes from 'constants/routes';
import ParticipantDashboard from './Participant/ParticipantDashboard';

export default function Dashboard() {
    const {
        DASHBOARD_TITLE,
    } = pageTitles;

    const dispatch = useDispatch();

    const { QUESTIONNAIRE } = routes;

    const getCurrentUserInfo = useSelector(state => state.currentUserInfo.currentUserInfo);
    const { userRole, surveyData } = useSelector((state) => state.auth);
    const isSurveyDataExist = (getCurrentUserInfo?.survey || surveyData);
    const isUserRoleExist = (getCurrentUserInfo?.role || userRole)

    useEffect(() => {
        dispatch(updatePageTitle(DASHBOARD_TITLE));
        window.scrollTo(0, 0);
    }, []);

    const switchRole = (userRole) => {
        switch (userRole) {
            case 'coach':
                return (
                    <DashboardCoach />
                );
            case 'participant':
                return (
                    // <DashboardParticipant />
                    <ParticipantDashboard />
                );
            case 'school_admin':
                return (
                    <DashboardSchoolAdmin />
                )
            default:
                return <NotFound />;
        }
    }

    if (isSurveyDataExist?.skip || (isUserRoleExist === 'participant' && !isSurveyDataExist?.required) || isSurveyDataExist?.filled || isUserRoleExist === 'school_admin') {
        return (
            <>
                {getCurrentUserInfo && switchRole(isUserRoleExist)}
            </>
        );
    }

    return <Redirect to={QUESTIONNAIRE} />;
}
