import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom";
import {Formik, Form} from 'formik';
import "./EditMyProfile.scss";
import profile from "services/profile";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/button/Button";
import {getInfoAboutCurrentUser} from "redux/actions";
import routes from "constants/routes"
import {participantValidationScheme} from './helpers/validation';
import {getParticipantInitValues} from "components/pages/EditMyProfile/helpers/initValues";
import PersonalInfo from "components/pages/EditMyProfile/PersonalInfo/PersonalInfo";
import UserLanguages from "components/pages/EditMyProfile/UserLanguages/UserLanguages";
import SeniorityLevel from "components/pages/EditMyProfile/SeniorityLevel/SeniorityLevel";
import WorkExperience from "components/pages/EditMyProfile/WorkExperience/WorkExperience";
import FunctionalArea from "components/pages/EditMyProfile/FunctionalArea/FunctionalArea";
import Industry from "components/pages/EditMyProfile/Industry/Industry";
import Goals from "components/pages/EditMyProfile/Goals/Goals";
import StatusMessage from "components/pages/EditMyProfile/StatusMessage/StatusMessage";

export default function ParticipantEditProfile({userInfo = {}}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {MY_PROFILE} = routes;
    const [spinnerLoadingButton, setSpinnerLoadingButton] = useState(false);
    const [getFunctionalAreas, setFunctionalAreas] = useState(null);
    const [getIndustryBackgrounds, setIndustryBackgrounds] = useState(null);
    const [getLanguages, setLanguages] = useState([]);
    const [getLanguageLevels, setlanguageLevels] = useState(null);
    const [getSeniorityLevels, setSeniorityLevels] = useState(null);
    const [formErrorsFormik, setFormErrorsFormik] = useState(false);
    const userToken = useSelector(state => state.auth.authToken);

    const updateUserProfileData = (userId, values, token, setStatus, setErrors) => {
        profile.updateParticipantProfile(userId, values, token, setStatus, setErrors)
            .then((response) => {
                if (response.data.status === "Success") {
                    setStatus({show: true, sent: true, msg: "Your profile has been successfully updated!"});
                    setSpinnerLoadingButton(true);
                    setTimeout(() => {
                        setSpinnerLoadingButton(false);
                        setStatus({show: false});

                        history.push(MY_PROFILE);
                    }, 2000);
                    dispatch(getInfoAboutCurrentUser());
                }

            })
            .catch(error => {
                console.log(error.response.data.message);
                setErrors(error);
                setStatus({
                    show: true,
                    sent: false,
                    msg: `Error! ${error.response.data.message}. Please try again later.`
                })
                setTimeout(() => {
                    setSpinnerLoadingButton(false);
                    setStatus({show: false});
                }, 2000);
            })
    };

    const getUserProfileDefinedData = () => {
        profile.getDefinedData(userToken)
            .then((response) => {
                setFunctionalAreas(response.functionalAreas);
                setIndustryBackgrounds(response.industryBackgrounds);
                setLanguages(response.languages);
                setlanguageLevels(response.languageLevels);
                setSeniorityLevels(response.seniorityLevels);

            })
            .catch(error => console.log(error))
    };

    useEffect(() => {
        getUserProfileDefinedData();
    }, []);

    return (
        <Formik
            validateOnMount={true}
            enableReinitialize
            initialValues={getParticipantInitValues(userInfo)}
            validationSchema={participantValidationScheme}
            onSubmit={
                (values, {setSubmitting, setStatus, setErrors}) => {
                    setSubmitting(false);
                    setStatus(false);
                    setFormErrorsFormik(false);
                    updateUserProfileData(userInfo.id, values, userToken, setStatus, setErrors)
                }
            }
        >
            {({isSubmitting, dirty, isValid, status, ...formProps}) => {
                return (
                    <Form>
                        <PersonalInfo userInfo={userInfo} {...formProps} />
                        <UserLanguages setFormErrorsFormik={setFormErrorsFormik}
                                       getLanguageLevels={getLanguageLevels}
                                       getLanguages={getLanguages}
                                       {...formProps}
                        />
                        <SeniorityLevel getSeniorityLevels={getSeniorityLevels} userInfo={userInfo} />
                        <WorkExperience setFormErrorsFormik={setFormErrorsFormik} {...formProps} />
                        <FunctionalArea getFunctionalAreas={getFunctionalAreas} userInfo={userInfo} />
                        <Industry getIndustryBackgrounds={getIndustryBackgrounds} userInfo={userInfo} />
                        <Goals userInfo={userInfo} {...formProps} />
                        <StatusMessage show={status?.show} success={status?.sent}>
                            {status?.msg}
                        </StatusMessage>
                        <StatusMessage show={!isValid && dirty && formErrorsFormik}>
                            Please, fill out all required fields
                        </StatusMessage>
                        <div className="save-block-wrapper">
                            <Button type="submit"
                                    className="save-personal-info"
                                    loading={spinnerLoadingButton}
                                    disabled={isSubmitting || !dirty}
                                    onClick={() => setFormErrorsFormik(true)}
                            >
                                Save changes
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
};
