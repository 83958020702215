import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import "./Programmes.scss";
import CoachProgrammeCard from "components/pages/Programmes/programmeCard/coachProgrammeCard";
import SingleProgramme from "components/pages/Programmes/SingleProgramme/SingleProgramme";
import ProgrammesParticipantsList from "components/pages/Programmes/ProgrammesParticipantsList/ProgrammesParticipantsList";
import EmptyPage from "../EmptyPage/EmptyPage"
import { programmPage } from "constants/emptyPages";
import CustomCoachProgramsSelect from 'components/CustomFormSelect/CustomCoachProgramsSelect';
import { programStatusList } from 'helpers/programStatusList';

export default function CoachProgram({ programStatus, setProgramStatus }) {
    const { path } = useRouteMatch();

    const userProgramsList = useSelector(state => state.userPrograms.programs);

    //  used to display only one program with the same school_program_id in the list
    function uniqProgram(data, key) {
        // need to show only one program with the same status and school_program_id
        // we may have 2 programs in the list with the same school_program_id but with different status
        const activePrograms = data.filter(program => !program.is_deactivated);
        const deactivatedPrograms = data.filter(program => program.is_deactivated);

        return [
            ...new Map(
                activePrograms?.map(x => [key(x), x])
            ).values(),
            ...new Map(
                deactivatedPrograms?.map(x => [key(x), x])
            ).values(),
        ].sort((a, b) => b.school_program_id - a.school_program_id)
    }

    const programCards = uniqProgram(userProgramsList, program => program.school_program_id).map(item => <CoachProgrammeCard key={item.id} {...item} />);

    return (
        <Switch>
            <Route exact path={path}>
                <div className="section-wrap">
                    <CustomCoachProgramsSelect
                        options={programStatusList}
                        programStatus={programStatus}
                        setProgramStatus={setProgramStatus}
                    />
                    {
                        userProgramsList.length > 0
                            ?
                            <>{programCards}</>
                            :
                            <EmptyPage emptyPageValue={programmPage()} />
                    }
                </div>
            </Route>
            <Route exact path={`${path}/participants-list/:programme`}>
                <ProgrammesParticipantsList userProgramsList={userProgramsList} />
            </Route>
            <Route exact path={`${path}/single-program/:programID`} component={SingleProgramme} />
        </Switch>
    );
};
